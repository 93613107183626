import React, { memo } from "react";
import styled from "styled-components";
import { useTransition, animated } from "react-spring";

const Box = styled.div``;

const Root = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: fit-content;
`;

export const ContentContainer = styled(animated.div)`
  height: fit-content;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: initial;
`;

interface AccordionProps {
  children: React.ReactNode;
  root: React.ReactNode;
  isOpen: boolean;
  onClick: () => void;
}

const BaseComponent = ({ children, root, isOpen, onClick }: AccordionProps) => {
  const transitions = useTransition(isOpen, null, {
    from: { transform: "translate3d(0,-20px,0)", opacity: 0 },
    enter: { transform: "translate3d(0,0px,0)", opacity: 1 },
    leave: { transform: "translate3d(0,-20px,0)", opacity: 0 },
    config: { duration: 100, mass: 1, friction: 50, tension: 400 },
  });

  return (
    <Box>
      <Root onClick={onClick}>{root}</Root>
      {transitions.map(({ item, props, key }) =>
        item ? (
          <ContentContainer key={key} style={props}>
            {children}
          </ContentContainer>
        ) : null,
      )}
    </Box>
  );
};

export const Accordion = memo(BaseComponent);
