import { Link as RLink, LinkProps } from "react-router-dom";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

export const Link = styled(RLink)<LinkProps>`
         font-family: ${themeGet("fonts.montserrat")};
         font-size: 16px;
         color: ${themeGet("colors.grays.7")};
         text-decoration: none;

         :hover {
           color: ${themeGet("colors.grays.2")};
           text-decoration: underline;
         }

         transition: all 0.2s;
       `;
