import styled from "styled-components";
import { Link } from "react-router-dom";
import themeGet from "@styled-system/theme-get";
import { Circle, buttonStyles, Box } from "@cyberalarm/ui";

export const AddCircle = styled(Circle)`
  cursor: pointer;
  background-color: ${themeGet("colors.black")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: white;
  ${buttonStyles}
`;

export const DeleteCircle = styled(Circle)`
  cursor: pointer;
  background-color: ${themeGet("colors.white")};
  border: 1px solid ${themeGet("colors.black")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: white;
  ${buttonStyles}
`;

export const SortCircle = styled(Circle)`
  cursor: pointer;
  background-color: ${themeGet("colors.black")};
  transparency: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: white;
  ${buttonStyles}
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

export const Dot = styled(Box)`
  width: 8px;
  height: 8px;
  margin-right: 7px;
  border-radius: 50%;
`;

export const ItemRoot = styled.div`
  display: flex;
  align-items: center;
  flex: 0 25%;
`;
