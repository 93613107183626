import { createSelector } from "reselect";
import { CookiesType, getCookie } from "@cyberalarm/common";

import { InitialState } from "./store";

export const getState = (state: InitialState): InitialState => state;

export const getUser = createSelector(
  getState,
  (state: InitialState) => state.user,
);

export const getUserID = createSelector(
  getUser,
  (user: User) => user && user.id,
);

export const getUserRole = createSelector(
  getUser,
  (user: User) => (user ? user.role : getCookie(CookiesType.role) || null),
);

export const getUserScope = createSelector(
  getUser,
  (user: User) => user && user.scope,
);
