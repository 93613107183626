import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { Spinner, theme, Box } from "@cyberalarm/ui";

import { UserRoute } from "./user-route";
import { GuestRoute } from "./guest-route";
import { AdminRoute } from "./admin-route";
import { RootRoute } from "./root-route";
import { CommonRoutes, AdminRoutes, UserRoutes } from "./routes";

import { useAuth } from "hooks";
import { UserPage, EmailVerification } from "pages";

const Loader = () => (
  <Box justifyContent="center" alignItems="center" width="100%" height="100vh">
    <Spinner background={theme.colors.background} />
  </Box>
);

const SignUp = lazy(() => import("pages/sign-up"));
const TeamUserSignUp = lazy(() => import("pages/team-user-sign-up"));
const SignUpTerms = lazy(() => import("pages/sign-up/terms"));
const NewCheckout = lazy(() => import("pages/checkout/new"));
const UserCreateConfirm = lazy(() =>
  import("pages/sign-up/user-create-confirm")
);
const TeamUserCreateConfirm = lazy(() =>
  import("pages/team-user-sign-up/team-user-create-confirm")
);
const SignIn = lazy(() => import("pages/sign-in"));
const ForgotPassword = lazy(() => import("pages/forgot-password"));
const ResetPassword = lazy(() => import("pages/reset-password"));
const AdminPage = lazy(() => import("pages/admin"));
const NotFound = lazy(() => import("pages/404"));

export const Router = () => {
  const loading = useAuth();

  if (loading) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path={CommonRoutes.root} exact component={RootRoute} />
        <Route
          path={CommonRoutes.emailVerification}
          exact
          component={EmailVerification}
        />

        <GuestRoute path={CommonRoutes.signUp} component={SignUp} />
        <GuestRoute path={CommonRoutes.signUpTerms} component={SignUpTerms} />
        <GuestRoute path={CommonRoutes.newCheckout} component={NewCheckout} />
        <GuestRoute
          path={CommonRoutes.userCreateConfirm}
          component={UserCreateConfirm}
        />
        <GuestRoute
          path={CommonRoutes.teamUserSignUp}
          component={TeamUserSignUp}
        />
        <GuestRoute
          path={CommonRoutes.teamUserCreateConfirm}
          component={TeamUserCreateConfirm}
        />
        <GuestRoute path={CommonRoutes.signIn} component={SignIn} />
        <GuestRoute
          path={CommonRoutes.forgotPassword}
          component={ForgotPassword}
        />
        <GuestRoute
          path={CommonRoutes.resetPassword}
          component={ResetPassword}
        />

        <AdminRoute path={AdminRoutes.root} component={AdminPage} />

        <UserRoute path={UserRoutes.root} component={UserPage} />

        {/* last resort page */}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};
