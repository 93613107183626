import React, { Fragment, memo, useState, useEffect, useContext } from "react";
import {
  Box,
  theme,
  FormTitle,
  Icon,
  Text,
  Modal,
  TitleBar,
} from "@cyberalarm/ui";
import { Icons } from "@cyberalarm/common";
import { ReportPreferencesForm } from "./report-preferences";
import { TeamSettings } from "./settings";
import { api } from "@cyberalarm/common";
import build from "redux-object";
import normalize from "json-api-normalizer";
import { _, Grid } from "gridjs-react";
import { StoreContext } from "store";
import "gridjs/dist/theme/mermaid.css";

import themeGet from "@styled-system/theme-get";
import styled from "styled-components";
import { toast } from "react-toastify";
import { UpdateTeamUserModal } from "./update-user";
import { InviteTeamUserModal } from "./invite-user";
import { useIntl } from "react-intl";

const StyledLinkText = styled(Text)`
  text-decoration: none;
  cursor: pointer;
  color: ${themeGet("colors.black")};
  margin-left: 10px;
  :hover {
    text-decoration: underline;
  }
`;

const TextButton = styled(Text)`
  text-decoration: none;
  cursor: pointer;
  color: ${themeGet("colors.black")};
  :hover {
    text-decoration: underline;
  }
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    overflow-wrap: anywhere;
  }
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-start;
  }
`;

export const Team = memo(() => {
  const intl = useIntl();
  const { user, team, setTeam } = useContext(StoreContext);
  const [teamUsers, setTeamUsers] = useState<TeamUser[]>([]);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [updateTeamUser, setUpdateTeamUser] = useState<TeamUser | undefined>(
    undefined
  );

  const EditActionButton = (row: TeamUser) => {
    return (
      <Box flexDirection="row" alignItems="center" padding={0}>
        <TextButton
          marginLeft={1}
          onClick={() => {
            setUpdateTeamUser(row);
            setUpdateDialogOpen(true);
          }}
        >
          Edit
        </TextButton>
      </Box>
    );
  };

  useEffect(() => {
    try {
      if (team === null || team === undefined) {
        api("teams").then((teamData) => {
          const normalizedTeamData = normalize(teamData);
          const builtTeamData = build(normalizedTeamData, "team")[0];
          setTeam({
            id: builtTeamData.id,
            name: builtTeamData.name,
            displayName: builtTeamData.displaName,
            preferences: builtTeamData.preferences,
            planPreferences: builtTeamData.planPreferences,
            domainsLimit: builtTeamData.domainsLimit,
            usersLimit: builtTeamData.usersLimit,
            trialMode: builtTeamData.trialMode,
            reportTitle: builtTeamData.reportTitle,
            reportLogo: builtTeamData.reportLogo,
          });
        });
      }
      api("teams/team_users", {
        method: "GET",
      }).then((data) => {
        const normalizedTeamUsersData = normalize(data);
        const builtTeamUsersData = build(normalizedTeamUsersData, "teamUser");
        if (builtTeamUsersData) {
          setTeamUsers(builtTeamUsersData);
        }
      });
    } catch (error) {
      toast.error("Failed to retrieve team users");
    }
  }, [team, setTeam]);

  return (
    <Fragment>
      {team !== null && (
        <>
          <TitleBar label="Team Settings" />
          <FlexBox>
            <TeamSettings />
            <ReportPreferencesForm />
          </FlexBox>

          <Box
            width="100%"
            flexDirection="column"
            marginTop="24px"
            backgroundColor="white"
            justifyContent="center"
          >
            <Box
              flexDirection="row"
              justifyContent="space-between"
              marginY="16px"
            >
              <FormTitle margin="auto 16px">Users</FormTitle>
              <Box
                flexDirection="row"
                alignItems="space-between"
                style={{ cursor: "pointer" }}
                onClick={() => setInviteDialogOpen(true)}
                margin="auto 16px"
              >
                <Icon name={Icons.plus} width={15} height={15} />
                <StyledLinkText>Invite user</StyledLinkText>
              </Box>
            </Box>
            <Grid
              columns={[
                {
                  name: "Email",
                  data: (row: TeamUser) => row.email,
                  width: "20%",
                },
                {
                  name: "First name",
                  data: (row: TeamUser) => row.firstName,
                  width: "15%",
                },
                {
                  name: "Last name",
                  data: (row: TeamUser) => row.lastName,
                  width: "15%",
                },
                {
                  name: "Role",
                  data: (row: TeamUser) => row.role,
                  width: "10%",
                },
                {
                  name: "Active",
                  data: (row: TeamUser) => (row.active ? "Yes" : "No"),
                  width: "10%",
                },
                {
                  name: "Last login",
                  data: (row: TeamUser) =>
                    _(
                      <>
                        <Box>
                          {intl.formatDate(row.lastSignInAt, {
                            year: "numeric",
                            month: "long",
                            day: "2-digit",
                          })}
                        </Box>

                        <Box>
                          {intl.formatTime(row.lastSignInAt, {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })}
                        </Box>
                      </>
                    ),
                  width: "10%",
                },
                {
                  name: "Action",
                  data: (row: TeamUser) =>
                    user?.email !== row.email
                      ? _(<EditActionButton {...row} />)
                      : _(
                          <>
                            <p style={{ marginLeft: "0.5em" }}>----</p>
                          </>
                        ),
                  width: "10%",
                },
              ]}
              data={teamUsers}
              sort={true}
              autoWidth={false}
              style={{
                table: {
                  "font-family": `Helvetic Neue, Helvetica`,
                  "font-size": "16px",
                },
                th: {
                  "text-wrap": "wrap",
                  "font-weight": "600",
                },
                td: {
                  "overflow-wrap": "break-word",
                  "padding-left": "20px",
                  "padding-right": "20px",
                },
              }}
            />
          </Box>
          <Modal
            isOpen={inviteDialogOpen}
            close={() => setInviteDialogOpen(false)}
          >
            <InviteTeamUserModal
              close={() => setInviteDialogOpen(false)}
              setTeamUsers={setTeamUsers}
            />
          </Modal>
          <Modal
            isOpen={updateDialogOpen}
            close={() => setUpdateDialogOpen(false)}
          >
            <UpdateTeamUserModal
              user={updateTeamUser}
              close={() => setUpdateDialogOpen(false)}
              setTeamUsers={setTeamUsers}
            />
          </Modal>
        </>
      )}
    </Fragment>
  );
});

Team.displayName = "Team";
