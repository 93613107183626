import React from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { CheckResult } from "@cyberalarm/common";
import { Text, InfoCard, Box, theme } from "@cyberalarm/ui";

const Grid = styled.div`
  display: grid;
  gap: 24px;

  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(min-content, 200px);

  @media (max-width: ${theme.breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Title = styled(Text)`
  font-size: 20px;
  line-height: 1.45;
  color: ${themeGet("colors.grays.3")};
`;

interface CustomCheck {
  id: string;
  description: string;
  title: string;
  checkGroup: {
    id: string;
    title: string;
  };
}

interface InfoFeedProps {
  data: {
    result: CheckResult;
    id: string;
    check: CustomCheck;
  }[];
  title: MessageDescriptor;
  onClick: (data: {
    id: string;
    checkType: string;
    result: CheckResult;
  }) => void;
}

export const InfoFeed = ({ data, title, onClick }: InfoFeedProps) => {
  const intl = useIntl();
  return (
    <Box marginBottom={5}>
      <Title marginBottom={5}>{intl.formatMessage(title)}</Title>
      <Grid>
        {data.map(
          (item: { id: string; result: CheckResult; check: CustomCheck }) => {
            return <InfoCard key={item.id} {...item} onClick={onClick} />;
          },
        )}
      </Grid>
    </Box>
  );
};
