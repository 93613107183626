import { useState, useCallback, useContext } from "react";
import normalizer from "json-api-normalizer";
import build from "redux-object";
import { api, Role, Scope } from "@cyberalarm/common";
import useSWR from "swr";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";

import { StoreContext, selectors } from "store";

interface Alert {
  id: string;
  createdAt: string;
  updatedAt: string;
  domainName?: string;
  tokenName?: string;
  domain?: Domain;
  read: boolean;
  checkResult: CheckResult;
  alerted: {
    checkResult: CheckResult;
    id: string;
    createdAt: string;
    updatedAt: string;
    tokenName: string;
    tokenType: string;
    compromised: string;
    generatedInfo: {
      token: string;
      fullName: string;
    };
  };
}

export interface OverlimitedDomain {
  [key: string]: string | {};
  id: string;
  type: string;
  name: string;
  meta: {
    [key: string]: string | number;
    passedCount: number;
    failedCount: number;
    warningsCount: number;
    lastScanAt: string;
  };
  attributes: {
    [key: string]: string;
    createdAt: string;
    name: string;
    updatedAt: string;
  };
}

export interface SubscriptionPlans {
  [key: string]: string | {};
  currency: string;
  description: string;
  id: string;
  name: string;
  price: string;
}

const alertsApiUrl = "alerts?limit=1";

export const useAlerts = () => {
  const [isOpen, setIsOpen] = useState(false);
  // TODO: get rid of hasMore state
  const [hasMore] = useState(false);
  const [alertsErrorStatus, setAlertsErrorStatus] = useState(0);
  const [domainLimitAmount, setDomainLimitAmount] = useState(0);
  const [domainsList] = useState();
  const state = useContext(StoreContext);
  const userRole = selectors.getUserRole(state);
  const userScope = selectors.getUserScope(state);

  const { data: alerts, revalidate, mutate } = useSWR(
    () => {
      const shouldFetchAlert =
        userRole === Role.user && userScope === Scope.Access;

      if (shouldFetchAlert || hasMore) {
        return alertsApiUrl;
      }
      return null;
    },
    api,
    {
      onError: (error) => {
        if (error?.errors[0]?.status === 403) {
          setAlertsErrorStatus(403);
          setDomainLimitAmount(error.errors[0].limit);
          setIsOpen(true);
          return;
        }
        if (error?.errors[0]?.status === 402) {
          setAlertsErrorStatus(402);
          setIsOpen(true);
          return;
        }
      },
    },
  );

  const normalizedData = alerts && normalizer(alerts);
  const builtAlert: Alert =
    normalizedData && build(normalizedData, "alert")?.[0];

  // Disabled alerts popup
  // useEffect(() => {
  //   if (alerts) {
  //     setHasMore(alerts?.meta?.has_more);
  //     setIsOpen(true);
  //   }
  // }, [alerts]);

  const close = useCallback(() => {
    mutate(alertsApiUrl, undefined);
    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsOpen]);

  const markRead = useCallback(async () => {
    try {
      await api(`alerts/${builtAlert?.id}`, { method: "PATCH" });
    } catch {}

    if (hasMore) {
      await revalidate();
    } else {
      close();
    }
  }, [builtAlert, close, hasMore, revalidate]);

  return {
    builtAlert,
    isOpen,
    close,
    markRead,
    alertsErrorStatus,
    domainsList,
    domainLimitAmount,
  };
};

export const useOverlimitedDomains = (alertsErrorStatus: number) => {
  const { data: overlimitedDomains } = useSWR(
    alertsErrorStatus === 403 ? "overlimited_domains" : null,
    api,
  );
  const normalizedData = overlimitedDomains && normalizer(overlimitedDomains);
  const builtDomains: OverlimitedDomain[] =
    normalizedData && build(normalizedData, "domain");
  return { overlimitedDomains: builtDomains };
};

export const useSubscriptionPlans = () => {
  const { data } = useSWR("plans", api);
  const normalizedData = data && normalizer(data);
  const builtPlans: SubscriptionPlans[] =
    normalizedData && build(normalizedData, "plan");

  return { builtPlans };
};

export const useRemoveOverlimitedDomains = (
  domains: string[],
  close: () => void,
  setHideRemoveDomainslist: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  api("overlimited_domains/unlink", {
    method: "PUT",
    // eslint-disable-next-line @typescript-eslint/camelcase
    body: { domain_ids: domains },
  })
    .then(() => {
      setHideRemoveDomainslist(true);
      window.location.reload();
      close();
      return null;
    })
    .catch(() => {
      toast.error("Something went wrong, try again");
    });
};

export const redirectToStripeCheckout = async (
  plan: string,
  close: () => void,
) => {
  const data = await api(`stripe/customer_session?price_id=${plan}`).catch(
    () => {
      toast.error("Something went wrong, please try again");
    },
  );

  if (data?.data !== undefined) {
    const stripePromise = await loadStripe(data.data.pub_key);
    stripePromise?.redirectToCheckout({ sessionId: data.data.id }).catch(() => {
      toast.error("Something went wrong, please try again");
    });
  } else {
    toast.success("Successfully subscribed");
    close();
  }
};
