import React, { useContext } from "react";
import { Field, Form } from "react-final-form";
import { FormattedMessage } from "react-intl";
import { form } from "@cyberalarm/common";
import { i18n, reportPreferencesSchema } from "./schema";
import { useReportPreferencesUpdate, useReportPreferencesReset } from "./hooks";
import { pathOr } from "ramda";
import styled from "styled-components";
import { StoreContext } from "store";
import {
  AdaptedInput,
  FormButton,
  Card,
  FormControlFeedback,
  FormGroup,
  FormInner,
  FormTitle,
  Label,
  Box,
  theme,
} from "@cyberalarm/ui";

const Thumbnail = styled.img`
  max-width: 64px;
  max-height: 64px;
  object-fit: contain;
  margin-inline-end: 1rem;
  align-self: normal;
`;

const StyledCard = styled(Card)`
  box-shadow: none;
  margin-left: 24px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-top: 24px;
    margin-left: 0px;
    width: 100%;
  }
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    margin-top: 20px;
    align-items: flex-start;
  }
`;

const StyledFormButton = styled(FormButton)`
  min-width: 80px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-top: 20px;
  }
`;

const StyledBox = styled(Box)`
  margin-left: 32px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-left: 0px;
  }
`;

export const ReportPreferencesForm = () => {
  const validator = form.validateForm({ schema: reportPreferencesSchema });
  const reportTitle = pathOr("", ["reportTitle"]);
  const reportLogo = pathOr("", ["reportLogo"]);
  const onSubmit = useReportPreferencesUpdate();
  const reset = useReportPreferencesReset();
  const { team } = useContext(StoreContext);

  return (
    <StyledCard p={5}>
      <Form
        onSubmit={onSubmit}
        validate={validator}
        initialValues={{
          report_title: reportTitle(team),
        }}
        component={({ handleSubmit, pristine, submitting }) => {
          return (
            <FormInner onSubmit={handleSubmit}>
              <FormTitle marginBottom={5} fontSize="14px">
                <FormattedMessage {...i18n.reportPreferencesFormTitle} />
              </FormTitle>
              <FlexBox>
                <Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Label htmlFor="report_title">
                      <FormattedMessage {...i18n.reportTitleLabel} />
                    </Label>
                    <Box
                      style={{
                        display: "flex",
                        fontSize: "0.8rem",
                        marginLeft: "0.5rem",
                        marginTop: "0.1rem",
                      }}
                    >
                      <FormattedMessage {...i18n.reportTitleHelp} />
                    </Box>
                  </Box>
                  <FormGroup marginTop={3}>
                    <Field
                      id="report_title"
                      name="report_title"
                      type={"text"}
                      component={AdaptedInput}
                      value={team?.reportTitle}
                    />
                    <FormControlFeedback name="report_title" />
                  </FormGroup>
                </Box>
                <StyledBox>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Label htmlFor="report_logo">
                      <FormattedMessage {...i18n.reportLogoLabel} />
                    </Label>
                    <Box
                      style={{
                        display: "flex",
                        fontSize: "0.8rem",
                        marginLeft: "0.5rem",
                        marginTop: "0.1rem",
                      }}
                    >
                      <FormattedMessage {...i18n.reportLogoHelp} />
                    </Box>
                  </Box>

                  <FormGroup marginTop={3}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      {reportLogo(team).length > 0 && (
                        <Thumbnail src={team?.reportLogo} />
                      )}
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Field
                          id="report_logo"
                          name="report_logo"
                          type={"file"}
                          component={AdaptedInput}
                        />
                        <FormControlFeedback name="report_logo" />
                      </Box>
                    </Box>
                  </FormGroup>
                </StyledBox>
              </FlexBox>

              <FlexBox>
                <StyledFormButton variant="primary" onClick={reset}>
                  <FormattedMessage {...i18n.reset} />
                </StyledFormButton>
                <StyledFormButton
                  variant="primary"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  <FormattedMessage {...i18n.save} />
                </StyledFormButton>
              </FlexBox>
            </FormInner>
          );
        }}
      />
    </StyledCard>
  );
};
