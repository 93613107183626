import React, { memo, useContext, useMemo } from "react";
import { Redirect, Route as RRoute } from "react-router-dom";
import { CookiesType, getCookie, Role } from "@cyberalarm/common";

import { CommonRoutes } from "./routes";

import { StoreContext, selectors } from "store";

interface UserRouteProps {
  component: React.ComponentType<any>;
  path: string;
  redirect?: string;
}

const Route = ({
  component: Component,
  redirect = CommonRoutes.root,
  ...rest
}: UserRouteProps) => {
  const token = getCookie(CookiesType.token);
  const state = useContext(StoreContext);
  const userRole = selectors.getUserRole(state);
  const isUser = userRole === Role.user;

  return useMemo(
    () => (
      <RRoute
        {...rest}
        render={(props) =>
          token != null && isUser ? (
            <Component {...props} />
          ) : (
            <Redirect to={redirect} />
          )
        }
      />
    ),
    [isUser, redirect, rest, token],
  );
};

export const UserRoute = memo(Route);
