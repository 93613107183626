import React, { memo } from "react";
import { defineMessages, useIntl } from "react-intl";

import { Button, Text, Box } from "../atoms";

const { logOut } = defineMessages({
  logOut: {
    defaultMessage: "Log out",
    id: "header.logOut",
  },
});

interface HeaderProps {
  children: React.ReactNode;
  logout: () => void;
}

const BaseComponent = ({ children, logout }: HeaderProps) => {
  const intl = useIntl();
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
      paddingX={1}
    >
      <Text color="grays.4" fontSize={3}>
        {children}
      </Text>

      <Button variant="primary" onClick={logout}>
        {intl.formatMessage(logOut)}
      </Button>
    </Box>
  );
};

export const Header = memo(BaseComponent);
