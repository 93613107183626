import { pathOr } from "ramda";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { CheckResult } from "@cyberalarm/common";

import { Card, Text, Box } from "../atoms";
import { additionalInformation, howDoIFix } from "../shared-schema";
import { getStatusColor } from "../theme";

const getCheckGroupTitle = pathOr<string>("", ["checkGroup", "title"]);

const StyledCard = styled(Card)`
  padding: 24px 0;
  width: 100%;
  min-width: 200px;
`;

const HeaderRoot = styled(Box)<{ status: string }>`
  border-left: 4px solid ${(p) => p.status};
  padding: 0 24px 0 20px;
`;

const HowToRoot = styled(Box)`
  cursor: pointer;
  margin: 16px 0 0 24px;
  :hover {
    filter: brightness(110%);
  }
`;

interface InfoCardProps {
  onClick: (data: {
    id: string;
    checkType: string;
    result: CheckResult;
  }) => void;
  result: CheckResult;
  id: string;
  check: {
    id: string;
    description: string;
    title: string;
    checkGroup: {
      id: string;
      title: string;
    };
  };
}

export const InfoCard = ({ result, check, id, onClick }: InfoCardProps) => {
  const intl = useIntl();
  const status = getStatusColor(result);
  const checkType = getCheckGroupTitle(check);
  const text =
    result === CheckResult.passed ? additionalInformation : howDoIFix;

  const handler = useCallback(
    () => checkType && onClick({ id, checkType, result }),
    [id, checkType, onClick, result],
  );
  return (
    <StyledCard>
      <HeaderRoot status={status}>
        <Box>
          <Text fontSize="14px" color="grays.2">
            {checkType}
          </Text>
          <Text fontSize="20px" color="grays.4" marginTop="4px">
            {check.title}
          </Text>
        </Box>
      </HeaderRoot>
      <Box padding={2} marginTop={2}>
        <Text fontSize="14px" color="grays.4">
          {check.description}
        </Text>
      </Box>

      <HowToRoot onClick={handler}>
        <Text fontSize="14px" color="primary">
          {intl.formatMessage(text)}
        </Text>
      </HowToRoot>
    </StyledCard>
  );
};
