import styled from "styled-components";

import { Box, BoxProps } from "./box";

export const Card = styled(Box)<BoxProps>`
         background-color: white;
         border-radius: 10px;
       `;

Card.defaultProps = {
  minHeight: "200px",
  minWidth: "272px",
  borderRadius: "4px",
};
