export enum CommonRoutes {
  root = "/",
  signIn = "/sign-in",
  signUp = "/sign-up",
  teamUserSignUp = "/team-user-sign-up",
  signUpTerms = "/sign-up-terms",
  userCreateConfirm = "/user-create-confirm",
  teamUserCreateConfirm = "/team-user-create-confirm",
  newCheckout = "/new-checkout",
  forgotPassword = "/forgot-password",
  resetPassword = "/reset-password",
  notFound = "/404",
  emailVerification = "/email-verification",
}

export enum UserRoutes {
  root = "/user",
  domain = "/user/domain",
  domainDashboard = "/user/domain/:domainID/dashboard",
  profile = "/user/profile",
  team = "/user/team",
  support = "/user/support",
  domainTests = "/user/domain/:domainID/tests",
  domainReport = "/user/domain/:domainID/report",
}

export enum AdminRoutes {
  root = "/admin",
  profile = "/admin/profile",
  team = "/admin/team",

  managerList = "/admin/managers",
  userList = "/admin/users",

  activeDomainList = "/admin/domains/active",
  deactivatedDomainList = "/admin/domains/deactivated",

  activeTestList = "/admin/tests/active",
  deactivatedTestList = "/admin/tests/deactivated",

  activeErrorList = "/admin/errors/active",
  deactivatedErrorList = "/admin/errors/deactivated",

  userDashboard = "/user-dashboard",
  userProfile = "/admin/profile",
}

export const getUserDomainDashboardUrl = (id: string | null) => {
  if (!id) {
    return UserRoutes.domain;
  } else if (window.location.href.match(/domain\/\d+\/tests$/)) {
    return UserRoutes.domainTests.replace(":domainID", id);
  } else {
    return UserRoutes.domainDashboard.replace(":domainID", id);
  }
};

export const getUserDomainTestsUrl = (id: string | null) => {
  if (!id) {
    return UserRoutes.domain;
  }
  return UserRoutes.domainTests.replace(":domainID", id);
};

export const getUserDomainReportUrl = (id: string | null) => {
  if (!id) {
    return UserRoutes.domain;
  }
  return UserRoutes.domainReport.replace(":domainID", id);
};
