import React, { memo, useEffect, useState, useContext } from "react";
import { Form, Field } from "react-final-form";
import {
  FormButton,
  Box,
  Card,
  Text,
  FormInner,
  AdaptedRadio,
  AdaptedCheckbox,
  Label,
  theme,
} from "@cyberalarm/ui";
import styled from "styled-components";
import { form, useToggle } from "@cyberalarm/common";
import { manageNotificationsSchema } from "./schema";
import { StoreContext } from "store";
import build from "redux-object";
import { toast } from "react-toastify";
import { useIntl, FormattedMessage } from "react-intl";
import { i18n } from "./schema";
import { api } from "@cyberalarm/common";
import normalize from "json-api-normalizer";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RadioFlexBox = styled(FlexBox)`
  margin-bottom: 0;
  width: 22rem;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: 8px;
    width: 14rem;
  }
`;

const SectionLabel = styled(Text)`
  font-size: 18px;
  min-width: 5rem;
  margin-left: 4rem;
  text-align: right;
  font-weight: 500;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-left: 8px;
  }
`;

const FlexCard = styled(Card)`
  max-width: 800px;
  overflow: auto;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface ModalProps {
  close: () => void;
}

interface NotificationPreferencesFormProps {
  digestNotifications: boolean | undefined;
  individualNotifications: boolean | undefined;
  digestNotificationsContent?: string;
  digestNotificationConsolidation?: string;
}
const Modal = ({ close }: ModalProps) => {
  const { team } = useContext(StoreContext);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (team) {
      setShowModal(true);
    }
  }, [team]);

  const intl = useIntl();
  const [digestEnabled, toggleDigestEnabled] = useToggle(
    team?.preferences?.notificationPreferences?.digestNotifications.enabled
  );
  const [individualEnabled, toggleIndividualEnabled] = useToggle(
    team?.preferences?.notificationPreferences?.individualNotifications.enabled
  );

  const onSubmit = async (values: NotificationPreferencesFormProps) => {
    const digestNotificationsValue = digestEnabled
      ? {
          enabled: true,
          content: values.digestNotificationsContent,
          consolidation: values.digestNotificationConsolidation,
        }
      : { enabled: false };
    const data = await api("teams/notificationpreferences", {
      method: "PATCH",
      noSnakeBody: true,
      body: {
        notificationPreferences: {
          digestNotifications: digestNotificationsValue,
          individualNotifications: {
            enabled: individualEnabled,
          },
        },
      },
    });
    const normalizedTeamData = normalize(data);
    const builtTeamData = build(normalizedTeamData, "team")[0];
    if (builtTeamData && team) {
      team.preferences = builtTeamData.preferences;
      toast.success(
        intl.formatMessage(i18n.notificationPreferencesSuccessToastText)
      );
    } else {
      toast.error(
        intl.formatMessage(i18n.notificationPreferencesErrorToastText)
      );
    }
    close();
  };

  return (
    <>
      <FlexCard>
        <Box marginX={8} marginY="32px" flex={1} minHeight="68px">
          <Box alignItems="center" marginX="auto">
            <Label>
              <Text fontFamily="title" fontSize="28px">
                <FormattedMessage {...i18n.notificationsPreferencesFormTitle} />
              </Text>
            </Label>
            <Text
              mt="24px"
              fontFamily="montserrat"
              style={{ opacity: 0.8 }}
              px="60px"
            >
              <FormattedMessage
                {...i18n.notificationsPreferencesFormSubTitle}
              />
            </Text>
          </Box>
          {showModal && (
            <Form
              onSubmit={onSubmit}
              validate={form.validateForm({
                schema: manageNotificationsSchema,
              })}
              initialValues={{
                digestNotifications:
                  team?.preferences?.notificationPreferences
                    ?.digestNotifications.enabled,
                individualNotifications:
                  team?.preferences?.notificationPreferences
                    ?.individualNotifications.enabled,
                digestNotificationsContent:
                  team?.preferences?.notificationPreferences
                    ?.digestNotifications.content || undefined,
                digestNotificationConsolidation:
                  team?.preferences?.notificationPreferences
                    ?.digestNotifications.consolidation || undefined,
              }}
            >
              {({ values, handleSubmit, submitting, valid }) => {
                return (
                  <FormInner onSubmit={handleSubmit}>
                    <Box mt={4}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        mt={3}
                        alignItems="center"
                      >
                        <Field
                          component={AdaptedCheckbox}
                          onClick={toggleDigestEnabled}
                          name="digestNotifications"
                          type="checkbox"
                          checked={digestEnabled}
                        />
                        <Text marginLeft={2} style={{ fontSize: "18px" }}>
                          <FormattedMessage
                            {...i18n.digestNotificationsLabel}
                          />
                        </Text>
                      </Box>
                      <FlexBox
                        mt={3}
                        alignItems="center"
                        style={
                          digestEnabled ? { opacity: 1.0 } : { opacity: 0.5 }
                        }
                      >
                        <SectionLabel marginRight={2}>
                          <FormattedMessage {...i18n.includeLabel} /> :{" "}
                        </SectionLabel>
                        <RadioFlexBox>
                          <Field
                            component={AdaptedRadio}
                            name="digestNotificationsContent"
                            label={intl.formatMessage(i18n.newOnlyLabel)}
                            type="radio"
                            value="NEW_ONLY"
                            disabled={!digestEnabled}
                          />
                        </RadioFlexBox>
                        <RadioFlexBox>
                          <Field
                            component={AdaptedRadio}
                            name="digestNotificationsContent"
                            label={intl.formatMessage(i18n.newAndExistingLabel)}
                            type="radio"
                            value="NEW_AND_EXISTING"
                            disabled={!digestEnabled}
                          />
                        </RadioFlexBox>
                      </FlexBox>
                      <Box
                        width="85%"
                        mt={3}
                        borderBottom="1px solid #b3b3b3"
                        margin="8px auto"
                      ></Box>
                      <FlexBox
                        alignItems="center"
                        style={
                          digestEnabled ? { opacity: 1.0 } : { opacity: 0.5 }
                        }
                      >
                        <SectionLabel marginRight={2}>
                          <FormattedMessage {...i18n.sendLabel} /> :{" "}
                        </SectionLabel>
                        <RadioFlexBox>
                          <Field
                            component={AdaptedRadio}
                            name="digestNotificationConsolidation"
                            label={intl.formatMessage(
                              i18n.sendIndividualDomainLabel
                            )}
                            type="radio"
                            value="INDIVIDUAL_DOMAIN"
                            disabled={!digestEnabled}
                          />
                        </RadioFlexBox>

                        <RadioFlexBox>
                          <Field
                            key="digestNotificationConsolidationConsolidated"
                            component={AdaptedRadio}
                            name="digestNotificationConsolidation"
                            label={intl.formatMessage(
                              i18n.sendConsolidatedDomainsLabel
                            )}
                            type="radio"
                            value="CONSOLIDATED_DOMAINS"
                            disabled={!digestEnabled}
                          />
                        </RadioFlexBox>
                      </FlexBox>
                      <Box
                        display="flex"
                        flexDirection="row"
                        mt={3}
                        alignItems="center"
                      >
                        <Field
                          component={AdaptedCheckbox}
                          onClick={toggleIndividualEnabled}
                          name="individualNotifications"
                          type="checkbox"
                          checked={individualEnabled}
                        />
                        <Text marginLeft={2} style={{ fontSize: "18px" }}>
                          <FormattedMessage
                            {...i18n.individualNotificationsLabel}
                          />
                        </Text>
                      </Box>
                      <Box alignItems="center">
                        <FormButton
                          mt="62px"
                          type="submit"
                          variant="primary"
                          disabled={
                            (!digestEnabled && !individualEnabled) ||
                            (digestEnabled &&
                              (values.digestNotificationConsolidation ===
                                undefined ||
                                values.digestNotificationsContent ===
                                  undefined)) ||
                            !valid ||
                            submitting
                          }
                        >
                          <FormattedMessage {...i18n.saveLabel} />
                        </FormButton>
                      </Box>
                    </Box>
                  </FormInner>
                );
              }}
            </Form>
          )}
        </Box>
      </FlexCard>
    </>
  );
};

export const NotificationsManagement = memo(Modal);

NotificationsManagement.displayName = "NotificationsManagement";
