import styled, { css } from "styled-components";

import { Box } from "./box";

export const ArrowIconRoot = styled(Box)<{ isOpen: boolean }>`
  justify-content: center;
  align-items: center;
  color: #c4c4c4;
  margin-left: auto;
  > svg {
    transition: 0.2s;
    transform-origin: center;
    transform: ${(p) => (p.isOpen ? css`rotate(90deg)` : css`rotate(0)`)};
  }
`;
