import React, { createContext, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { theme } from "@cyberalarm/ui";

const defaultResolution = {
  isMobile: false,
  isTablet: false,
  isDesktop: true,
};

export const ResponsiveContext = createContext(defaultResolution);

export const ResponsiveState = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });
  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.xl})`,
  });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.xl})`,
  });

  const value = useMemo(() => ({ isMobile, isTablet, isDesktop }), [
    isDesktop,
    isMobile,
    isTablet,
  ]);

  return (
    <ResponsiveContext.Provider value={value}>
      {children}
    </ResponsiveContext.Provider>
  );
};
