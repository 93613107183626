import React from "react";
import { Icons } from "@cyberalarm/common";
import { Icon, Box } from "@cyberalarm/ui";
import styled from "styled-components";

export const DownArrowRoot = styled(Box)`
  justify-content: center;
  align-items: center;
  margin-left: auto;
  > svg {
    transform-origin: center;
    transform: rotate(180deg);
  }
`;
export const UpArrowRoot = styled(Box)`
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

interface SortMenuItemProps {
  value: string;
  label: string;
  order: string;
  isCurrent: boolean;
  onClick: (value: string, order: string) => void;
}

const SortMenuItem = (props: SortMenuItemProps) => {
  const { value, label, order, onClick, isCurrent } = props;

  const handleClick = () => {
    onClick(value, label);
  };

  return (
    <div
      key={value}
      onClick={handleClick}
      style={{
        padding: "8px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {label}
      {isCurrent && order === "asc" && (
        <UpArrowRoot>
          <Icon name={Icons.arrowUp} width={16} height={16} />
        </UpArrowRoot>
      )}
      {isCurrent && order === "desc" && (
        <DownArrowRoot>
          <Icon name={Icons.arrowUp} width={16} height={16} />
        </DownArrowRoot>
      )}
    </div>
  );
};

export default SortMenuItem;
