import React, { createContext, useMemo, useCallback } from "react";
import { Box, Modal, Text, theme } from "@cyberalarm/ui";

import { NotificationsManagement } from "./notifications-management";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

const FlexBox = styled(Box)`
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: 1rem;
  }
`;

const StyledLinkText = styled(Text)`
  text-decoration: none;
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  color: ${themeGet("colors.black")};
`;

type InitialContext = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

const initialContext: InitialContext = {
  isOpen: false,
  open: () => null,
  close: () => null,
};

export const NotificationsManagementModalCtx = createContext(initialContext);

export const NotificationsManagementModal = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const close = useCallback(() => setIsOpen(false), []);
  const open = useCallback(() => setIsOpen(true), []);
  const value = useMemo(() => ({ isOpen, open, close }), [close, open, isOpen]);

  return (
    <>
      <FlexBox>
        <StyledLinkText onClick={open}>Manage Notifications</StyledLinkText>
      </FlexBox>

      <NotificationsManagementModalCtx.Provider value={value}>
        <Modal isOpen={isOpen} close={close}>
          <NotificationsManagement close={close} />
        </Modal>
      </NotificationsManagementModalCtx.Provider>
    </>
  );
};
