import { useCallback, useContext } from "react";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { api } from "@cyberalarm/common";
import { errors } from "shared-schema";
import { i18n } from "./schema";
import build from "redux-object";
import normalize from "json-api-normalizer";
import { StoreContext } from "store";

type Result = number | string | [] | {};
interface Options {
  [key: string]: Result;
}

interface FileProps {
  content: string | ArrayBuffer | null;
  type: string | null;
  size: number | null;
  elem: HTMLInputElement | null;
}

interface InviteUserParams {
  email: string;
}

interface UpdateUserParams {
  active: boolean;
  role: "user" | "admin";
}

export const getFile = async () =>
  new Promise<FileProps>((resolve, reject) => {
    const file: HTMLInputElement | null = document.querySelector(
      'input[name="report_logo"]'
    );
    const reader = new FileReader();
    if (file && file.files) {
      const f = file.files[0];
      if (f !== undefined) {
        reader.readAsDataURL(f);
        reader.onload = () => {
          resolve({
            content: reader.result,
            type: f.type,
            size: f.size,
            elem: file,
          });
        };
      } else {
        resolve({ content: null, type: null, size: null, elem: file });
      }
    }
    reader.onerror = (error) => reject(error);
  });

export const useReportPreferencesUpdate = () => {
  const intl = useIntl();
  const { setTeam } = useContext(StoreContext);
  return useCallback(
    async ({ ...rest }) => {
      try {
        const params = { ...rest };
        const logoImageData = await getFile();
        const { data } = await api("teams/reportpreferences", {
          method: "PATCH",
          body: { ...params, report_logo: logoImageData.content },
        });
        const normalizedTeamData = normalize(data);
        const builtTeamData = build(normalizedTeamData, "team");
        toast.success(intl.formatMessage(i18n.reportPreferencesToast));
        setTeam(builtTeamData);
      } catch (error) {
        toast.error(intl.formatMessage(errors.commonError));
      }
    },
    [intl, setTeam]
  );
};

export const useReportPreferencesReset = () => {
  const intl = useIntl();
  const { setTeam } = useContext(StoreContext);
  return useCallback(
    async ({ ...rest }) => {
      try {
        const { data } = await api("teams/reset_reportpreferences", {
          method: "PATCH",
        });
        const normalizedTeamData = normalize(data);
        const builtTeamData = build(normalizedTeamData, "team");
        setTeam(builtTeamData);
      } catch (error) {
        toast.error(intl.formatMessage(errors.commonError));
      }
    },
    [intl, setTeam]
  );
};

export const useInviteTeamUser = (params: InviteUserParams) => {
  return useCallback(
    async ({ ...rest }) => {
      try {
        const { errors } = await api("teams/invite_user", {
          method: "POST",
          body: { email: params.email },
        });
        if (errors) {
          throw errors[0].status;
        }
        toast.success("User invite successful");
      } catch (error) {
        toast.error("User invite failed");
      }
    },
    [params]
  );
};

export const useTeamUsers = () => {
  return useCallback(async () => {
    try {
      const { data } = await api("teams/team_users", {
        method: "GET",
      });

      const normalizedTeamUsersData = normalize(data);
      const builtTeamUsersData = build(normalizedTeamUsersData, "teamUser");
      return builtTeamUsersData;
    } catch (error) {
      toast.error("Failed to retrieve team users");
      return;
    }
  }, []);
};

export const useUpdateTeamUser = (params: UpdateUserParams) => {
  return useCallback(async () => {
    try {
      const { data } = await api("teams/update_user", {
        method: "PATCH",
        body: { active: params.active, role: params.role },
      });

      const normalizedTeamUsersData = normalize(data);
      const builtTeamUsersData = build(normalizedTeamUsersData, "teamUser");
      toast.success("User update successful");
      return builtTeamUsersData;
    } catch (error) {
      toast.error("User update failed");
    }
  }, [params]);
};
