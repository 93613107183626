import React, { useEffect, useState } from "react";
import { Tablet, Box, Icon } from "@cyberalarm/ui";
import { useToggle, Icons } from "@cyberalarm/common";

import { Root, IconRoot, StyledLink, ListRoot } from "./components";

import { UserRoutes } from "routes";
import { DomainManagement, Control } from "ui";
import { useProfileData } from "hooks";

import { useLocation } from "react-router";

import { SubscriptionInfo } from "./subscription-info";

export const TabletSideBar = () => {
  const [isOpen, toggle] = useToggle(false);
  const user = useProfileData();
  const [showTeamLink, setShowTeamLink] = useState(false);
  const location = useLocation();
  const showSubscriptionInfo =
    location.pathname === "/user/team" || location.pathname === "/user/profile";

  useEffect(() => {
    if (user?.teamAttributes?.role === "admin") {
      setShowTeamLink(true);
    }
  }, [user]);

  return (
    <Tablet>
      <Root paddingY="25px" bg="white">
        <Box height="100%" width="100%" bg="white">
          <IconRoot onClick={toggle}>
            <Icon
              name={isOpen ? Icons.close : Icons.hamburger}
              width={18}
              height={18}
            />
          </IconRoot>
          <Box
            marginBottom="auto"
            padding={5}
            justifyContent="center"
            alignItems="center"
            color="gray"
          >
            <StyledLink to={UserRoutes.profile} style={{ padding: "12px" }}>
              <Icon name={Icons.user} />
            </StyledLink>
            {showTeamLink && (
              <StyledLink
                to={UserRoutes.team}
                style={{ padding: "12px", paddingTop: "8px" }}
              >
                <Icon name={Icons.gear} />
              </StyledLink>
            )}
          </Box>
        </Box>

        <ListRoot isOpen={isOpen}>
          {showSubscriptionInfo && <SubscriptionInfo />}
          {!showSubscriptionInfo && <DomainManagement />}
        </ListRoot>
        <Control />
      </Root>
    </Tablet>
  );
};
