import React, { memo, useRef, useState, useCallback } from "react";
import styled from "styled-components";
import { Icons, useOnClickOutside } from "@cyberalarm/common";

import { Icon, Box } from "../atoms";

const IconRoot = styled(Box)`
  cursor: pointer;
`;

export const Popup = memo(({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const reference = useRef(null);
  const toggle = useCallback(() => setIsOpen((state) => !state), []);
  const close = useCallback(() => setIsOpen(false), []);
  useOnClickOutside(reference, close);

  return (
    <IconRoot onClick={toggle} ref={reference}>
      <Icon name={Icons.more} />
      {isOpen && children}
    </IconRoot>
  );
});

Popup.displayName = "Popup";
