/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useCallback, useState, useMemo } from "react";

interface InitialState {
  id: string | null;
  isOpen: boolean;
}

const initialState: InitialState = {
  id: null,
  isOpen: false,
};

const initialContext: InitialState & {
  open: (id: string) => void;
  close: () => void;
} = {
  ...initialState,
  open: () => {},
  close: () => {},
};

export const DetailsContext = createContext(initialContext);

export const DetailsStore = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState(initialState);
  const close = useCallback(() => setState(initialState), []);
  const open = useCallback((id: string) => setState({ id, isOpen: true }), []);
  const value = useMemo(() => ({ ...state, close, open }), [
    close,
    open,
    state,
  ]);

  return (
    <DetailsContext.Provider value={value}>{children}</DetailsContext.Provider>
  );
};
