import { defineMessages } from "react-intl";

export const i18n = defineMessages({
  rangeStart: {
    defaultMessage: "Range start",
    id: "rangeInput.rangeStart",
  },
  rangeEnd: {
    defaultMessage: "Range end",
    id: "rangeInput.rangeEnd",
  },
});
