import React, { Suspense, SVGProps } from "react";
import { Icons } from "@cyberalarm/common";

const iconsMap = {
  [Icons.arrow]: React.lazy(() => import("./arrow")),
  [Icons.arrowUp]: React.lazy(() => import("./arrow-up")),
  [Icons.check]: React.lazy(() => import("./check")),
  [Icons.close]: React.lazy(() => import("./close")),
  [Icons.delete]: React.lazy(() => import("./delete")),
  [Icons.domain]: React.lazy(() => import("./domain")),
  [Icons.eye]: React.lazy(() => import("./eye")),
  [Icons.hamburger]: React.lazy(() => import("./hamburger")),
  [Icons.more]: React.lazy(() => import("./more")),
  [Icons.notification]: React.lazy(() => import("./notification")),
  [Icons.notificationOff]: React.lazy(() => import("./notification-off")),
  [Icons.plus]: React.lazy(() => import("./plus")),
  [Icons.minus]: React.lazy(() => import("./minus")),
  [Icons.user]: React.lazy(() => import("./user")),
  [Icons.sort]: React.lazy(() => import("./sort")),
  [Icons.team]: React.lazy(() => import("./team")),
  [Icons.support]: React.lazy(() => import("./support")),
  [Icons.report]: React.lazy(() => import("./report")),
  [Icons.target]: React.lazy(() => import("./target")),
  [Icons.gear]: React.lazy(() => import("./gear")),
};

export const Icon = ({ name, ...rest }: SVGProps<{}> & { name: Icons }) => {
  const Component = iconsMap[name] as any;

  return (
    <Suspense fallback={null}>
      <Component {...rest} />
    </Suspense>
  );
};
