import { defineMessages, MessageDescriptor } from "react-intl";

export const i18n: {
         [key: string]: MessageDescriptor;
       } = defineMessages({
         failed: {
           defaultMessage: "Alarm",
           id: "dashboard.failed",
         },
         warnings: {
           defaultMessage: "Warning",
           id: "dashboard.warnings",
         },
         passed: {
           defaultMessage: "Clear",
           id: "dashboard.passed",
         },
         title: {
           defaultMessage: "Security status - {name}",
           id: "dashboard.title",
         },
         lastScan: {
           defaultMessage: "Last scan: {time}",
           id: "dashboard.lastScan",
         },
         inProgress: {
           defaultMessage: "in progress",
           id: "dashboard.scanInProgress",
         },
       });
