import React, { memo, useContext, useState } from "react";
import {
  FormButton,
  Box,
  Card,
  Text,
  Spinner,
  Label,
  Modal,
  theme,
} from "@cyberalarm/ui";
import normalize from "json-api-normalizer";
import build from "redux-object";

import { selectors, StoreContext } from "store";
import { toast } from "react-toastify";
import {
  usePlans,
  useCurrentPaymentMethod,
  updateSubscription,
  redirectToStripeCheckoutForUpdatePaymentMethod,
  snakeCaseToInitCaps,
} from "./hooks";
import { SubscriptionPreferencesForm } from "./subscription-preferences";
import { ConfirmationModal } from "./confirmation-modal";
import { api } from "@cyberalarm/common";
import styled from "styled-components";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FlexCard = styled(Card)`
  max-width: 800px;
  overflow: auto;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface ModalProps {
  close: () => void;
  domainsCount: number;
  refreshDomains: () => void;
  setBusyOpen: (open: boolean) => void;
}

const SubscriptionManagementModal = ({
  close,
  domainsCount,
  refreshDomains,
  setBusyOpen,
}: ModalProps) => {
  const paymentMethod = useCurrentPaymentMethod();
  const plans = usePlans();
  const [submitted, setSubmitted] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState<string[] | undefined>(
    undefined
  );
  const { setUser, ...rest } = useContext(StoreContext);
  const user = selectors.getUser(rest);
  const [formValues, setFormValues] = useState({
    product: user?.planPreferences?.product || "",
    plan: user?.planPreferences?.plan || "",
    domains: user?.planPreferences?.domains || 1,
  });

  const refresh = async () => {
    try {
      const data = await api("profile", {
        method: "GET",
      });
      const normalizedData = normalize(data);
      const builtData = build(normalizedData, "profile");

      if (builtData) {
        const { user, ...profile } = builtData[0];
        const preparedData = {
          ...profile,
          id: user.id,
          email: user.email,
          role: user.role,
          confirmed: user.confirmed,
          domainsLimit: user.domainsLimit,
          planPreferences: user.planPreferences,
        };

        setUser(preparedData);
        refreshDomains();
      }
    } catch {}
  };

  const onSubmit = async (value: any) => {
    setSubmitted(true);
    const product = value.product.value;
    const plan = value.plan.value;
    const messages = [];
    const domains =
      plan === "essential"
        ? parseInt(value.domainsInput)
        : value.domainsSelect.value;
    if (domainsCount > parseInt(domains)) {
      toast.error(
        "Invalid request. New Domains limit cannot be less than the currently added domains count",
        { autoClose: 5000 }
      );
      toast.error("Delete existing domains to process the reduced limit", {
        autoClose: 5000,
      });
      setSubmitted(false);
    } else {
      const selectedPlan = plans?.find(
        (p: any) => p.product === product && p.price.metadata.plan === plan
      );
      if (selectedPlan) {
        const transformQty =
          selectedPlan.price.transform_quantity !== null
            ? selectedPlan.price.transform_quantity.divide_by
            : 1;
        const recurringPrice =
          (selectedPlan.price.unit_price * parseInt(domains)) / transformQty;
        messages.push(
          `You have selected ${snakeCaseToInitCaps(
            product
          )} [ ${snakeCaseToInitCaps(plan)} ] - ${domains} domains.`
        );
        messages.push(
          `Subscription price : ${recurringPrice} ${selectedPlan.price.currency.toUpperCase()} per ${
            selectedPlan.price.frequency
          }.`
        );
        if (product !== user?.planPreferences?.product) {
          messages.push(
            "Existing results will be deleted and fresh scans will be initiated for the current set of domains."
          );
        }
        messages.push("Are you sure you want to modify the subscription?");
        setConfirmMessage(messages);
        setFormValues({ product: product, plan: plan, domains: domains });
        setConfirmOpen(true);
      } else {
        toast.error(
          "Something went wrong! Retry or contact System Administrator if the problem persists"
        );
      }
    }
  };

  const onConfirmYes = () => {
    setConfirmOpen(false);
    updateSubscription(formValues, close, refresh, setBusyOpen);
  };

  const onConfirmNo = () => {
    setConfirmOpen(false);
    setSubmitted(false);
  };

  const onPaymentMethodUpdate = async (value: any) => {
    setSubmitted(true);
    redirectToStripeCheckoutForUpdatePaymentMethod(close);
  };

  return (
    <>
      <FlexCard>
        {paymentMethod && plans !== undefined && (
          <>
            <Box alignItems="center" marginX="auto" marginTop="20px">
              <Label>
                <Text fontFamily="title" fontSize="28px">
                  Manage subscription
                </Text>
              </Label>
            </Box>
            <FlexBox flex={1} margin={4}>
              <Box
                flex={1}
                alignItems="center"
                flexDirection="column"
                padding={3}
              >
                <Box alignItems="center" paddingBottom={6}>
                  <Text fontSize="20px">Payment method</Text>
                </Box>
                <Box justifyContent="center" flex={1} width="15rem" mt={3}>
                  <Box flexDirection="column" flex={1}>
                    <Box flex={1} flexWrap="nowrap">
                      <Label>Card type</Label>
                    </Box>
                    <Box flex={1} flexWrap="nowrap" paddingLeft={3}>
                      <Label
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        {paymentMethod?.brand}
                      </Label>
                    </Box>
                  </Box>
                  <Box flexDirection="column" flex={1}>
                    <Box flex={1} flexWrap="nowrap">
                      <Label>Last four digits</Label>
                    </Box>
                    <Box flex={1} paddingLeft={3}>
                      <Label
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        {paymentMethod?.last4}
                      </Label>
                    </Box>
                  </Box>
                  <Box flexDirection="column" flex={1}>
                    <Box flex={1} flexWrap="nowrap">
                      <Label>Expiry</Label>
                    </Box>
                    <Box flex={1} paddingLeft={3}>
                      <Label
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        {paymentMethod?.exp_month.toString().padStart(2, "0")} /{" "}
                        {paymentMethod?.exp_year}
                      </Label>
                    </Box>
                  </Box>
                </Box>
                <FormButton
                  mt={3}
                  variant="primary"
                  disabled={submitted}
                  onClick={onPaymentMethodUpdate}
                >
                  Update payment method
                </FormButton>
              </Box>
              <Box
                flex={1}
                flexDirection="column"
                alignItems="center"
                borderLeft="1px solid lightgray"
                padding={3}
              >
                <Box alignItems="center" paddingBottom={6}>
                  <Text fontSize="20px">Subscription</Text>
                </Box>
                <SubscriptionPreferencesForm
                  product={formValues.product}
                  plan={formValues.plan}
                  domains={formValues.domains}
                  curreuntDomainsCount={domainsCount}
                  onSubmit={onSubmit}
                />
              </Box>
            </FlexBox>
          </>
        )}
        {paymentMethod === undefined && (
          <Box alignItems="center" marginY={6}>
            <Spinner />
          </Box>
        )}
      </FlexCard>
      {confirmOpen && (
        <Modal isOpen={confirmOpen} close={onConfirmNo} zIndex={1001}>
          <ConfirmationModal
            handleYes={onConfirmYes}
            handleNo={onConfirmNo}
            messages={confirmMessage}
          />
        </Modal>
      )}
    </>
  );
};

export const SubscriptionManagement = memo(SubscriptionManagementModal);

SubscriptionManagement.displayName = "SubscriptionManagement";
