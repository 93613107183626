import React, { memo } from "react";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { Text, Circle, Box } from "@cyberalarm/ui";
import { undefinedCheck } from "@cyberalarm/common";

const InfoText = styled(Text)`
  font-family: ${themeGet("font.raleway")};
  color: #848a99;
  font-size: 16px;
  font-weight: 600;
  word-break: break-all;
`;

interface ModalCheckCardHeaderProps {
  groupTitle?: string | null;
  subGroupTitle?: string | null;
  children?: React.ReactNode;
  statusBackground?: string;
  header?: string | null;
  checkName?: string | null;
  title?: string | null;
  subTitle?: string | null;
}

export const ModalCheckCardHeader = memo(
  ({
    groupTitle,
    subGroupTitle,
    children,
    statusBackground = "logo",
    header,
    title,
    subTitle,
  }: ModalCheckCardHeaderProps) => (
    <Box>
      <Box flexDirection="row" alignItems="center">
        <Circle
          width="20px"
          height="20px"
          minWidth="20px"
          minHeight="20px"
          bg={statusBackground}
          marginRight="11px"
        />
        <Box>
          <InfoText as="div">{children}</InfoText>
          <Text color="gray" fontSize="18px" fontWeight={500} mb={1}>
            {undefinedCheck(title)}
          </Text>
          <Text color="gray" fontSize="16px" fontWeight={500} mb={1}>
            {undefinedCheck(subTitle)}
          </Text>
          <InfoText>
            {groupTitle} / {undefinedCheck(subGroupTitle)}
            {header && ` / ${header}`}
          </InfoText>
        </Box>
      </Box>
    </Box>
  )
);

ModalCheckCardHeader.displayName = "ModalCheckCardHeader";
