import { MessageDescriptor, defineMessages } from "react-intl";

export const actions: {
         [key: string]: MessageDescriptor;
       } = defineMessages({
         difficultyLow: {
           defaultMessage: "You have to wait for this to fix itself",
           id: "checkDetails.difficultyLow",
         },
         difficultyMedium: {
           defaultMessage: "You can fix this yourself",
           id: "checkDetails.difficultyMedium",
         },
         difficultyHigh: {
           defaultMessage: "You may need technical support to fix this",
           id: "checkDetails.difficultyHigh",
         },
         createTicket: {
           defaultMessage: "Create Ticket",
           id: "checkDetails.createTicket",
         },
         requestHelp: {
           defaultMessage: "Log a Support Ticket",
           id: "checkDetails.requestHelp",
         },
         ticketDetails: {
           defaultMessage: "Support ticket details",
           id: "checkDetails.ticketDetails",
         },
       });

export const i18n = defineMessages({
  problemLabel: {
    defaultMessage: "Here’s what we’ve found",
    id: "dashboardDetails.problemLabel",
  },
  descriptionLabel: {
    defaultMessage: "Why is this important?",
    id: "dashboardDetails.descriptionLabel",
  },
  additionalInfoLabel: {
    defaultMessage: "Where do I get more information?",
    id: "dashboardDetails.additionalInfoLabel",
  },
  actionLabel: {
    defaultMessage: "What to do next",
    id: "dashboardDetails.actionLabel",
  },
  moreDetailsLabel: {
    defaultMessage: "Technical details",
    id: "dashboardDetails.moreDetailsLabel",
  },
  copyText: {
    defaultMessage: "Copy Text",
    id: "dashboardDetails.copyText",
  },
  manageNotifications: {
    defaultMessage: "Manage notification",
    id: "dashboardDetails.manageNotifications",
  },
  completeThisAction: {
    defaultMessage: "I have completed this action",
    id: "dashboardDetails.completeThisAction",
  },
});
