import React, { Fragment, memo } from "react";
import { Field, Form } from "react-final-form";
import { MessageDescriptor, useIntl } from "react-intl";
import {
  Card,
  FormGroup,
  FormInner,
  FormTitle,
  Label,
  AdaptedInput,
  FormControlFeedback,
  Modal,
  Spinner,
} from "@cyberalarm/ui";

import { domainLabel } from "./schema";

interface ModalTemplateProps {
  title: MessageDescriptor;
  children: React.ReactNode;
  isOpen: boolean;
  close: () => void;
  onSubmit: (values: {}) => void;
  initialValues?: { domain: string; disabled?: boolean };
}

const BaseComponent = ({
  title,
  children,
  isOpen,
  close,
  onSubmit,
  initialValues,
}: ModalTemplateProps) => {
  const intl = useIntl();
  return (
    <Fragment>
      <Modal isOpen={isOpen} close={close}>
        <Card p={5} width={{ _: "320px", sm: "400px" }}>
          <FormTitle>{intl.formatMessage(title)}</FormTitle>
          <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, submitting }) => (
              <FormInner onSubmit={handleSubmit}>
                <FormGroup marginTop={5}>
                  <Label>{intl.formatMessage(domainLabel)}</Label>
                  <Field
                    id="domain"
                    name="domain"
                    component={AdaptedInput}
                    disabled={initialValues?.disabled}
                  />
                  <FormControlFeedback name="domain" />
                </FormGroup>
                {!submitting && children}
                {submitting && (
                  <Spinner width="4rem" height="4rem" margin="auto" />
                )}
              </FormInner>
            )}
          </Form>
        </Card>
      </Modal>
    </Fragment>
  );
};

export const ModalTemplate = memo(BaseComponent);