import React, { SVGProps } from "react";

const NotificationOff = ({
  width = 18,
  height = 20,
  fill = "currentColor",
}: SVGProps<{}>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 9C14 5.93 12.36 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C6.29792 2.73052 6.10292 2.80231 5.91502 2.87149L5.915 2.8715L5.81 2.91L14 11.1V9ZM8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.89 20 8 20ZM2.81 5.57L0 2.76L1.41 1.35L17.39 17.33L15.98 18.74L14.24 17H0V16L2 14V9C2 7.73 2.29 6.57 2.81 5.57Z"
      fill={fill}
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default NotificationOff;
