import styled from "styled-components";
import {
  typography,
  TypographyProps,
  space,
  SpaceProps,
  color,
  ColorProps,
} from "styled-system";

type TextProps = TypographyProps & SpaceProps & ColorProps;

export const Text = styled.p<TextProps>(
  { margin: 0 },
  typography,
  space,
  color,
);

Text.defaultProps = {
  color: "logo",
  fontFamily: "Helvetic Neue, Helvetica",
};
