import React from "react";

import { Spinner, Box } from "../atoms";
import { BoxProps } from "../atoms/box";

export const Loader = (props: BoxProps | any) => (
  <Box {...props}>
    <Spinner />
  </Box>
);

Loader.defaultProps = {
  width: "100%",
  height: "200px",
  alignItems: "center",
  justifyContent: "center",
};
