import React, { createContext, useReducer } from "react";

import { DomainActions, DomainList } from "./actions";
import { domainReducer, initialDomainList } from "./reducer";

const initialState = { ...initialDomainList };

export const DomainStateContext = createContext({
  ...initialState,
} as DomainList);

export const DomainDispatchContext = createContext<
  (action: DomainActions) => void
>(() => {});

export const DomainStore = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(domainReducer, initialState);

  return (
    <DomainStateContext.Provider value={state as DomainList}>
      <DomainDispatchContext.Provider value={dispatch}>
        {children}
      </DomainDispatchContext.Provider>
    </DomainStateContext.Provider>
  );
};
