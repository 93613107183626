import { defineMessages } from "react-intl";

export const {
         title,
         noTokensTitle,
         dataBreachAmount,
         manage,
         setupRequired,
         yesLabel,
         noLabel,
         tokenDeleteSuccess,
         tokenDeleteFailure,
       } = defineMessages({
         title: {
           defaultMessage: "Data breaches",
           id: "GDPRTokensList.title",
         },
         dataBreachAmount: {
           defaultMessage: "{value} Data breaches",
           id: "GDPRTokensList.dataBreachAmount",
         },
         noTokensTitle: {
           defaultMessage: "GDPR alarm",
           id: "GDPRTokensList.noTokensTitle",
         },
         manage: {
           defaultMessage: "Setup/manage",
           id: "GDPRTokenManagement.manage",
         },
         setupRequired: {
           defaultMessage: "Setup required",
           id: "GDPRTokensList.setupRequired",
         },
         yesLabel: {
           defaultMessage: "Yes",
           id: "confirmationModal.yes",
         },
         noLabel: {
           defaultMessage: "No",
           id: "confirmationModal.no",
         },
         tokenDeleteSuccess: {
           defaultMessage: "Token deleted",
           id: "GDPRTokensList.tokenDeleteSuccess",
         },
         tokenDeleteFailure: {
           defaultMessage: "Token delete failed",
           id: "GDPRTokensList.tokenDeleteFailure",
         },
       });
