import React from "react";
import { Field } from "react-final-form";
import styled from "styled-components";
import { MessageDescriptor, useIntl } from "react-intl";
import {
  FormGroup,
  Label,
  AdaptedSelect,
  FormControlFeedback,
} from "@cyberalarm/ui";

const SelectGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
`;

interface Value<T> {
  value: T | null;
  label: string;
}

interface CustomSelectProps<T> {
  id: string;
  fontSize?: string;
  options: Value<T>[];
  label: MessageDescriptor;
  skipOptionsFilter?: boolean;
}

export const CustomSelect = <T extends any>({
  id,
  options,
  label,
  skipOptionsFilter,
}: CustomSelectProps<T>) => {
  const intl = useIntl();

  return (
    <SelectGroup>
      <Label htmlFor={id}>
        {intl.formatMessage(label)}
      </Label>
      <Field
        name={id}
        id={id}
        component={(props) => {
          const { input } = props;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { onFocus: _, ...rest } = input;
          return (
            <AdaptedSelect
              {...props}
              options={options as Value<T>[]}
              skipOptionsFilter={skipOptionsFilter}
              input={{
                ...rest,
                ...rest.value,
                onChange: (option: Value<T>) => props.input.onChange(option),
              }}
            />
          );
        }}
      />
      <FormControlFeedback name={id} />
    </SelectGroup>
  );
};
