import styled from "styled-components";
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  grid,
  GridProps as SSGridProps,
} from "styled-system";

export type GridProps = SpaceProps & LayoutProps & SSGridProps;

export const Grid = styled.div<GridProps>(
  { display: "grid" },
  space,
  layout,
  grid,
);
