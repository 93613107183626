import React from "react";
import {
  Box,
  Label,
  AdaptedInput,
  FormGroup,
  FormControlFeedback,
} from "@cyberalarm/ui";
import { useIntl } from "react-intl";
import { Field } from "react-final-form";

import { i18n } from "./schema";

export const RangeField = ({
  minRangeName,
  maxRangeName,
}: {
  minRangeName: string;
  maxRangeName: string;
}) => {
  const intl = useIntl();

  return (
    <Box flexDirection="row" justifyContent="space-between">
      <FormGroup marginRight={2}>
        <Label htmlFor={minRangeName}>
          {intl.formatMessage(i18n.rangeStart)}
        </Label>
        <Field
          id={minRangeName}
          name={minRangeName}
          valid={true}
          type="number"
          min={0}
          component={AdaptedInput}
        />
        <FormControlFeedback name={minRangeName} />
      </FormGroup>

      <FormGroup marginLeft={2}>
        <Label htmlFor={maxRangeName}>
          {intl.formatMessage(i18n.rangeEnd)}
        </Label>
        <Field
          id={maxRangeName}
          name={maxRangeName}
          type="number"
          valid={true}
          min={0}
          component={AdaptedInput}
        />
        <FormControlFeedback name={maxRangeName} />
      </FormGroup>
    </Box>
  );
};
