import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

import { Box } from "./box";

export const CardHeader = styled(Box)`
  border-bottom: 1px solid ${themeGet("colors.grays.0")};
`;

CardHeader.defaultProps = {
  flexDirection: "row",
  height: "54px",
  alignItems: "center",
  justifyContent: "space-between",
  p: "0 24px",
};
