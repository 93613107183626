import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";

import { theme } from "../theme";

export const Tablet = ({ children }: { children: React.ReactNode }) => {
  const isTablet = useMediaQuery({
    minWidth: theme.breakpoints.sm,
    maxWidth: theme.breakpoints.xl,
  });

  return <Fragment>{isTablet ? children : null}</Fragment>;
};
