import styled from "styled-components";
import { typography, TypographyProps } from "styled-system";
import themeGet from "@styled-system/theme-get";

import { getErrorBorder } from "../theme";

export const Input = styled.input<TypographyProps & { valid: boolean }>`
         border-radius: 0;
         border: 1px solid ${themeGet("colors.grays.8")};
         padding: 0 16px;
         font-size: 16px;
         color: ${themeGet("colors.gray")};
         font-family: ${themeGet("fonts.montserrat")};
         ${getErrorBorder}
         font-weight: 400;
         background: ${themeGet("colors.white")};
         height: 48px;

         :focus,
         :active {
           border: 1px solid ${themeGet("colors.brand")};
           outline: none;
         }

         :hover {
           border: 1px solid ${themeGet("colors.grays.2")};
         }

         transition: all 0.2s;
         ${typography}
       `;

Input.defaultProps = {
  type: "text",
};
