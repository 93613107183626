import React, { useContext } from "react";

import { Card, Box, theme, FormTitle, Text } from "@cyberalarm/ui";

import { SubscriptionManagementModal, NotificationsManagementModal } from "ui";
import { useDomainIds } from "hooks";
import { StoreContext } from "store";
import styled from "styled-components";
import { i18n } from "./schema";
import { FormattedMessage } from "react-intl";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 16px 0px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

const StyledCard = styled(Card)`
  width: 50%;
  padding: 0px 24px;
  height: 100%;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    width: 100%;
    height: auto;
  }
`;

export const TeamSettings = () => {
  const domainsCount = useDomainIds().length;
  const { team } = useContext(StoreContext);
  return (
    <StyledCard>
      <Box>
        <Box marginBottom="24px" marginTop="24px">
          <FormTitle>General</FormTitle>
          <Text mt="32px" fontFamily="montserrat" style={{ opacity: 0.8 }}>
            <FormattedMessage {...i18n.settingsGeneralHelpText} />
          </Text>
        </Box>
        <FlexBox>
          <NotificationsManagementModal />
          {!team?.trialMode && (
            <SubscriptionManagementModal domainsCount={domainsCount} />
          )}
        </FlexBox>
      </Box>
    </StyledCard>
  );
};
