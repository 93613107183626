import { useCallback, useContext } from "react";
import { useHistory } from "react-router";
import { resetCookies } from "@cyberalarm/common";
import { cache, mutate } from "swr";

import { domainActions, DomainDispatchContext, StoreContext } from "../store";

const clearCacheFromSWR = () => {
  const keys = cache.keys();
  keys.forEach((key) => {
    mutate(key, undefined, true);
  });
};

export const useLogout = () => {
  const history = useHistory();
  const { setUser } = useContext(StoreContext);
  const domainDispatch = useContext(DomainDispatchContext);
  const handleLogOut = useCallback(() => {
    //remove token
    resetCookies();

    // reset user context
    setUser(null);
    clearCacheFromSWR();
    //reset domain store
    domainDispatch(domainActions.domainStateReset());
    history?.push("/sign-in");
  }, [history, setUser, domainDispatch]);

  return handleLogOut;
};

export const useLogoutWithoutRR = () => {
  const { setUser } = useContext(StoreContext);
  const domainDispatch = useContext(DomainDispatchContext);
  const handleLogOut = useCallback(() => {
    //remove token
    resetCookies();

    // reset user context
    setUser(null);

    //reset domain store
    domainDispatch(domainActions.domainStateReset());
    window.location.pathname = "/sign-in";
    window.location.search = "";
  }, [setUser, domainDispatch]);

  return handleLogOut;
};
