import { useMemo } from "react";
import normalizer from "json-api-normalizer";
import build from "redux-object";
import { api } from "@cyberalarm/common";
import useSWR, { trigger } from "swr";

export interface GdprToken {
  id: string;
  title: string;
  tokenName: string;
  tokenType: string;
  compromised: boolean;
  generatedInfo: {
    token: string;
    fullName: string;
  };
  updatedAt: string;
  createdAt: string;
  checkResults: CheckResult[];
}

export const tokensListRequest = "tokens?token[limit]=10000";

export const useGDPRTokens = (): GdprToken[] => {
  const { data } = useSWR(tokensListRequest, api);

  return useMemo(() => {
    if (data) {
      const normalizedData = normalizer(data);
      const builtData = build(normalizedData, "gdprToken");

      return builtData
        ? builtData.sort((a: GdprToken, b: GdprToken) => {
            return a.compromised === b.compromised ? 0 : a.compromised ? -1 : 1;
          })
        : builtData;
    }
    return data;
  }, [data]);
};

export const deleteTokenAsync = async (
  token: GdprToken | undefined,
  close: (success: boolean) => void
) => {
  const { errors } = await api(`/tokens/${token?.id}`, { method: "DELETE" });
  if (errors) {
    close(false);
  } else {
    trigger(tokensListRequest, true);
    close(true);
  }
};
