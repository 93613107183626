import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";

import { theme } from "../theme";

export const Mobile = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.sm });

  return <Fragment>{isMobile ? children : null}</Fragment>;
};
