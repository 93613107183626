import { defineMessages } from "react-intl";
import * as yup from "yup";
import { isPasswordStrong, passwordConfirmation } from "@cyberalarm/common";

import { required } from "shared-schema";

export const i18n = defineMessages({
  save: {
    defaultMessage: "Save",
    id: "profile.save",
  },
  oldPasswordLabel: {
    defaultMessage: "Old password",
    id: "profile.oldPasswordLabel",
  },
  newPasswordLabel: {
    defaultMessage: "New password",
    id: "profile.newPasswordLabel",
  },
  passwordConfirmationLabel: {
    defaultMessage: "Repeat new password",
    id: "profile.repeatPassword",
  },
  passwordFormTitle: {
    defaultMessage: "Change password",
    id: "profile.passwordFormTitle",
  },
  passwordUpdateSuccess: {
    defaultMessage: "Password has been successfully updated",
    id: "profile.passwordUpdateSuccess",
  },
});

export const { passwordMismatch, minLength, complexity } = defineMessages({
         passwordMismatch: {
           defaultMessage: "Password mismatch",
           id: "profileErrors.passwordMismatch",
         },
         minLength: {
           defaultMessage: "Min length 8 characters",
           id: "profileErrors.minLength",
         },
         complexity: {
           defaultMessage:
             "Password must be at least 8 characters, include upper, lower and special characters but cannot include space, £, \\, ± or §",
           id: "profileErrors.complexity",
         },
       });

export const passwordSchema = yup.object().shape({
         oldPassword: yup.string().required(required.id),
         password: yup
           .string()
           .ensure()
           .test("complexity", complexity.id, (value) => {
             return isPasswordStrong.test(value);
           })
           .required(required.id),
         passwordConfirmation: passwordConfirmation(
           required.id,
           passwordMismatch.id
         ),
       });
