import React, { SVGProps } from "react";

const Notification = ({
  width = 16,
  height = 20,
  fill = "currentColor",
}: SVGProps<{}>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 9V14L16 16V17H0V16L2 14V9C2 5.92 3.63 3.36 6.5 2.68V2C6.5 1.17 7.17 0.5 8 0.5C8.83 0.5 9.5 1.17 9.5 2V2.68C12.36 3.36 14 5.93 14 9ZM10 18C10 19.1 9.1 20 8 20C6.89 20 6 19.1 6 18H10Z"
      fill={fill}
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default Notification;
