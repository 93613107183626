import React, { memo, useMemo, useContext } from "react";
import styled from "styled-components";
import { Route, useHistory } from "react-router-dom";
import themeGet from "@styled-system/theme-get";
import { Box, TruncateText, Grid } from "@cyberalarm/ui";

import { ItemStatus } from "./item-status";
import { StyledLink } from "./components";

import { getUserDomainDashboardUrl } from "routes";
import { StoreContext } from "store";

const Root = styled(Box)`
  cursor: pointer;
  padding: 6px 0px;
  border-bottom: 1px solid ${themeGet("colors.grays.9")};
  :hover {
    color: ${themeGet("colors.logo")};
  }
  transition: all 0.2s;
`;

const StyledTruncateText = styled(TruncateText)`
  font-size: 18px;
`;

interface ListItemProps {
  setID: (id: string) => void;
}

const Item = ({ name, id, setID, ...rest }: ListItemProps & BuiltDomain) => {
  const isHttp = useMemo(() => /http/.test(name), [name]);
  const { sortPreferences } = useContext(StoreContext);
  const text = useMemo(() => (isHttp ? name.split("://")[1] : name), [
    isHttp,
    name,
  ]);
  const path = getUserDomainDashboardUrl(id);
  const history = useHistory();
  const navigate = () => {
    setID(id);
    sortPreferences.currentDomainId = id;
    history.push(getUserDomainDashboardUrl(id));
  };

  return (
    <Route path={path}>
      {({ match }) => (
        <Root>
          <Box>
            <Grid gridTemplateColumns={{ _: "100%" }}>
              <StyledLink
                to={path}
                onClick={navigate}
                style={{ overflowWrap: "anywhere", marginRight: "8px" }}
              >
                <StyledTruncateText
                  fontSize="18px"
                  fontWeight={match ? 600 : 400}
                  title={text}
                  color={match ? "black" : "logo"}
                >
                  {text}
                </StyledTruncateText>
              </StyledLink>
              <ItemStatus
                basePath={path}
                {...rest.meta}
                createdAt={rest.createdAt}
              />
            </Grid>
          </Box>
        </Root>
      )}
    </Route>
  );
};

export const ListItem = memo(Item);
