import React, { useState, useRef } from "react";
import { useOnClickOutside } from "@cyberalarm/common";
import { Box } from "@cyberalarm/ui";
import styled from "styled-components";
import SortMenuItem from "./sort-menu-item";

export const DownArrowRoot = styled(Box)`
  justify-content: center;
  align-items: center;
  margin-left: auto;
  > svg {
    transform-origin: center;
    transform: rotate(180deg);
  }
`;
export const UpArrowRoot = styled(Box)`
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

interface SortMenuProps {
  sortOption: string;
  sortOrder: string;
  options: { value: string; label: string }[];
  onSortChange: (value: string, order: string) => void;
  toggleSortMenuOpen: () => void;
  menuIconRef: React.MutableRefObject<HTMLDivElement | null>;
}

const SortMenu = (props: SortMenuProps) => {
  const {
    options,
    onSortChange,
    sortOption,
    sortOrder,
    toggleSortMenuOpen,
    menuIconRef,
  } = props;
  const [currentOption, setCurrentOption] = useState(sortOption);
  const [currentOrder, setCurrentOrder] = useState(sortOrder);
  const dropdownRef = useRef(null);

  const close = () => {
    toggleSortMenuOpen();
  };

  useOnClickOutside(dropdownRef, close, menuIconRef);

  const handleOptionClick = (value: string) => {
    if (value === currentOption) {
      const newOrder = sortOrder === "asc" ? "desc" : "asc";
      setCurrentOrder(newOrder);
      onSortChange(value, newOrder);
    } else {
      setCurrentOption(value);
      setCurrentOrder("asc");
      onSortChange(value, "asc");
    }
  };

  return (
    <>
        <div
          style={{
            position: "relative",
            display: "inline-block",
            color: "#000000",
          }}
        >
          <ul
            style={{
              position: "absolute",
              top: "0",
              left: "auto",
              right: "100%",
              listStyle: "none",
              padding: "8px",
              marginTop: "10px",
              marginRight: "-10px",
              backgroundColor: "white",
              borderRadius: "8px",
              zIndex: 1000,
              minWidth: "120px",
            }}
            ref={dropdownRef}
          >
            {options.map((option) => (
              <SortMenuItem
                key={option.value}
                isCurrent={currentOption === option.value}
                order={currentOption === option.value ? currentOrder : ""}
                onClick={handleOptionClick}
                value={option.value}
                label={option.label}
              />
            ))}
          </ul>
        </div>
    </>
  );
};

export default SortMenu;
