import React, { memo } from "react";
import { Box, theme, TitleBar } from "@cyberalarm/ui";

import { PasswordForm } from "../../shared/password-form";

import { PersonalDataForm } from "./personal-data-form";

import styled from "styled-components";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Profile = memo(() => {
  return (
    <>
      <TitleBar label="Profile" />
      <FlexBox height="100%" justifyContent="space-between">
        <Box flex="50%">
          <PersonalDataForm />
        </Box>
        <FlexBox flex="50%">
          <PasswordForm />
        </FlexBox>
      </FlexBox>
    </>
  );
});

Profile.displayName = "Profile";
