import styled from "styled-components";
import { compose, typography, TypographyProps } from "styled-system";

import { Text } from "./text";

export type TruncateTextProps = TypographyProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
};

const composedHelpers = compose(typography);

export const TruncateText = styled(Text)<TruncateTextProps>`
         text-overflow: ellipsis;
         overflow-x: hidden;
         white-space: nowrap;
         font-size: 16px;
         font-weight: 200;
         ${composedHelpers}
       `;
