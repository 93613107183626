import React from "react";
import styled from "styled-components";
import { MessageDescriptor, useIntl } from "react-intl";
import themeGet from "@styled-system/theme-get";
import { Text, Box } from "@cyberalarm/ui";
import Linkify from "linkify-react";

export const SectionLabel = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

export const SectionDescription = styled(Text)`
  white-space: pre-wrap;
  font-size: 16px;
  a {
    color: #000000;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const SectionItem = ({
  text,
  label,
}: {
  text: string | null;
  label: MessageDescriptor;
}) => {
  const intl = useIntl();
  return (
    <Box marginBottom={3}>
      <SectionLabel mb="20px">{intl.formatMessage(label)}</SectionLabel>
      <Linkify options={{ target: { url: "_blank" } }}>
        <SectionDescription>{text}</SectionDescription>
      </Linkify>
    </Box>
  );
};

export const SectionRoot = styled(Box)`
  border-top: 1px solid ${themeGet("colors.grays.0")};
`;
