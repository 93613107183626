import React, { createContext, useCallback, useMemo, useState } from "react";

import { DomainStore } from "./domain";

export interface Domain {
  id: string | null;
  name: string | null;
}

export interface InitialState {
  user: User | null;
  team: Team | null;
  sortPreferences: {
    sortOption: string;
    sortOrder: string;
    currentDomainId: string | null;
  };
}

export type InitialContext = InitialState & {
  setUser: (user: User | null) => void;
  setTeam: (team: Team | null) => void;
};

const initialState: InitialState = {
  user: null,
  team: null,
  sortPreferences: {
    sortOption: "name",
    sortOrder: "asc",
    currentDomainId: null,
  },
};

export const StoreContext = createContext({
  ...initialState,
  setUser: () => {},
  setTeam: () => {},
} as InitialContext);

export const Store = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState(initialState);

  const setUser = useCallback((user: User | null) => {
    setState((oldState: InitialState) => {
      if (!user) {
        return { ...oldState, user };
      }

      const newUser = {
        ...(oldState.user && oldState.user),
        ...user,
      };

      return { ...oldState, user: newUser } as InitialState;
    });
  }, []);

  const setTeam = useCallback((team: Team | null) => {
    setState((oldState: InitialState) => {
      if (!team) {
        return { ...oldState, team };
      }

      const newTeam = {
        ...(oldState.team && oldState.team),
        ...team,
      };

      return { ...oldState, team: newTeam } as InitialState;
    });
  }, []);

  const value = useMemo(() => ({ ...state, setUser, setTeam }), [
    state,
    setUser,
    setTeam,
  ]);

  return (
    <StoreContext.Provider value={value}>
      <DomainStore>{children}</DomainStore>
    </StoreContext.Provider>
  );
};
