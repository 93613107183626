import React from "react";
import { Box, Text, theme } from "@cyberalarm/ui";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { getCapitalize } from "@cyberalarm/common";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px 8px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
  }
`;

const GridBox = styled(Box)`
  display: grid;
  gap: 0px;
  grid-template-columns: 10% 15% 12% 8% 15% 20% 10% 10%;
  padding: 8px 0px;
  margin: 8px 0px;
  border: 1px solid #d6d6d6;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    grid-template-columns: 25% 25% 25% 25%;
  }
`;

const StyledText = styled(Text)`
  overflow-wrap: anywhere;
`;

const LabelText = styled(Text)`
  text-align: right;
  padding-right: 4px;
  font-weight: 600;
`;

const SectionTitleText = styled(Text)`
  text-decoration: underline;
  margin: 4px 0px;
`;

interface SupportTicketProps {
  supportTicket: SupportTicket;
}

const getStatus = (str: string) => {
  return getCapitalize(
    str
      .replace("state_", "")
      .split("_")
      .join(" ")
  );
};

export const SupportRequestDetails = ({
  supportTicket,
}: SupportTicketProps) => {
  const intl = useIntl();
  return (
    <FlexBox marginX="10px">
      <GridBox>
        <LabelText>Date :</LabelText>
        <Text>
          {intl.formatDate(supportTicket.ticketCreateDate, {
            year: "numeric",
            month: "short",
            day: "2-digit",
          })}
          {"  "}
          {intl.formatTime(supportTicket.ticketCreateDate, {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })}
        </Text>
        <LabelText>Number :</LabelText>
        <Text>{supportTicket.ticketNumber}</Text>
        <LabelText>Created by :</LabelText>
        <StyledText>{supportTicket.createdBy}</StyledText>
        <LabelText>Status :</LabelText>
        <Text>{getStatus(supportTicket.ticketStatus)}</Text>
      </GridBox>
      {supportTicket.ticketHistory && (
        <>
          <SectionTitleText>Notes history</SectionTitleText>
          <Box
            display="grid"
            style={{
              gridGap: 0,
              width: "98%",
              gridTemplateColumns: "15% 65% 20%",
              fontSize: "14px",
            }}
          >
            {["Date", "Note", "From"].map((h) => (
              <Text style={{ textDecoration: "underline" }}>{h}</Text>
            ))}
            {supportTicket.ticketHistory.notes.map((e) => (
              <>
                <Text marginY="8px" paddingRight="4px">
                  {intl.formatDate(e.date, {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  })}
                  {"  "}
                  {intl.formatTime(e.date, {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
                </Text>
                <Text marginY="8px" paddingRight="4px">
                  {e.body}
                </Text>
                <StyledText marginY="8px" paddingRight="4px">
                  {e.senderType}
                </StyledText>
              </>
            ))}
          </Box>
        </>
      )}
    </FlexBox>
  );
};

SupportRequestDetails.displayName = "SupportRequestDetails";
