import React, { SVGProps } from "react";

const Check = ({
  width = 15,
  height = 12,
  fill = "currentColor",
  strokeWidth,
}: SVGProps<{}>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.77273 9.49254L1.19318 5.73134L0 6.98507L4.77273 12L15 1.25373L13.8068 0L4.77273 9.49254Z"
      fill={fill}
      strokeWidth={strokeWidth}
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default Check;
