import { defineMessages, MessageDescriptor } from "react-intl";
import * as yup from "yup";

export const i18n = defineMessages({
  problemLabel: {
    defaultMessage: "Here’s what we’ve found",
    id: "alerts.problemLabel",
  },
  descriptionLabel: {
    defaultMessage: "Why is this important?",
    id: "alerts.descriptionLabel",
  },
  additionalInfoLabel: {
    defaultMessage: "Where do I get more information?",
    id: "alerts.additionalInfoLabel",
  },
  actionLabel: {
    defaultMessage: "What to do next",
    id: "alerts.actionLabel",
  },
  manageLabel: {
    defaultMessage: "Manage alert",
    id: "alerts.manageLabel",
  },
  disable: {
    defaultMessage: "Disable alerts and emails",
    id: "alerts.disable",
  },
  stopNotify: {
    defaultMessage: "Ok, mark as read",
    id: "alerts.stopNotify",
  },
});

export const difficulty: {
  [key: string]: MessageDescriptor;
} = defineMessages({
  low: {
    defaultMessage: "You have to wait for this to fix itself",
    id: "alerts.difficultyLow",
  },
  medium: {
    defaultMessage: "You can fix this yourself",
    id: "alerts.difficultyMedium",
  },
  high: {
    defaultMessage: "You may need help to fix this",
    id: "alerts.difficultyHigh",
  },
});

export const subscriptionSchema = yup.object().shape({
  plan: yup.string().required(),
});
