import styled from "styled-components";
import { Link } from "react-router-dom";
import themeGet from "@styled-system/theme-get";
import { Box } from "@cyberalarm/ui";

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const StyledLink = styled(Link)`
  height: 100%;
`;

export const Root = styled(Box)`
  min-height: 100%;
  justify-content: space-between;
  position: relative;
`;

export const IconRoot = styled(Box)`
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  background: white;
  z-index: 2;
  color: ${themeGet("colors.gray")};
`;

export const MobileIconRoot = styled(IconRoot)`
  position: fixed;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  justify-content: center;
  z-index: 1001;

  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.2);
`;

export const ListRoot = styled(Box)<{ isOpen: boolean }>`
  min-height: initial;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  background: white;
  padding: 24px;
  height: 100%;

  transform: ${(p) => (p.isOpen ? "translateX(100%)" : "translateX(0)")};

  transition: 0.2s ease-in-out all;
`;

export const MobileListRoot = styled(Box)<{ isOpen: boolean }>`
         width: 100%;
         height: 100%;
         min-height: initial;
         position: absolute;
         background: white;

         z-index: 2;
         top: 0;
         left: 0;

         transition: 0.2s ease-in-out all;
         transform: ${(p) =>
           p.isOpen ? "translateX(0)" : "translateX(-100%)"};

         padding: 25px 25px 0;
       `;
