import React, { useEffect, memo } from "react";
import { useLocation, useHistory } from "react-router";
import { FormattedMessage, defineMessages } from "react-intl";
import { BaseTemplate, Text, Box } from "@cyberalarm/ui";
import { api } from "@cyberalarm/common";
import useSWR from "swr";

import { CommonRoutes } from "routes";

const { titleError, titleSuccess } = defineMessages({
  titleError: {
    defaultMessage:
      "Ooops! Something went wrong. Please contact System Administrator",
    id: "emailVerification.fail",
  },
  titleSuccess: {
    defaultMessage: "Success! Your email address has been verified",
    id: "emailVerification.success",
  },
});

const useVerify = () => {
  const location = useLocation();

  const parameters = new URLSearchParams(location.search);
  const confirmationToken = parameters.get("confirmation_token");
  const { data, error, isValidating } = useSWR(confirmationToken, (token) =>
    api(`users/confirmation?confirmation_token=${token}`)
  );

  return { data, error, isValidating };
};

export const EmailVerification = memo(() => {
  const history = useHistory();
  const { data, error, isValidating } = useVerify();

  useEffect(() => {
    if (!isValidating && !error && data) {
      const attributes = data.data.attributes;
      const email = attributes.email;
      const product = attributes.plan_preferences.product;
      const plan = attributes.plan_preferences.plan;
      const number_of_domains = attributes.plan_preferences.domains;
      history.push({
        pathname: CommonRoutes.newCheckout,
        state: {
          email: email,
          product: product,
          plan: plan,
          domains: number_of_domains,
        },
      });
    }
  }, [history, data, error, isValidating]);

  return (
    <BaseTemplate>
      <Box alignItems="center" justifyContent="center" flex={1}>
        {!isValidating && !error && (
          <>
            <Text fontSize={5} color="white">
              <FormattedMessage {...titleSuccess} />
            </Text>
          </>
        )}
        {!isValidating && error && (
          <Text fontSize={5} color="white">
            <FormattedMessage {...titleError} />
          </Text>
        )}
      </Box>
    </BaseTemplate>
  );
});

EmailVerification.displayName = "EmailVerification";
