import React, { memo } from "react";
import styled from "styled-components";
import { Link, Box } from "@cyberalarm/ui";

import { Control } from "./control";

import { AdminRoutes } from "routes";

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledLink = styled(Link)`
  height: 100%;
`;

const Root = styled(Box)`
  min-height: 100%;
  padding: 25px;
  justify-content: space-between;
  position: relative;
  overflow: auto;
`;

export const BaseComponent = ({
  children,
}: {
  children: React.ReactNode;
  profileUrl: string;
  teamUrl: string;
}) => {
  return (
    <Root padding="25px" flex={1}>
      <Box height="62px" alignItems="center" justifyContent="center">
        <StyledLink to={AdminRoutes.activeDomainList}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt="CYBER ALARM"
          />
        </StyledLink>
      </Box>
      {children}
      <Control />
    </Root>
  );
};

export const SideBar = memo(BaseComponent);
