import React from "react";
import {
  Card,
  Box,
  Text,
  Button,
  FormInner,
  AdaptedRadio,
  Spinner,
} from "@cyberalarm/ui";
import { Field, Form } from "react-final-form";
import { form } from "@cyberalarm/common";

import { redirectToStripeCheckout, useSubscriptionPlans } from "./hooks";
import { subscriptionSchema } from "./schema";

interface PaymentRequiredProps {
  close: () => void;
}

export const PaymentRequired = ({ close }: PaymentRequiredProps) => {
  const { builtPlans } = useSubscriptionPlans();

  const onSubmit = (value: any) => {
    redirectToStripeCheckout(value.plan, close);
  };

  return (
    <>
      <Card
        width={["400px", "500px", "1000px"]}
        p={4}
        overflow="auto"
        display="block"
      >
        <Box marginX={8} marginY={6} alignItems="left" flex={1}>
          <Box alignItems="center">
            <Box
              as="img"
              width="86px"
              height="86px"
              src="/images/logo-symbol.png"
            />
          </Box>
          <Box alignItems="left">
            <Text mt="50px" ml="40px" fontSize="18px" fontFamily="Raleway">
              Thanks for choosing Cyber Alarm. <br />
              <br />
              While you are thinking about your cyber security there are a few things we recommend:<br />
            </Text>
            <Text mt="20px" ml="80px" fontSize="18px" fontFamily="Raleway">
              - Make sure you know your login information for all your key services such 
              as domain, website, email account, etc. In many cases you will need to access 
              these services to resolve any incident.<br />
              <br />
              - Take the opportunity to discuss cyber security with your team, including steps 
              that everyone can take like using strong passwords, backing up your files and 
              updating your software regularly. These can all help prevent cyber security issues in the first place.<br />
              <br />
              - Keep an eye on our blog page where we regularly post updates on things like how 
              to find the right IT support provider, how to choose a strong password 
              or lessons that can be learnt from high profile cyber breaches.
            </Text>
            <Text mt="20px" mb="10px" ml="40px" fontSize="18px" fontFamily="Raleway">
              Now please choose which level of security you’d like from us:
            </Text>
          </Box>

          <Form
            onSubmit={onSubmit}
            validate={form.validateForm({ schema: subscriptionSchema })}
          >
            {({ handleSubmit }) => {
              return (
                <FormInner onSubmit={handleSubmit}>
                  <Box mt={2} ml={40} alignItems="left">
                    {builtPlans?.length !== 0 && builtPlans !== undefined ? (
                      builtPlans?.map((plan) => (
                        <Field
                          key={plan?.id}
                          component={AdaptedRadio}
                          id={plan?.name}
                          name="plan"
                          label={plan?.description}
                          type="radio"
                          value={plan?.id}
                        />
                      ))
                    ) : (
                      <Box alignItems="center" width="100%">
                        <Spinner />
                      </Box>
                    )}

                    <Box alignItems="center">
                      <Button mt="40px" type="submit" variant="secondary">
                        Choose subscription
                      </Button>
                    </Box>
                  </Box>
                </FormInner>
              );
            }}
          </Form>
        </Box>
      </Card>
    </>
  );
};
