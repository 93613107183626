import React, { memo } from "react";
import { Form, Field } from "react-final-form";
import {
  FormButton,
  Box,
  FormInner,
  FormGroup,
  Label,
  AdaptedInput,
  AdaptedSelect,
  FormControlFeedback,
} from "@cyberalarm/ui";
import {
  useProductOptions,
  usePlanOptions,
  useDomainsQtyOptions,
} from "./hooks";

import { CustomSelect } from "ui";

import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { form } from "@cyberalarm/common";

import { manageSubscriptionSchema } from "./schema";

import { productLabel, planLabel, domainsLabel } from "shared-schema";

const SelectGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
`;

interface ModalProps {
  close: () => void;
}
interface SubscriptionPreferencesProps {
  product: string;
  plan: string;
  domains: number;
  curreuntDomainsCount: number;
  onSubmit: (value: any) => void;
}

const SubscriptionPreferences = (props: SubscriptionPreferencesProps) => {
  const productOptions = useProductOptions();
  const planOptions = usePlanOptions();
  const domainsQtyOptions = useDomainsQtyOptions();

  return (
    <Box justifyContent="center" flex={1}>
      <Form
        initialValues={{
          product: {
            value: props.product,
          },
          plan: {
            value: props.plan,
          },
          domainsInput: props.domains,
          domainsSelect: {
            value: Math.ceil((props.domains ? props.domains : 0) / 10) * 10,
          },
        }}
        onSubmit={props.onSubmit}
        validate={form.validateForm({
          schema: manageSubscriptionSchema,
        })}
      >
        {({ values, handleSubmit, pristine, submitting }) => {
          return (
            <FormInner
              key="subscriptionPreferencesForm"
              onSubmit={handleSubmit}
            >
              <Box flex={1} justifyContent="center" alignItems="center">
                <Box
                  marginLeft={[0, 2]}
                  flex={1}
                  verticalAlign="center"
                  marginTop={2}
                >
                  <CustomSelect<
                    | {
                        value: string;
                      }
                    | string
                  >
                    id="product"
                    label={productLabel}
                    options={productOptions}
                  />
                  <CustomSelect<
                    | {
                        value: string;
                      }
                    | string
                  >
                    id="plan"
                    label={planLabel}
                    options={planOptions}
                  />
                  {values.plan.value === "essential" && (
                    <FormGroup>
                      <Label htmlFor="domainsInput">
                        <FormattedMessage {...domainsLabel} />
                      </Label>
                      <Field
                        id="domainsInput"
                        name="domainsInput"
                        component={AdaptedInput}
                      />
                      <FormControlFeedback name="domainsInput" />
                    </FormGroup>
                    //<TextField id="domains" label={domainsLabel} />
                  )}
                  {values.plan.value === "premium" && (
                    <SelectGroup>
                      <Label htmlFor="domainsSelect">
                        <FormattedMessage {...domainsLabel} />
                      </Label>
                      <Field
                        name="domainsSelect"
                        id="domainsSelect"
                        component={(props: any) => {
                          const { input } = props;
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          const { onFocus: _, ...rest } = input;
                          return (
                            <AdaptedSelect
                              {...props}
                              options={domainsQtyOptions}
                              input={{
                                ...rest,
                                ...rest.value,
                                onChange: (option: {
                                  value: string;
                                  label: string;
                                }) => props.input.onChange(option),
                              }}
                            />
                          );
                        }}
                      />
                      <FormControlFeedback name="domainsSelect" />
                    </SelectGroup>
                  )}
                </Box>
                <FormButton
                  mt={3}
                  type="submit"
                  variant="primary"
                  disabled={submitting || pristine}
                  margin="auto"
                >
                  Update subscription
                </FormButton>
              </Box>
            </FormInner>
          );
        }}
      </Form>
    </Box>
  );
};

export const SubscriptionPreferencesForm = memo(SubscriptionPreferences);

SubscriptionPreferencesForm.displayName = "SubscriptionPreferencesForm";
