import React, { SVGProps } from "react";

export const Target = ({
  fill = "none",
  stroke = "#1C274C",
  width = 14,
  height = 14,
}: SVGProps<{}>) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} >

    <g id="SVGRepo_bgCarrier" strokeWidth="0" />

    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

    <g id="SVGRepo_iconCarrier"> <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke={stroke} strokeWidth="1.5" /> <path opacity="0.5" d="M2 12L5 12" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" /> <path opacity="0.5" d="M19 12L22 12" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" /> <path opacity="0.5" d="M12 22L12 19" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" /> <path opacity="0.5" d="M12 5L12 2" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" /> <path d="M12 12H10M12 12H14M12 12V14M12 12L12 10" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </g>

  </svg>
);

// eslint-disable-next-line import/no-default-export
export default Target;
