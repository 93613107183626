import { defineMessages } from "react-intl";
import * as yup from "yup";

import { required } from "shared-schema";

export const i18n = defineMessages({
         supportPageTitle: {
           defaultMessage: "Support",
           id: "support.Title",
         },
         createTicketLabel: {
           defaultMessage: "Create Ticket",
           id: "support.createTicketLabel",
         },
         selectDomainsLabel: {
           defaultMessage: "Select domains",
           id: "support.selectDomainsLabel",
         },
         noResultsForGrid: {
           defaultMessage:
             "No results found or ticket(s) exist for all the current alarms and warnings.",
           id: "support.noResultsForGrid",
         },
       });

export const newTicketSchema = yup.object().shape({
  requestDescription: yup
    .string()
    .ensure()
    .required(required.id),
});
