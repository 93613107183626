export enum ActionTypes {
  DOMAINS_LIST_REQUEST = "DOMAINS_LIST_REQUEST",
  DOMAINS_LIST_SUCCESS = "DOMAINS_LIST_SUCCESS",
  DOMAINS_LIST_FAILURE = "DOMAINS_LIST_FAILURE",

  DOMAIN_CREATE_REQUEST = "DOMAIN_CREATE_REQUEST",
  DOMAIN_CREATE_SUCCESS = "DOMAIN_CREATE_SUCCESS",
  DOMAIN_CREATE_FAILURE = "DOMAIN_CREATE_FAILURE",

  DOMAIN_READ_REQUEST = "DOMAIN_READ_REQUEST",
  DOMAIN_READ_SUCCESS = "DOMAIN_READ_SUCCESS",
  DOMAIN_READ_FAILURE = "DOMAIN_READ_FAILURE",

  DOMAIN_UPDATE_REQUEST = "DOMAIN_UPDATE_REQUEST",
  DOMAIN_UPDATE_SUCCESS = "DOMAIN_UPDATE_SUCCESS",
  DOMAIN_UPDATE_FAILURE = "DOMAIN_UPDATE_FAILURE",

  DOMAIN_DELETE_REQUEST = "DOMAIN_DELETE_REQUEST",
  DOMAIN_DELETE_SUCCESS = "DOMAIN_DELETE_SUCCESS",
  DOMAIN_DELETE_FAILURE = "DOMAIN_DELETE_FAILURE",

  DOMAIN_STATE_RESET = "DOMAIN_STATE_RESET",
}

export interface DomainEntity {
  domain: null | Entity<Domain>;
}

export type DomainList = NormalizedResponse<DomainEntity>;

// list
export const domainsListRequest = () => {
  return {
    type: ActionTypes.DOMAINS_LIST_REQUEST,
  } as const;
};

export const domainsListSuccess = (payload: DomainList) => {
  return {
    type: ActionTypes.DOMAINS_LIST_SUCCESS,
    payload,
  } as const;
};

export const domainsListFailure = () => {
  return {
    type: ActionTypes.DOMAINS_LIST_FAILURE,
  } as const;
};

// create
export const domainCreateRequest = () => {
  return {
    type: ActionTypes.DOMAIN_CREATE_REQUEST,
  } as const;
};

export const domainCreateSuccess = (payload: DomainEntity) => {
  return {
    type: ActionTypes.DOMAIN_CREATE_SUCCESS,
    payload,
  } as const;
};

export const domainCreateFailure = () => {
  return {
    type: ActionTypes.DOMAIN_CREATE_FAILURE,
  } as const;
};

// read
export const domainReadRequest = () => {
  return {
    type: ActionTypes.DOMAIN_READ_REQUEST,
  } as const;
};

export const domainReadSuccess = (payload: DomainList) => {
  return {
    type: ActionTypes.DOMAIN_READ_SUCCESS,
    payload,
  } as const;
};

export const domainReadFailure = () => {
  return {
    type: ActionTypes.DOMAIN_READ_FAILURE,
  } as const;
};

// update
export const domainUpdateRequest = () => {
  return {
    type: ActionTypes.DOMAIN_UPDATE_REQUEST,
  } as const;
};

export const domainUpdateSuccess = (payload: DomainEntity) => {
  return {
    type: ActionTypes.DOMAIN_UPDATE_SUCCESS,
    payload,
  } as const;
};

export const domainUpdateFailure = () => {
  return {
    type: ActionTypes.DOMAIN_UPDATE_FAILURE,
  } as const;
};

// delete
export const domainDeleteRequest = () => {
  return {
    type: ActionTypes.DOMAIN_DELETE_REQUEST,
  } as const;
};

export const domainDeleteSuccess = (payload: string) => {
  return {
    type: ActionTypes.DOMAIN_DELETE_SUCCESS,
    payload,
  } as const;
};

export const domainDeleteFailure = () => {
  return {
    type: ActionTypes.DOMAIN_DELETE_FAILURE,
  } as const;
};

export const domainStateReset = () => {
  return {
    type: ActionTypes.DOMAIN_STATE_RESET,
  } as const;
};

export type DomainActions =
  // list
  | ReturnType<typeof domainsListRequest>
  | ReturnType<typeof domainsListSuccess>
  | ReturnType<typeof domainsListFailure>
  // create
  | ReturnType<typeof domainCreateRequest>
  | ReturnType<typeof domainCreateSuccess>
  | ReturnType<typeof domainCreateFailure>
  // read
  | ReturnType<typeof domainReadRequest>
  | ReturnType<typeof domainReadSuccess>
  | ReturnType<typeof domainReadFailure>
  // update
  | ReturnType<typeof domainUpdateRequest>
  | ReturnType<typeof domainUpdateSuccess>
  | ReturnType<typeof domainUpdateFailure>
  // delete
  | ReturnType<typeof domainDeleteRequest>
  | ReturnType<typeof domainDeleteSuccess>
  | ReturnType<typeof domainDeleteFailure>
  | { type: ActionTypes.DOMAIN_STATE_RESET };
