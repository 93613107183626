import React, { SVGProps } from "react";

const Minus = ({
  width = 18,
  height = 18,
  fill = "currentColor",
}: SVGProps<{}>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.74229e-08 10L0 8L18 8V10L8.74229e-08 10Z" fill={fill} />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default Minus;
