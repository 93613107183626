import styled, { keyframes } from "styled-components";
import themeGet from "@styled-system/theme-get";

import { Box } from "./box";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(Box)<{ background?: string }>`
         position: relative;
         animation: ${spin} 2s ease-in-out infinite;
         border-radius: 50%;
         background: linear-gradient(
           to left,
           ${themeGet("colors.black")},
           transparent
         );

         ::after {
           content: "";
           position: absolute;
           left: 50%;
           top: 50%;
           transform: translate(-50%, -50%);
           background: ${(p) => p.background};
           width: 93%;
           height: 93%;
           border-radius: 50%;
         }

         ::before {
           content: "";
           position: absolute;
           width: 50%;
           height: 40%;
           background: ${(p) => p.background};
           border-radius: 100% 0 0 0;
           top: 0;
           left: 0;
         }
       `;

Spinner.defaultProps = {
  background: "white",
  width: "120px",
  height: "120px",
};
