import React from "react";
import { Field } from "react-final-form";
import { useIntl } from "react-intl";
import { FieldErrorType } from "@cyberalarm/common";

import {
  Checkbox,
  Feedback,
  Input,
  Select,
  Textarea,
  NumberInput,
  InputRadio,
} from "../atoms";

// eslint-disable-next-line react/display-name
const adapt = (W: React.ComponentType<any>) => ({
  input,
  meta: { touched, valid, dirtySinceLastSubmit },
  ...rest
}: any) => {
  const componentProps = {
    ...input,
    ...rest,
    valid: touched && !dirtySinceLastSubmit ? valid : undefined,
  };

  return <W {...componentProps} />;
};

export const AdaptedCheckbox = adapt(Checkbox);
export const AdaptedInput = adapt(Input);
export const AdaptedNumberInput = adapt(NumberInput);
export const AdaptedSelect = adapt(Select);
export const AdaptedTextarea = adapt(Textarea);
export const AdaptedRadio = adapt(InputRadio);

interface ErrorMessageProps {
  message?: string | null;
  type: FieldErrorType;
}

const getMessage = (message: string | object): string => {
  if (typeof message === "string") {
    return message;
  }

  return Object.values(message).join(", ");
};

const extractErrorMessage = ({
  touched = false,
  error = null,
  dirtySinceLastSubmit = false,
  submitError = null,
  submitting = false,
}): ErrorMessageProps => {
  const hasValidationError = error && touched;

  if (hasValidationError) {
    return {
      message: getMessage((error as unknown) as string | object),
      type: FieldErrorType.validation,
    };
  }

  const hasSubmitError = submitError && !dirtySinceLastSubmit && !submitting;

  if (hasSubmitError) {
    return {
      message: submitError,
      type: FieldErrorType.submit,
    };
  }

  return {
    message: null,
    type: FieldErrorType.none,
  };
};

interface FormControlFeedbackProps {
  name: string;
  absolute?: boolean;
}

export const FormControlFeedback = ({
  name,
  absolute,
}: FormControlFeedbackProps) => {
  const intl = useIntl();

  return (
    <Field name={name}>
      {({ meta }) => {
        const { message, type } = extractErrorMessage(meta);
        const valid = type === FieldErrorType.none;

        return (
          <Feedback
            position={absolute ? "absolute" : "relative"}
            bottom={absolute ? 0 : "auto"}
          >
            {valid ? null : intl.formatMessage({ id: message as string })}
          </Feedback>
        );
      }}
    </Field>
  );
};
