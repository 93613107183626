import { css, createGlobalStyle } from "styled-components";
import { CheckResult } from "@cyberalarm/common";

export const GlobalStyles = createGlobalStyle`
  * {
      box-sizing: border-box;
  }
`;

const colors = {
  white: "#ffffff",
  black: "#000000",
  inputTextColor: "#09402b",
  logo: "#222450",
  brand: "#09402B",
  primary: "#2D2DFF",
  statusOk: "#A5D560",
  statusError: "#E25A5A",
  statusWarning: "#F6955A",
  background: "#FFFFFF",
  backgrounds: ["#E8EEF0", "#ECF0EC"],
  buttonBorders: ["#09402b"],
  gray: "#323C47",
  grays: [
    "#F5F2F2",
    "#F0F1F6",
    "#A3A8B3",
    "#6A707E",
    "#474D54",
    "#848A99",
    "#c4c4c4",
    "#A1A1A1",
    "#D6D6D6",
    "#D0D0D0",
  ],
  blue: "#109CF1",
  blues: ["#334d6e"],
  greens: ["#11876f", "#09402B", "#DAE3DB"],
  labels: ["#223528"],
  values: ["#222450"],
};

export const theme = {
         breakpoints: ["40em", "52em", "64em", "80em"] as string[] & {
           [key: string]: string;
         },
         //         0   1   2   3   4   5   6   7   8
         fontSizes: [12, 13, 14, 16, 24, 32, 48, 64, 72],
         // margin and padding
         //      0  1  2  3   4   5   6    7   8   9   10
         space: [0, 4, 8, 12, 16, 24, 32, 48, 64, 128, 256],
         colors,
         buttons: {
           primary: {
             color: colors.white,
             backgroundColor: colors.black,
             padding: "9px 18px",
             border: `1px solid ${colors.black}`,
             borderRadius: "35px",
           },
           secondary: {
             color: colors.white,
             backgroundColor: colors.statusError,
             padding: "16px 21px",
             border: `1.5px solid ${colors.statusError}`,
           },
           outline: {
             color: colors.statusError,
             backgroundColor: colors.white,
             border: `1.5px solid ${colors.statusError}`,
             padding: "9px 26px",
           },
           danger: {
             color: colors.white,
             backgroundColor: colors.statusError,
             padding: "9px 18px",
             border: `1px solid ${colors.statusError}`,
             borderRadius: "35px",
           },
           link: {
             color: colors.white,
             backgroundColor: colors.grays[4],
             padding: "9px 18px",
             border: `1px solid ${colors.grays[4]}`,
             borderRadius: "35px",
           },
         },
         fonts: {
           title: `Times New Roman`,
           raleway: `Helvetic Neue, Helvetica`,
           montserrat: `Helvetic Neue, Helvetica`,
         },
       };

theme.breakpoints.sm = theme.breakpoints[0];
theme.breakpoints.md = theme.breakpoints[1];
theme.breakpoints.lg = theme.breakpoints[2];
theme.breakpoints.xl = theme.breakpoints[3];

export const getErrorBorder = css`
  border: 1px solid
    ${(p: { valid?: boolean }) => {
      if (p.valid != null && !p.valid) {
        return "#E25A5A";
      }
      return "#D6D6D6";
    }};
`;

export const buttonStyles = css`
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  :hover:not(:disabled) {
    box-shadow: 0 12px 12px rgba(50, 50, 93, 0.11),
      0 12px 12px rgba(0, 0, 0, 0.08);
  }

  :active {
    opacity: 0.9;
  }

  :active,
  :focus {
    outline: none;
  }

  :disabled {
    filter: brightness(80%);
  }

  transition: all 0.2s;
`;

export const getStatusColor = (result: CheckResult) => {
  switch (result) {
    case CheckResult.passed:
      return theme.colors.statusOk;
    case CheckResult.failed:
      return theme.colors.statusError;
    case CheckResult.timeouts:
      return theme.colors.logo;

    default:
      return theme.colors.statusWarning;
  }
};
