import styled, { css } from "styled-components";
import themeGet from "@styled-system/theme-get";
import { Card, Circle as CircleTemplate, Box, theme } from "@cyberalarm/ui";

const getActiveColor = css`
  background: ${(p: { mainColor: string }) => themeGet(p.mainColor)(p)};
  color: white;
`;
/*
const getColor = css`
  background: white;
  color: ${(p: { mainColor: string }) => themeGet(p.mainColor)(p)};
  :hover {
    background: ${(p: { mainColor: string }) => themeGet(p.mainColor)(p)};
    color: white;
  }
`;
*/
const getColor = css`
  background: ${(p: { mainColor: string }) => themeGet(p.mainColor)(p)};
  color: white;
`;
export const Root = styled(Card)`
         grid-column: 1 / -1;
         position: relative;
         border-radius: 10px;
         padding: 0;
         padding-bottom: 24px;
         min-height: 235px;
         :hover {
           box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
         }

         transition: all 0.2s;
       `;

export const CardHeader = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  padding: 0 24px;
  border-bottom: 1px solid ${themeGet("colors.grays.0")};
`;

interface CircleProps {
  mainColor: string;
  isActive: boolean;
}

export const Circle = styled(CircleTemplate)<CircleProps>`
  cursor: pointer;
  font-size: 56px;
  transition: 0.2s;
  font-weight: 500;
  margin: 24px 0;
  border-color: ${(p: { mainColor: string }) => themeGet(p.mainColor)(p)};
  border-style: solid;

  ${(p) => (p.isActive ? getActiveColor : getColor)};

  @media screen and (max-width: ${theme.breakpoints.md}) {
    font-size: 32px;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    font-size: 24px;
  }
`;

export const Dot = styled(CircleTemplate)`
  width: 10px;
  height: 10px;
  margin-right: 6px;
`;
