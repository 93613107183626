import React, { memo, useCallback } from "react";
import { FormattedMessage, FormattedRelativeTime } from "react-intl";
import styled from "styled-components";
import { Status, StatusColors, getRelativeDateTime } from "@cyberalarm/common";
import { Text, CardHeader, Spinner, Box } from "@cyberalarm/ui";
import { useHistory } from "react-router";

import { i18n } from "./schema";
import { Root, Circle, Dot } from "./components";

import { getDifferenceInMinutes } from "utils/get-difference-in-minutes";
import { CommonRoutes } from "routes";

const SmallSpinner = styled(Spinner)`
  width: 16px;
  height: 16px;
`;
const StyledHeaderText = styled(Text)`
  font-size: 20px;
  font-weight: 600;
`;
const StyledInfoText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;

interface StatusCardProps {
  activeStatus: Status | null;
  domain: BuiltDomain | null;
}

const twoMinutes = 2;

const BaseCard = ({ activeStatus, domain }: StatusCardProps) => {
  const history = useHistory();
  const resetState = useCallback(() => history.push({ search: "" }), [history]);
  if (domain === null) {
    history.push(CommonRoutes.root);
  }
  const isCreatedRecently =
    domain && getDifferenceInMinutes(domain.createdAt) < twoMinutes;

  const hasStatus =
    domain &&
    Boolean(domain.meta.passedCount) &&
    Boolean(domain.meta.failedCount) &&
    Boolean(domain.meta.warningsCount);
  const isNewDomain = isCreatedRecently && !hasStatus;

  return (
    <Root
      width="100%"
      height={["fit-content", "100%"]}
      maxHeight={["100%", "313px"]}
      flex={1}
    >
      <CardHeader
        flexDirection={["column", "row"]}
        p={["24px", "0 24px"]}
        height={["fit-content", "54px"]}
        alignItems="center"
      >
        <StyledHeaderText>
          <FormattedMessage
            {...i18n.title}
            values={{ name: domain ? domain.name : " " }}
          />
        </StyledHeaderText>

        <StyledInfoText color="grays.3" marginRight="5px">
          <FormattedMessage
            {...i18n.lastScan}
            values={{
              time: (
                <StyledInfoText as="span">
                  {domain && domain.meta.lastScanAt ? (
                    <FormattedRelativeTime
                      {...getRelativeDateTime(domain.meta.lastScanAt)}
                    />
                  ) : (
                    <FormattedMessage {...i18n.inProgress} />
                  )}
                </StyledInfoText>
              ),
            }}
          />
        </StyledInfoText>
      </CardHeader>
      <Box
        flexDirection={["column", "row"]}
        alignItems="center"
        justifyContent="space-evenly"
        onClick={resetState}
      >
        {domain &&
          Object.keys(Status).map((key) => {
            const color = StatusColors[key as keyof typeof StatusColors];
            return (
              <Box alignItems="center" key={key}>
                <Circle
                  width={["128px", "128px", "168px"]}
                  height={["128px", "128px", "168px"]}
                  borderWidth={["4px", "8px", "12px"]}
                  mainColor={`colors.${color}`}
                  isActive={key === activeStatus}
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push({ search: `?status=${key}` });
                  }}
                >
                  {isNewDomain ? <SmallSpinner /> : domain.meta[`${key}Count`]}
                </Circle>
                <Box flexDirection="row" alignItems="center">
                  <Dot bg={color} />
                  <StyledInfoText>
                    <FormattedMessage {...i18n[key]} />
                  </StyledInfoText>
                </Box>
              </Box>
            );
          })}
      </Box>
    </Root>
  );
};

export const StatusCard = memo(BaseCard);
