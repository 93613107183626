import React from "react";
import { Desktop } from "@cyberalarm/ui";

import { Root } from "./components";

import { DomainManagement } from "ui";

import { useLocation } from "react-router";

import { SubscriptionInfo } from "./subscription-info";

export const DesktopSidebar = () => {
  const location = useLocation();
  const showSubscriptionInfo =
    location.pathname === "/user/team" || location.pathname === "/user/profile";

  return (
    <Desktop>
      <Root padding="25px" paddingRight="0px" flex={1}>
        {showSubscriptionInfo && <SubscriptionInfo />}
        {!showSubscriptionInfo && <DomainManagement />}
      </Root>
    </Desktop>
  );
};
