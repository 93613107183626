import React, { memo } from "react";
import { Field } from "react-final-form";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { StatusColors } from "@cyberalarm/common";
import {
  AdaptedInput,
  Button,
  FormControlFeedback,
  FormGroup,
  Label,
  Box,
  Text,
} from "@cyberalarm/ui";

import { i18n } from "./schema";
import { useEmailConfirmation } from "./hooks";

const StyledInput = styled(AdaptedInput)`
  width: 100%;
`;

interface EmailFieldProps {
  confirmed: boolean;
  email: string;
}

export const EmailField = memo(({ confirmed, email }: EmailFieldProps) => {
  const handleVerification = useEmailConfirmation({ email });
  return (
    <FormGroup>
      <Label htmlFor="email">
        <FormattedMessage {...i18n.emailLabel} />
      </Label>
      <Field
        id="email"
        name="email"
        component={(props) => (
          <Box flexDirection="row" alignItems="center">
            <StyledInput {...props} disabled />
            {confirmed ? (
              <Box
                bg={StatusColors.passed}
                height="48px"
                width="90px"
                alignContent="center"
                justifyContent="center"
              >
                <Text
                  textAlign="center"
                  color="white"
                  fontSize="16px"
                  fontWeight={500}
                >
                  <FormattedMessage {...i18n.verifiedLabel} />
                </Text>
              </Box>
            ) : (
              <Button
                variant="primary"
                height="56px"
                type="button"
                onClick={handleVerification}
              >
                <FormattedMessage {...i18n.verifyButtonLabel} />
              </Button>
            )}
          </Box>
        )}
      />
      <FormControlFeedback name="email" />
    </FormGroup>
  );
});

EmailField.displayName = "EmailField";
