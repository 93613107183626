import React from "react";
import styled from "styled-components";
import { Circle } from "./circle";

import { getErrorBorder } from "../theme";

import { Box } from "./box";

const Root = styled(Box)<{ valid?: boolean }>`
  ${getErrorBorder};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 16px;
`;

interface CheckboxProps {
  checked: boolean;
  onClick: () => void;
  valid?: boolean;
  bg?: string;
}

export const RoundCheckbox = ({
  checked,
  onClick,
  valid,
  bg,
}: CheckboxProps) => (
  <Root valid={valid} onClick={onClick}>
    {checked && <Circle width="16px" height="16px" bg={bg ? bg : "#000000"} />}
  </Root>
);
