import { defineMessages } from "react-intl";
import * as yup from "yup";
import { getFile } from "./hooks"

import { formatsJPGorPNG, invalidEmail, maxFileSize4MB, required } from "shared-schema";

export const i18n = defineMessages({
         personalDataFormTitle: {
           defaultMessage: "Personal",
           id: "profile.personalDataFormTitle",
         },
         reportPreferencesFormTitle: {
           defaultMessage: "Report preferences",
           id: "profile.reportPreferencesFormTitle",
         },
         firstNameLabel: {
           defaultMessage: "First name",
           id: "profile.firstName",
         },
         lastNameLabel: {
           defaultMessage: "Last name",
           id: "profile.lastName",
         },
         emailLabel: {
           defaultMessage: "Email",
           id: "profile.email",
         },
         countryLabel: {
           defaultMessage: "Country",
           id: "profile.country",
         },
         companyLabel: {
           defaultMessage: "Company",
           id: "profile.companyLabel",
         },
         phoneLabel: {
           defaultMessage: "Phone",
           id: "profile.phone",
         },
         save: {
           defaultMessage: "Save",
           id: "profile.save",
         },
         profileUpdateSuccess: {
           defaultMessage: "Data has been successfully updated",
           id: "profile.profileUpdateSuccess",
         },
         verifyButtonLabel: {
           defaultMessage: "Verify",
           id: "profile.verifyButtonLabel",
         },
         verifiedLabel: {
           defaultMessage: "Verified",
           id: "profile.verifiedLabel",
         },
         reportTitleLabel: {
           defaultMessage: "Title",
           id: "profile.reportTitleLabel",
         },
         reportTitleHelp: {
           defaultMessage: "[Max 80 chars. Use ## as domain placeholder]",
           id: "profile.reportTitleHelp",
         },
         reportLogoLabel: {
           defaultMessage: "Your logo",
           id: "profile.reportLogoLabel",
         },
         reportLogoHelp: {
           defaultMessage: "[Max 4 MB, JPEG or PNG only]",
           id: "profile.reportLogoHelp",
         },
         mailVerificationToast: {
           defaultMessage: "Please check your mail to confirm it",
           id: "profile.mailVerificationToast",
         },
         reportPreferencesToast: {
           defaultMessage: "Report preferences were succcessfully updated",
           id: "profile.reportPreferencesToast",
         },
         reportGenerationWait: {
           defaultMessage: "Generating Report. Please wait...",
           id: "reportGenerationWait",
         },
         reportPageTitle: {
           defaultMessage: "Domain Scan Report",
           id: "reportPageTitle",
         },
         backToDashboard: {
           defaultMessage: "Back to dashboard",
           id: "backToDashboard",
         },
       });

export const deleteProfileI18n = defineMessages({
  buttonLabel: {
    defaultMessage: "Delete Profile",
    id: "deleteProfile.buttonLabel",
  },
  title: {
    defaultMessage: "The action cannot be undone!",
    id: "deleteProfile.title",
  },
  cancel: {
    defaultMessage: "Cancel",
    id: "deleteProfile.cancel",
  },
  success: {
    defaultMessage: "Your profile was successfully deleted",
    id: "deleteProfile.success",
  },
});

export const profileDataSchema = yup.object().shape({
  firstName: yup
    .string()
    .ensure()
    .required(required.id),

  lastName: yup
    .string()
    .ensure()
    .required(required.id),

  email: yup
    .string()
    .ensure()
    .email(invalidEmail.id)
    .required(required.id),

  phone: yup.string().required(required.id),

  country: yup.object().required(required.id),
});

const REPORT_LOGO_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const reportPreferencesSchema = yup.object().shape({
  report_title: yup.string().required(required.id).max(80, "Max limit of 80 characters exceeded"),
  report_logo: yup.mixed()
    .nullable()
    .notRequired()
    .test('Formats allowed are jpeg or png', formatsJPGorPNG.id, async (value) => {
      if (value && value !== undefined && value !== null) {
        try {
          const file = await getFile();
          return file.type != null && REPORT_LOGO_SUPPORTED_FORMATS.includes(file.type);
        } catch (error) {
          return false;
        }
      } else {
        return true;
      }
    }
    )
    .test('Maximum filesize allowed is 4 MB', maxFileSize4MB.id, async (value) => {
      if (value && value !== undefined && value !== null) {
        try {
          const file = await getFile();
          return file.size != null && file.size <= 4096 * 1024;
        } catch (error) {
          return false;
        }
      } else {
        return true;
      }
    }
    ),
});
