import { pathOr } from "ramda";
import React from "react";
import { Form } from "react-final-form";
import { FormattedMessage } from "react-intl";
import { form } from "@cyberalarm/common";
import {
  FormButton,
  Card,
  FormInner,
  FormTitle,
  Box,
  theme,
} from "@cyberalarm/ui";

import { i18n, profileDataSchema } from "./schema";
import { useProfileUpdate } from "./hooks";
import { EmailField } from "./email-field";

import { TextField, PhoneField, CountryField } from "ui";
import { useProfileData } from "hooks";
import styled from "styled-components";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const getFirstName = pathOr("", ["firstName"]);
const getLastName = pathOr("", ["lastName"]);
const getCountry = pathOr(null, ["country"]);
const getPhone = pathOr("", ["phone"]);
const getEmail = pathOr("", ["email"]);
const getCompanyName = pathOr("", ["companyName"]);
const getConfirmed = pathOr(false, ["confirmed"]);

export const PersonalDataForm = () => {
  const user = useProfileData();
  const onSubmit = useProfileUpdate();
  const country = getCountry(user);
  const confirmed = getConfirmed(user);
  const email = getEmail(user);

  return (
    <Card
      p={5}
      height="fit-content"
      minHeight="fit-content"
      minWidth={0}
      marginRight={[0, 0, 2]}
      marginBottom={[4, 4, 0]}
      flex={1}
      borderRadius={1}
    >
      <Form
        onSubmit={onSubmit}
        validate={form.validateForm({ schema: profileDataSchema })}
        initialValues={{
          firstName: getFirstName(user),
          lastName: getLastName(user),
          country: country ? { value: country, label: country } : country,
          phone: getPhone(user),
          companyName: getCompanyName(user),
          email,
        }}
      >
        {({ handleSubmit, pristine, submitting }) => (
          <FormInner onSubmit={handleSubmit}>
            <FlexBox>
              <FormTitle>
                <FormattedMessage {...i18n.personalDataFormTitle} />
              </FormTitle>
            </FlexBox>

            <Box flexDirection={["column", "row"]} marginTop={5}>
              <Box marginRight={[0, 2]} width="100%">
                <TextField id="firstName" label={i18n.firstNameLabel} />
              </Box>

              <Box marginLeft={[0, 2]} width="100%">
                <TextField id="lastName" label={i18n.lastNameLabel} />
              </Box>
            </Box>

            <CountryField label={i18n.countryLabel} />
            <EmailField confirmed={confirmed} email={email} />
            <PhoneField label={i18n.phoneLabel} />
            <TextField id="companyName" label={i18n.companyLabel} />

            <Box>
              <FormButton
                variant="primary"
                type="submit"
                disabled={pristine || submitting}
              >
                <FormattedMessage {...i18n.save} />
              </FormButton>
            </Box>
          </FormInner>
        )}
      </Form>
    </Card>
  );
};
