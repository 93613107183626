import { useContext, useEffect, useMemo } from "react";
import normalize from "json-api-normalizer";
import build from "redux-object";
import { api } from "@cyberalarm/common";

import {
  selectors,
  StoreContext,
  domainSelectors,
  DomainStateContext,
} from "../store";

export const useProfileData = () => {
  const { setUser, ...rest } = useContext(StoreContext);
  const user = selectors.getUser(rest);
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await api("profile", {
          method: "GET",
        });
        const normalizedData = normalize(data);
        const builtData = build(normalizedData, "profile");

        if (builtData) {
          const { user, ...profile } = builtData[0];
          const preparedData = {
            ...profile,
            id: user.id,
            email: user.email,
            role: user.role,
            trialMode: user.trialMode,
            confirmed: user.confirmed,
            domainsLimit: user.domainsLimit,
            planPreferences: user.planPreferences,
            teamAttributes: user.teamAttributes,
          };

          setUser(preparedData);
        }
      } catch {}
    };

    if (!user || (user && !user.id)) {
      fetchProfile();
    }
  }, [setUser, user]);

  return useMemo(() => user as User, [user]);
};

export const useDomainIds = () => {
  const state = useContext(DomainStateContext);
  const domains = domainSelectors.getDomainList(state);
  return useMemo(() => domains.result as string[], [domains]);
};

export const useDomainResultsCountTotal = () => {
  const state = useContext(DomainStateContext);
  const domains = domainSelectors.getDomainList(state);
  const total: number[] = [];
  if (domains.entities.domain !== null) {
    Object.keys(domains.entities.domain).forEach((k) =>
      total.push(
        domains.entities.domain![k].meta.failedCount +
          domains.entities.domain![k].meta.warningsCount +
          domains.entities.domain![k].meta.passedCount
      )
    );
  }
  return useMemo(() => total as [], [total]);
};
