import React from "react";
import styled from "styled-components";

import { Text } from "./text";

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #bebebe;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 5px;
      background: #eeeeee;
    }
  }
  ${(props) =>
    props.checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      background: #222450;
      border: 1px solid #222450;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin: 5px;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
        background: white;
      }
    }
  `}
`;

interface InputRadioProps {
  valid: boolean;
  id: string;
  onChange: () => void;
  value: string | number | string[] | undefined;
  label: string;
  name: string;
  skipOptionsFilter: boolean;
  checked: boolean;
}

export const InputRadio = ({
  label,
  checked,
  id,
  ...rest
}: InputRadioProps) => {
  return (
    <Item>
      <RadioButton type="radio" checked={checked} id={id} {...rest} />
      <RadioButtonLabel />
      <Text as="label" fontSize="16px" htmlFor={id}>
        {label}
      </Text>
    </Item>
  );
};
