import {
  ButtonStyleProps,
  buttonStyle,
  space,
  SpaceProps,
  border,
  BorderProps,
  LayoutProps,
  layout,
  typography,
  TypographyProps,
} from "styled-system";
import styled from "styled-components";

import { buttonStyles } from "../theme";

type ButtonProps = SpaceProps &
  ButtonStyleProps &
  BorderProps &
  LayoutProps &
  TypographyProps & {
    href?: string;
  };

export const Button = styled.button<ButtonProps>(
         {
           appearance: "none",
           color: "white",
           borderRadius: 0,
           width: "fit-content",
           fontWeight: 400,
           fontSize: "16px",
           textDecoration: "none",
           cursor: "pointer",
         },
         buttonStyle,
         space,
         border,
         layout,
         typography,
         ...buttonStyles
       );
