import { defineMessages } from "react-intl";
import * as yup from "yup";
import { getFile } from "./hooks"

import { formatsJPGorPNG, invalidEmail, maxFileSize4MB, required } from "shared-schema";

export const i18n = defineMessages({
         settingsPageTitle: {
           defaultMessage: "Team settings",
           id: "team.settingsPageTitle",
         },
         save: {
           defaultMessage: "Save",
           id: "team.save",
         },
         reset: {
           defaultMessage: "Reset",
           id: "team.reset",
         },
         reportPreferencesFormTitle: {
           defaultMessage: "Report preferences",
           id: "team.reportPreferencesFormTitle",
         },
         inviteUserLabel: {
           defaultMessage: "Invite user",
           id: "team.inviteUserLabel",
         },
         inviteEmailLabel: {
           defaultMessage: "Email",
           id: "team.inviteEmailLabel",
         },
         confirmInviteEmailLabel: {
           defaultMessage: "Repeat email",
           id: "team.repeatInviteEmailLabel",
         },
         inviteLabel: {
           defaultMessage: "Invite",
           id: "team.invite",
         },
         reportTitleLabel: {
           defaultMessage: "Title",
           id: "profile.reportTitleLabel",
         },
         reportTitleHelp: {
           defaultMessage: "[Max 80 chars. Use ## as domain placeholder]",
           id: "profile.reportTitleHelp",
         },
         reportLogoLabel: {
           defaultMessage: "Your logo",
           id: "profile.reportLogoLabel",
         },
         reportLogoHelp: {
           defaultMessage: "[Max 4 MB, JPEG or PNG only]",
           id: "profile.reportLogoHelp",
         },
         reportPreferencesToast: {
           defaultMessage: "Report preferences were succcessfully updated",
           id: "profile.reportPreferencesToast",
         },
         reportGenerationWait: {
           defaultMessage: "Generating Report. Please wait...",
           id: "reportGenerationWait",
         },
         reportPageTitle: {
           defaultMessage: "Domain Scan Report",
           id: "reportPageTitle",
         },
         settingsGeneralHelpText: {
           defaultMessage:
             "Use the links below to set your notification and subscription preferences",
           id: "settingsGeneralHelpText",
         },
       });

export const inviteSchema = yup.object().shape({
  email: yup
    .string()
    .ensure()
    .email(invalidEmail.id)
    .required(required.id),

  confirmEmail: yup
    .string()
    .ensure()
    .email(invalidEmail.id)
    .required(required.id),
});

const REPORT_LOGO_SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const reportPreferencesSchema = yup.object().shape({
  report_title: yup.string().when("report_logo", {
    is: null,
    then: yup
      .string()
      .required(required.id)
      .max(80, "Max limit of 80 characters exceeded"),
    otherwise: yup.string(),
  }),
  report_logo: yup
    .mixed()
    .nullable()
    .notRequired()
    .test(
      "Formats allowed are jpeg or png",
      formatsJPGorPNG.id,
      async (value) => {
        if (value && value !== undefined && value !== null) {
          try {
            const file = await getFile();
            return (
              file.type != null &&
              REPORT_LOGO_SUPPORTED_FORMATS.includes(file.type)
            );
          } catch (error) {
            return false;
          }
        } else {
          return true;
        }
      }
    )
    .test(
      "Maximum filesize allowed is 4 MB",
      maxFileSize4MB.id,
      async (value) => {
        if (value && value !== undefined && value !== null) {
          try {
            const file = await getFile();
            return file.size != null && file.size <= 4096 * 1024;
          } catch (error) {
            return false;
          }
        } else {
          return true;
        }
      }
    ),
});
