import React, { createContext, useMemo, useCallback, useState } from "react";
import { Modal, Box, Text, theme } from "@cyberalarm/ui";
import { SubscriptionManagement } from "./subscription-management";
import { BusyModal } from "./busy-modal";
import { useDomainIds } from "hooks";
import { mutate } from "swr";
import qs from "qs";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

const StyledLinkText = styled(Text)`
  text-decoration: none;
  height: 100%;
  width: fit-content;
  cursor: pointer;
  margin-left: 25px;
  color: ${themeGet("colors.black")};
  :hover {
    text-decoration: underline;
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-left: 0px;
  }
`;

type InitialContext = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

interface SubscriptionManagementModalProps {
  domainsCount: number;
}
const initialContext: InitialContext = {
  isOpen: false,
  open: () => null,
  close: () => null,
};

export const subscriptionManagementModalCtx = createContext(initialContext);

export const SubscriptionManagementModal = (
  props: SubscriptionManagementModalProps
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [busyOpen, setBusyOpen] = useState(false);
  const close = useCallback(() => setIsOpen(false), []);
  const open = useCallback(() => setIsOpen(true), []);
  const value = useMemo(() => ({ isOpen, open, close }), [close, open, isOpen]);
  const domainIds = useDomainIds();
  const refreshDomains = () => {
    const mutateKeys: string[] = ["domains"];
    domainIds.forEach((id) => {});
    mutateKeys.push(...domainIds);
    domainIds.forEach((id) =>
      mutateKeys.push(
        `domains/${id}/results/testdetails?${qs.stringify({
          limit: 5000,
          main_ext_statuses: true,
          include_ignored_result_ids: true,
        })}`
      )
    );
    setTimeout(
      () =>
        mutateKeys.forEach((key) => {
          mutate(key, undefined, true);
        }),
      5000
    );
    setTimeout(() => setBusyOpen(false), 5000);
  };

  return (
    <>
      <Box>
        <StyledLinkText onClick={open}>Manage Subscription</StyledLinkText>
      </Box>

      <subscriptionManagementModalCtx.Provider value={value}>
        <Modal isOpen={isOpen} close={close}>
          <SubscriptionManagement
            close={close}
            refreshDomains={refreshDomains}
            setBusyOpen={setBusyOpen}
            domainsCount={props.domainsCount}
          />
        </Modal>
        {busyOpen && (
          <Modal
            isOpen={busyOpen}
            close={() => {}}
            zIndex={1002}
            showCloseIcon={false}
          >
            <BusyModal
              messages={["Processing...", "This might take a few seconds..."]}
            />
          </Modal>
        )}
      </subscriptionManagementModalCtx.Provider>
    </>
  );
};
