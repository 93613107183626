import React, { memo } from "react";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

import { Text, Box, Link } from "../atoms";

const Root = styled(Box)`
  flex-direction: row;
  font-family: ${themeGet("fonts.title")};
  padding: 20px 0px;
  background-color: transparent;
  justify-content: space-between;
`;

const MenuBox = styled(Box)`
  flex-direction: row;
  justify-contents: space-between;
`;

const MenuItem = styled(Box)`
  cursor: pointer;
  flex-direction: row;
  align-items: right;
  justify-content: right;
  margin-left: 25px;
  margin-top: auto;
  color: ${themeGet("colors.black")};
  transition: 0.2s;
  :hover {
    color: ${themeGet("colors.grays.1")};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  height: 100%;
  width: fit-content;
  color: ${themeGet("colors.gray")};
`;

const StyledText = styled(Text)`
  font-size: 28px;
  font-family: ${themeGet("fonts.title")};
  font-weight: 400;
`;

interface TitleProps {
  label: string;
  links?: { label: string; url: string }[];
}

const Title = ({ label, links }: TitleProps) => {
  return (
    <Root>
      <Box justifyContent="space-between">
        <StyledText>{label}</StyledText>
      </Box>
      <MenuBox>
        {links &&
          links.map((l) => (
            <MenuItem key={l.url}>
              <StyledLink to={l.url}>
                <Text fontSize="16px" fontFamily="montserrat">
                  {l.label}
                </Text>
              </StyledLink>
            </MenuItem>
          ))}
      </MenuBox>
    </Root>
  );
};

export const TitleBar = memo(Title);
