import React, { useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { format } from "date-fns";
import themeGet from "@styled-system/theme-get";
import { Box, TruncateText } from "@cyberalarm/ui";
import { StatusColors } from "@cyberalarm/common";

import { GdprToken } from "../token-list/hooks";
import { Dot } from "../status-card/components";

import {
  emailAddressIs,
  userName,
  tokenIssued,
  numberOfHits,
  tokenNameWithValue,
} from "./schema";

import { CheckDetails } from "ui";

type ActiveGdprTokenProps = {
  item: GdprToken;
};

const StyledBox = styled(Box)`
  border-bottom: 1px solid ${themeGet("colors.grays.0")};
  padding-bottom: 8px;
`;

export const ActiveGdprTokenItem: React.FC<ActiveGdprTokenProps> = ({
  item,
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Box
        flexDirection="row"
        alignItems="baseline"
        width="100%"
        onClick={() => setIsOpen(true)}
        style={{ cursor: "pointer" }}
      >
        <Box>
          <Dot bg={StatusColors[item.compromised ? "failed" : "passed"]} />
        </Box>
        <StyledBox width="100%" display="grid" marginY={3}>
          <Box
            display="grid"
            width="100%"
            style={{
              gridTemplateColumns: "repeat(2,1fr)",
              alignContent: "space-between",
              justifyContent: "space-between",
              columnGap: "16px",
            }}
          >
            <TruncateText fontSize="12px" fontWeight={600}>
              {intl.formatMessage(tokenNameWithValue, {
                value: item.tokenName,
              })}
            </TruncateText>
            <TruncateText fontSize="12px" fontWeight={600}>
              {intl.formatMessage(tokenIssued, {
                value: format(new Date(item.createdAt), "PPP"),
              })}
            </TruncateText>
            <TruncateText fontSize="12px" fontWeight={600}>
              {intl.formatMessage(userName, {
                value: item.generatedInfo.fullName,
              })}
            </TruncateText>
            <TruncateText fontSize="12px" fontWeight={600}>
              {intl.formatMessage(numberOfHits, {
                value: item?.checkResults[0]?.variables
                  ? (item?.checkResults[0]?.variables as any)["hits"]
                  : "0",
              })}
            </TruncateText>
            <TruncateText fontSize="12px" fontWeight={600}>
              {intl.formatMessage(emailAddressIs, {
                value: item.generatedInfo.token,
              })}
            </TruncateText>
          </Box>
        </StyledBox>
      </Box>

      {item.compromised ? (
        <CheckDetails
          details={item.checkResults[0]}
          close={() => setIsOpen(false)}
          isOpen={isOpen}
          dontShowManageNotification={true}
        />
      ) : null}
    </>
  );
};
