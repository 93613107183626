import { pathOr } from "ramda";
import React, { memo, useMemo, useEffect, useState } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import styled from "styled-components";
import { Link } from "react-router-dom";
import themeGet from "@styled-system/theme-get";

import { Text, Box } from "../atoms";
import { Role } from "@cyberalarm/common";

const getFirstName = pathOr("", ["firstName"]);
const getLastName = pathOr("", ["lastName"]);

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledLinkText = styled(Text)`
  color: #11876f;
  font-size: 18px;
  font-family: ${themeGet("fonts.raleway")};
  text-decoration: underline;
`;

const InfoBox = styled(Box)`
  background-color: #dae3db;
  border: 1px solid #09402b;
  padding: 4px;
`;

const InfoText = styled(Text)`
  font-size: 11px;
  font-family: ${themeGet("fonts.raleway")};
`;

const Root = styled(Box)`
  flex-direction: row;
  padding: 48px 0;
  background-color: white;
`;

export interface ProfileProps {
  profileUrl: string;
  teamUrl: string;
  user: null | {
    firstName?: string;
    lastName?: string;
    domainsLimit?: number;
    planPreferences?: {
      product: string;
      plan: string;
      domains: number;
    };
    role?: string;
    teamAttributes?: {
      teamId: number;
      role: string;
    };
  };
  profileLabel: MessageDescriptor;
  teamLabel: MessageDescriptor;
  domainsCount: number;
}

const snakeCaseToInitCaps = (str: string | undefined) => {
  if (str === undefined) return "";
  return str
    .split("_")
    .map((e) => e.charAt(0).toUpperCase() + e.substring(1))
    .join(" ");
};

const Profile = ({
  user,
  profileLabel,
  teamLabel,
  domainsCount,
  profileUrl,
  teamUrl,
}: ProfileProps) => {
  const intl = useIntl();
  const firstName = useMemo(() => getFirstName(user), [user]);
  const lastName = useMemo(() => getLastName(user), [user]);
  const [showTeamLink, setShowTeamLink] = useState(false);

  useEffect(() => {
    if (user?.teamAttributes?.role === "admin") {
      setShowTeamLink(true);
    }
  }, [user]);

  return (
    <Root>
      <Box justifyContent="space-between">
        <Text fontSize="20px" fontWeight={600}>
          {firstName} {lastName}
        </Text>

        <Box
          style={{ marginTop: "18px" }}
          flexDirection="row"
          justifyContent="space-between"
        >
          <StyledLink to={profileUrl}>
            <StyledLinkText>{intl.formatMessage(profileLabel)}</StyledLinkText>
          </StyledLink>
          {showTeamLink && (
            <StyledLink to={teamUrl}>
              <StyledLinkText>{intl.formatMessage(teamLabel)}</StyledLinkText>
            </StyledLink>
          )}
        </Box>

        {user?.role === Role.user && (
          <InfoBox marginTop={3} padding={1} flexDirection="row">
            <Box flexDirection="column">
              <InfoText>Plan</InfoText>
              <InfoText>Usage</InfoText>
            </Box>
            <Box flexDirection="column" marginLeft={3}>
              <InfoText>
                : {snakeCaseToInitCaps(user?.planPreferences?.product)}
                {" - "}
                {snakeCaseToInitCaps(user?.planPreferences?.plan)}
              </InfoText>
              <InfoText>
                : {domainsCount} / {user?.domainsLimit}
              </InfoText>
            </Box>
          </InfoBox>
        )}
      </Box>
    </Root>
  );
};

export const ProfileInfo = memo(Profile);
