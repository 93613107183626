import { defineMessages } from "react-intl";

export const i18n = defineMessages({
  tests: {
    defaultMessage: "Tests",
    id: "adminControls.tests",
  },
  users: {
    defaultMessage: "Users",
    id: "adminControls.users",
  },
  domains: {
    defaultMessage: "Domains",
    id: "adminControls.domains",
  },
  clients: {
    defaultMessage: "Clients",
    id: "adminControls.clients",
  },
  managers: {
    defaultMessage: "Managers",
    id: "adminControls.managers",
  },
  active: {
    defaultMessage: "Active",
    id: "adminControls.active",
  },
  deactivated: {
    defaultMessage: "Deactivated",
    id: "adminControls.deactivated",
  },
  errors: {
    defaultMessage: "Errors",
    id: "adminControls.errors",
  },
});
