import React, { Fragment, useContext, useEffect, memo } from "react";
import { FormattedMessage } from "react-intl";
import build from "redux-object";
import { Text, Box } from "@cyberalarm/ui";

import { notFound } from "./schema";
import { ListItem } from "./list-item";

import { domainSelectors, DomainStateContext, StoreContext } from "store";
import { useDomainsList } from "hooks";

interface ListProps {
  setID: (id: string) => void;
  sortOption: string;
  sortOrder: string;
}

export const List = memo(({ setID, sortOption, sortOrder }: ListProps) => {
  const state = useContext(DomainStateContext);
  const { sortPreferences } = useContext(StoreContext);
  const domainListSort = `${sortOption}_${sortOrder}`;
  const domains = domainSelectors.getDomainList(state);
  useDomainsList(`domains?sort=${domainListSort}`);

  useEffect(() => {
    const id = sortPreferences.currentDomainId ?? domains.result[0];
    setID(id);
  }, [setID, domains.result, sortPreferences.currentDomainId]);

  return (
    <Fragment>
      <Box
        marginBottom="32px"
        height="calc(90vh - 48px)"
        overflowX="hidden"
        overflowY="auto"
      >
        {Boolean(domains.result.length) ? (
          domains.result.map((id: string) => {
            const domain: BuiltDomain = build(domains.entities, "domain", id);
            return <ListItem key={id} setID={setID} {...domain} />;
          })
        ) : (
          <Box marginY="50px" alignItems="center">
            <Text fontSize={1} color="grays.2">
              <FormattedMessage {...notFound} />
            </Text>
          </Box>
        )}
      </Box>
    </Fragment>
  );
});
