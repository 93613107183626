import React, { Fragment, useCallback, memo } from "react";
import { FormattedMessage } from "react-intl";
import { Button, FormButton, Box, Modal } from "@cyberalarm/ui";

import { ConfirmationModal } from "./confirmation-modal";
import { BusyModal } from "./busy-modal";
import { ModalTemplate } from "./modal-template";
import {
  deleteButtonLabel,
  deleteTitle,
  ModalFlowEnum,
  addTitle,
  addButtonLabel,
} from "./schema";

import {
  useDomainDelete,
  useDomainUpdate,
  useDomainCreate,
  useDomainRead,
} from "hooks";

interface ModalFlowProps {
  setCurrentModal: (modal: null | ModalFlowEnum) => void;
  currentModal: null | ModalFlowEnum;
  id: string | null;
}

export const ModalFlow = memo(
         ({ currentModal, setCurrentModal, id }: ModalFlowProps) => {
           const domain = useDomainRead({ id });
           const close = useCallback(() => {
             setCurrentModal(null);
           }, [setCurrentModal]);

           const setBusyModal = useCallback(() => {
             setCurrentModal(ModalFlowEnum.BUSY);
           }, [setCurrentModal]);

           const deleteItem = useDomainDelete(close);
           const onAdd = useDomainCreate(close, setBusyModal);

           const onUpdate = useDomainUpdate(close, id);

           const isCreateOpen = currentModal === ModalFlowEnum.CREATE;
           const isConfirmOpen = currentModal === ModalFlowEnum.CONFIRM;
           const isDeleteOpen = currentModal === ModalFlowEnum.DELETE;
           const isBusyOpen = currentModal === ModalFlowEnum.BUSY;

           const onConfirmDelete = useCallback(() => {
             setCurrentModal(ModalFlowEnum.BUSY);
             deleteItem({
               id,
             });
           }, [setCurrentModal, deleteItem, id]);

           const onCancelDelete = useCallback(() => {
             close();
           }, [close]);

           const onDelete = useCallback(() => {
             setCurrentModal(ModalFlowEnum.CONFIRM);
           }, [setCurrentModal]);

           return (
             <Fragment>
               {isCreateOpen && (
                 <ModalTemplate
                   title={addTitle}
                   onSubmit={onAdd}
                   isOpen={isCreateOpen}
                   close={close}
                 >
                   <Box alignItems="flex-end">
                     <FormButton variant="primary" type="submit">
                       <FormattedMessage {...addButtonLabel} />
                     </FormButton>
                   </Box>
                 </ModalTemplate>
               )}
               {isConfirmOpen && (
                 <Modal
                   isOpen={isConfirmOpen}
                   close={onCancelDelete}
                   zIndex={1001}
                   showCloseIcon={false}
                 >
                   <ConfirmationModal
                     handleDelete={onConfirmDelete}
                     handleClose={onCancelDelete}
                   />
                 </Modal>
               )}
               {isDeleteOpen && (
                 <ModalTemplate
                   isOpen={isDeleteOpen}
                   close={close}
                   title={deleteTitle}
                   initialValues={{
                     domain: domain ? domain.name : "",
                     disabled: true,
                   }}
                   onSubmit={onUpdate}
                 >
                   <Box flexDirection="row" justifyContent="right">
                     <Button variant="danger" onClick={onDelete}>
                       <FormattedMessage {...deleteButtonLabel} />
                     </Button>
                   </Box>
                 </ModalTemplate>
               )}
               {isBusyOpen && (
                 <Modal
                   isOpen={isBusyOpen}
                   close={onCancelDelete}
                   zIndex={1002}
                   showCloseIcon={false}
                 >
                   <BusyModal
                     messages={[
                       "Processing...",
                       "This might take a few seconds...",
                     ]}
                   />
                 </Modal>
               )}
             </Fragment>
           );
         }
       );

ModalFlow.displayName = "ModalFlow";
