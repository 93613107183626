import React, { useContext, memo } from "react";
import { Redirect } from "react-router-dom";
import { Role } from "@cyberalarm/common";

import { CommonRoutes, UserRoutes, AdminRoutes } from "./routes";

import { StoreContext, selectors } from "store";

export const RootRoute = memo(() => {
  const state = useContext(StoreContext);
  const userRole = selectors.getUserRole(state);

  switch (userRole) {
    case Role.admin:
      return <Redirect to={AdminRoutes.root} />;

    case Role.user:
      return <Redirect to={UserRoutes.root} />;

    default:
      return <Redirect to={CommonRoutes.signIn} />;
  }
});

RootRoute.displayName = "RootRoute";
