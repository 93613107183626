import React, { memo } from "react";
import styled from "styled-components";
import { AuthTemplate as Template, Box } from "@cyberalarm/ui";

import { SideBar, AdminControls } from "../organisms";

import { AdminRoutes } from "routes";

const BodyRoot = styled(Box)`
  height: 100vh;
`;

interface AuthTemplateProps {
  children: React.ReactNode;
}

const BaseComponent = ({ children }: AuthTemplateProps) => {
  return (
    <Template
      sidebar={
        <SideBar profileUrl={AdminRoutes.profile} teamUrl={AdminRoutes.team}>
          <AdminControls />
        </SideBar>
      }
    >
      <BodyRoot p={5}>{children}</BodyRoot>
    </Template>
  );
};

export const AdminPageTemplate = memo(BaseComponent);
