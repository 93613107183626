import styled from "styled-components";
import {
  SpaceProps,
  space,
  LayoutProps,
  layout,
  flexbox,
  background,
  BackgroundProps,
  FlexboxProps,
  overflow,
  OverflowProps,
  BorderRadiusProps,
  borderRadius,
  color,
  ColorProps,
  BorderProps,
  border,
} from "styled-system";

export type BoxProps = SpaceProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  OverflowProps &
  BorderRadiusProps &
  ColorProps &
  BorderProps;

export const Box = styled.div<BoxProps>(
  {
    boxSizing: "border-box",
    minWidth: 0,
    display: "flex",
    flexDirection: "column",
  },
  space,
  layout,
  flexbox,
  background,
  overflow,
  borderRadius,
  color,
  border,
);
