import React, { memo } from "react";
import styled from "styled-components";

import { Box, Grid } from "../atoms";
import { theme } from "../theme";
import themeGet from "@styled-system/theme-get";

const SidebarRoot = styled(Box)`
  background-color: ${themeGet("colors.backgrounds.1")};
  z-index: 1;
  grid-area: nav;
  height: 100%;

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    height: fit-content;
  }
`;

const ContentRoot = styled(Box)`
  overflow: auto;
  grid-area: main;
  height: 100%;
`;

interface AuthTemplateProps {
  children: React.ReactNode;
  sidebar?: React.ReactNode;
  background?: string;
}

export const Template = ({
  children,
  sidebar,
  background,
}: AuthTemplateProps) => {
  return (
    <Grid
      gridTemplateAreas={["'main'", "'nav main'"]}
      gridTemplateColumns={{ _: "1fr", sm: "100px 1fr", xl: "250px 1fr" }}
      gridTemplateRows="90vh"
      gridGap={0}
    >
      <SidebarRoot background="#ECF0EC">{sidebar}</SidebarRoot>
      <ContentRoot background={background}>{children}</ContentRoot>
    </Grid>
  );
};

export const AuthTemplate = memo(Template);
