import React, { SVGProps } from "react";

export const ArrowUp = ({
  stroke = "#000000",
  width = 64,
  height = 64,
}: SVGProps<{}>) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M12 20L12 4M12 4L18 10M12 4L6 10"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>{" "}
    </g>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default ArrowUp;
