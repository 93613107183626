import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Card, Text, Box } from "@cyberalarm/ui";

import { yesLabel, noLabel } from "./schema";

interface ConfirmationModalProps {
  handleYes: () => void;
  handleNo: () => void;
  messages?: string[];
}

export const ConfirmationModal = ({
  handleYes,
  handleNo,
  messages,
}: ConfirmationModalProps) => {
  return (
    <Card p={5} minHeight="50px">
      {messages !== undefined &&
        messages.map((message, index) => (
          <Text key={index} marginBottom={6}>
            {message}
          </Text>
        ))}
      <Box marginTop={5} flexDirection="row" justifyContent="space-between">
        <Button variant="primary" onClick={handleYes}>
          <FormattedMessage {...yesLabel} />
        </Button>
        <Button variant="danger" onClick={handleNo}>
          <FormattedMessage {...noLabel} />
        </Button>
      </Box>
    </Card>
  );
};
