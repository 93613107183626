import React, { memo, useContext } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import themeGet from "@styled-system/theme-get";
import { Text, Box } from "@cyberalarm/ui";

import { i18n } from "./schema";

import { useLogout } from "hooks";
import { ResponsiveContext } from "store";

const ItemRoot = styled(Box)`
  cursor: pointer;
  flex-direction: row;
  align-items: right;
  justify-content: right;
  color: ${themeGet("colors.black")};
  :hover {
    text-decoration: underline;
  }
`;

const BaseControl = () => {
  const logout = useLogout();
  const { isDesktop } = useContext(ResponsiveContext);
  return (
    <Box alignItems={{ _: "center", xl: "flex-end" }} marginTop={[0, "25px"]}>
      <ItemRoot onClick={logout}>
        {isDesktop && (
          <Text fontSize="16px" fontWeight={400}>
            <FormattedMessage {...i18n.logoutLabel} />
          </Text>
        )}
      </ItemRoot>
    </Box>
  );
};

export const Control = memo(BaseControl);
