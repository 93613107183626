import React, { FC, useState } from "react";
import { StatusColors, Icons } from "@cyberalarm/common";
import { Box, TruncateText, Icon, theme } from "@cyberalarm/ui";

import { GdprToken } from "./hooks";

import { CheckDetails } from "ui";
import styled from "styled-components";

type TokenItemProps = {
  token: GdprToken;
  deleteToken: (token: GdprToken) => void;
};

const textColor = "grays.5";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0px 8px, @media screen and (max-width: ${theme.breakpoints.md}) {

  }
`;

export const TokenItem: FC<TokenItemProps> = ({ token, deleteToken }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const currentTextColor = token.compromised ? StatusColors.failed : textColor;
  return (
    <FlexBox marginX="10px">
      <Box
        key={token.id}
        display="grid"
        style={{
          gridGap: 0,
          width: "98%",
          gridTemplateColumns: "20% 15% 65%",
          cursor: token.compromised ? "pointer" : "auto",
        }}
        onClick={() => setIsOpen(true)}
      >
        <TruncateText
          marginY="8px"
          color={currentTextColor}
          paddingRight="4px"
          fontSize="12px"
          fontWeight={400}
        >
          {token.tokenName}
        </TruncateText>
        <TruncateText
          marginY="8px"
          paddingRight="4px"
          color={token.compromised ? StatusColors.failed : textColor}
          fontSize="12px"
          fontWeight={400}
        >
          {token.generatedInfo.fullName}
        </TruncateText>

        <TruncateText
          marginY="8px"
          color={currentTextColor}
          paddingRight="4px"
          fontSize="12px"
          fontWeight={400}
        >
          {token.generatedInfo.token}
        </TruncateText>
      </Box>
      {token.compromised ? (
        <CheckDetails
          details={token.checkResults[0]}
          close={() => {
            setIsOpen(false);
          }}
          isOpen={isOpen}
          dontShowManageNotification={true}
        />
      ) : null}
      <Box
        paddingX="4px"
        margin="auto"
        style={{ cursor: "pointer" }}
        onClick={() => deleteToken(token)}
      >
        <Icon name={Icons.delete} width={10} height={10} fill="#E25A5A" />
      </Box>
    </FlexBox>
  );
};
