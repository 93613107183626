import React from "react";
import { Field } from "react-final-form";
import { MessageDescriptor, useIntl } from "react-intl";
import {
  FormGroup,
  Label,
  AdaptedTextarea,
  FormControlFeedback,
} from "@cyberalarm/ui";
import { getValueOrNull } from "@cyberalarm/common";

interface TextareaFieldProps {
  id: string;
  label: MessageDescriptor;
  margin?: string;
  disabled?: boolean;
  labelColor?: string;
}

export const identity = (value: any) => {
  return value ? value : null;
};

export const TextareaField = ({
  label,
  id,
  margin,
  disabled,
  labelColor,
}: TextareaFieldProps) => {
  const intl = useIntl();
  return (
    <FormGroup margin={margin}>
      <Label htmlFor={id} color={labelColor}>
        {intl.formatMessage(label)}
      </Label>
      <Field
        id={id}
        name={id}
        component={AdaptedTextarea}
        disabled={disabled}
        // use because empty values dropped from state https://github.com/final-form/react-final-form/issues/130
        parse={getValueOrNull}
      />
      <FormControlFeedback name={id} />
    </FormGroup>
  );
};
