import { useCallback, useContext } from "react";
import normalizer from "json-api-normalizer";
import build from "redux-object";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { api } from "@cyberalarm/common";

import { i18n } from "./schema";

import { accessDenied, errors } from "shared-schema";
import { useProfileData } from "hooks";
import { StoreContext } from "store";

type Result = number | string | [] | {};
interface Options {
  [key: string]: Result;
}

export const useProfileUpdate = () => {
  const intl = useIntl();
  const { setUser } = useContext(StoreContext);
  const user = useProfileData();

  return useCallback(
    async ({ country, ...rest }) => {
      try {
        const data = await api("profile", {
          method: "PUT",
          body: {
            ...rest,
            country: country.value,
          },
        });
        const normalizedData = normalizer(data);
        const builtData = user && build(normalizedData, "user", user.id);

        if (builtData) {
          const {
            role,
            status,
            email,
            id,
            profile,
            domainsLimit,
            trialMode,
          } = builtData;
          const { firstName, lastName, companyName, phone, country } = profile;
          setUser({
            role,
            status,
            email,
            id,
            firstName,
            lastName,
            companyName,
            phone,
            country,
            domainsLimit,
            trialMode,
          });
          toast.success(intl.formatMessage(i18n.profileUpdateSuccess));
        } else {
          toast.error(intl.formatMessage(errors.commonError));

          return {
            firstName: accessDenied.id,
            lastName: accessDenied.id,
            country: accessDenied.id,
            phone: accessDenied.id,
          };
        }
      } catch {}
    },
    [intl, setUser, user]
  );
};

export const useEmailConfirmation = ({ email }: { email: string }) => {
  const intl = useIntl();

  return useCallback(async () => {
    try {
      await api("users/confirmation", {
        method: "POST",
        body: { user: { email } },
      });
      toast.success(intl.formatMessage(i18n.mailVerificationToast));
    } catch (error) {
      toast.error(intl.formatMessage(errors.commonError));
    }
  }, [email, intl]);
};

export const redirectToManageSubscriptions = async () => {
  // const data = await api("stripe/customer_portal");
  // window.location.href = data.data.attributes.session_url.url;
};

interface FileProps {
  content: string | ArrayBuffer | null;
  type: string | null;
  size: number | null;
  elem: HTMLInputElement | null;
}
export const getFile = async () =>
  new Promise<FileProps>((resolve, reject) => {
    const file: HTMLInputElement | null = document.querySelector(
      'input[name="report_logo"]'
    );
    const reader = new FileReader();
    if (file && file.files) {
      const f = file.files[0];
      if (f !== undefined) {
        reader.readAsDataURL(f);
        reader.onload = () => {
          resolve({
            content: reader.result,
            type: f.type,
            size: f.size,
            elem: file,
          });
        };
      } else {
        resolve({ content: null, type: null, size: null, elem: file });
      }
    }
    reader.onerror = (error) => reject(error);
  });

export const useReportPreferencesUpdate = () => {
  const intl = useIntl();
  return useCallback(
    async ({ ...rest }) => {
      try {
        const params = { ...rest };
        const logoImageData = await getFile();
        const { errors } = await api("profile/reportpreferences", {
          method: "PATCH",
          body: { ...params, report_logo: logoImageData.content },
        });
        if (errors) {
          throw errors[0].status;
        }
        toast.success(intl.formatMessage(i18n.reportPreferencesToast));
        setTimeout(() => window.location.reload(), 1000);
      } catch (error) {
        toast.error(intl.formatMessage(errors.commonError));
      }
    },
    [intl]
  );
};
