import React, { SVGProps } from "react";

const Hamburger = ({
  width = 64,
  height = 64,
  fill = "none",
  stroke = "currentColor",
}: SVGProps<{}>) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 64 64"
  >
    <line
      fill={fill}
      stroke={stroke}
      strokeWidth="7"
      strokeMiterlimit="10"
      x1="0"
      y1="7"
      x2="64"
      y2="7"
    />
    <line
      fill={fill}
      stroke={stroke}
      strokeWidth="7"
      strokeMiterlimit="10"
      x1="0"
      y1="33"
      x2="64"
      y2="33"
    />
    <line
      fill={fill}
      stroke={stroke}
      strokeWidth="7"
      strokeMiterlimit="10"
      x1="0"
      y1="57"
      x2="64"
      y2="57"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default Hamburger;
