import { pathOr } from "ramda";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import { api, normalize } from "@cyberalarm/common";
import build from "redux-object";
import normalizer from "json-api-normalizer";
import useSWR, { trigger, mutate } from "swr";

import { accessDenied, invalidDomain } from "shared-schema";
import {
  domainActions,
  DomainDispatchContext,
  domainSelectors,
  StoreContext,
  DomainStateContext,
} from "store";
import { DomainList, DomainEntity } from "store/domain/actions";
import { getUserDomainDashboardUrl } from "routes";
import { toast } from "react-toastify";

type noop = () => void;

const getErrorCode = pathOr(accessDenied.id, ["0", "code"]);

export const useDomainsList = (url?: string) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useContext(DomainDispatchContext);
  const isProfilePage = /profile/.test(location.pathname);
  const isTeamPage = /team/.test(location.pathname);
  const isSupportPage = /support/.test(location.pathname);
  const hasDomainID = /\/user\/domain\/\d+/.test(location.pathname);
  const { data } = useSWR(url ?? "domains", api);

  useEffect(() => {
    if (data) {
      const normalizedData: DomainList = normalize<DomainEntity>(data);
      const firstResult = normalizedData.result[0];
      dispatch(domainActions.domainsListSuccess(normalizedData));

      if (!isProfilePage && !isTeamPage && !isSupportPage && !hasDomainID) {
        history.push(getUserDomainDashboardUrl(firstResult));
      }
    }
  }, [
    data,
    dispatch,
    hasDomainID,
    history,
    isProfilePage,
    isTeamPage,
    isSupportPage,
  ]);
};

export const useDomainCreate = (close: noop, setBusyModal: noop) => {
  const { sortPreferences } = useContext(StoreContext);
  const domainListSort = `${sortPreferences.sortOption}_${sortPreferences.sortOrder}`;
  return useCallback(
    async ({ domain: domainName }: { [key: string]: string }) => {
      if (!domainName) {
        return {
          domain: invalidDomain.id,
        };
      }
      // eslint-disable-next-line
      const domainRegex = /(?:https?:\/\/)?(?:www\.)?([a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*\.[a-z]{2,})(?::\d{1,5})?(?:\/[^?\s]*)?(?:\?[^\s]*)?$/i;
      const domainMatchArray = domainName.trim().match(domainRegex);
      if (!domainMatchArray) {
        return {
          domain: invalidDomain.id,
        };
      } else {
        const name = domainMatchArray[1];
        setBusyModal();
        try {
          const data = await api("domains", {
            method: "POST",
            body: {
              domain: {
                name,
              },
            },
          });
          const normalizedData = normalizer(data);
          if (!data.errors && normalizedData) {
            trigger(`domains?sort=${domainListSort}`, true);
            return close();
          }
          if (data.errors) {
            return {
              domain: getErrorCode(data.errors[0].code),
            };
          }
        } catch (error) {
          if (error.errors[0].status === 403) {
            toast.error(
              `You are limited to ${error.errors[0].limit} domain(s). Please update your subscription to increase the limit`
            );
          } else {
            toast.error(`Invalid request - ${error.errors[0].detail}`);
          }
          close();
        }
      }
    },
    [close, setBusyModal, domainListSort]
  );
};

export const useDomainUpdate = (close: noop, id: string | null) => {
  const dispatch = useContext(DomainDispatchContext);
  const history = useHistory();

  return useCallback(
    async ({ domain: name }: { [key: string]: string }) => {
      const data = await api(`domains/${id}`, {
        method: "PUT",
        body: {
          domain: { name },
        },
      });
      const normalizedData = normalizer(data);

      if (!data.errors && normalizedData) {
        dispatch(
          domainActions.domainUpdateSuccess(
            normalizedData as domainActions.DomainEntity
          )
        );
        history.push(
          `/user/domain/${
            Object.keys((normalizedData as any).domain)[0]
          }/dashboard?status=failed`
        );

        mutate("domains");
        return close();
      }

      if (data.errors) {
        return { domain: getErrorCode(data.errors) };
      }
    },
    [id, dispatch, close, history]
  );
};

export const useDomainDelete = (close: noop) => {
  const dispatch = useContext(DomainDispatchContext);
  const { sortPreferences } = useContext(StoreContext);
  const domainListSort = `${sortPreferences.sortOption}_${sortPreferences.sortOrder}`;
  const history = useHistory();

  return useCallback(
    async ({ id }: { id: string | null }) => {
      const { errors } = await api(`domains/${id}`, {
        method: "DELETE",
      });

      if (errors) {
        return { domain: getErrorCode(errors) };
      } else {
        dispatch(domainActions.domainDeleteSuccess(id as string));

        trigger(`domains?sort=${domainListSort}`, true)
          .then((data) => {
            const normalizedData: DomainList = normalize<DomainEntity>(data);
            const firstResult = normalizedData.result[0];
            dispatch(domainActions.domainsListSuccess(normalizedData));
            history.push(getUserDomainDashboardUrl(firstResult));
          })
          .finally(() => {
            return close();
          });
      }
    },
    [close, dispatch, history, domainListSort]
  );
};

export const useDomainRead = ({ id }: { id: string | null }) => {
  const state = useContext(DomainStateContext);
  const activeDomain = domainSelectors.getActiveDomain(state, {
    activeDomainID: id,
  });

  const memoizedDomain = useMemo(
    () => activeDomain && id && build({ domain: activeDomain }, "domain", id),
    [activeDomain, id],
  );

  return memoizedDomain;
};
