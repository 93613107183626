import React, { createContext, useMemo, useCallback } from "react";
import themeGet from "@styled-system/theme-get";
import { Modal, Box, Text, buttonStyles, Circle, Icon } from "@cyberalarm/ui";
import styled from "styled-components";
import { Icons } from "@cyberalarm/common";

import { TokenManagement } from "./token-management";

type InitialContext = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

const initialContext: InitialContext = {
  isOpen: false,
  open: () => null,
  close: () => null,
};

const Manage = styled(Box)`
  cursor: pointer;
  ${Text} {
    font-size: 18px;
    color: ${themeGet("colors.black")};

    :hover {
      text-decoration: underline;
    }
  }
`;

export const AddCircle = styled(Circle)`
  cursor: pointer;
  background-color: ${themeGet("colors.black")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: white;
  ${buttonStyles}
`;

export const tokenManagementModalCtx = createContext(initialContext);

type TokenManagementModalProps = {
  label: string;
};

export const TokenManagementModal: React.FC<TokenManagementModalProps> = ({
  label,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const close = useCallback(() => setIsOpen(false), []);
  const open = useCallback(() => setIsOpen(true), []);
  const value = useMemo(() => ({ isOpen, open, close }), [close, open, isOpen]);

  return (
    <>
      <Manage>
        <AddCircle onClick={open}>
          <Icon name={Icons.plus} width={15} height={15} />
        </AddCircle>
      </Manage>
      <tokenManagementModalCtx.Provider value={value}>
        <Modal isOpen={isOpen} close={close} height="auto">
          <TokenManagement />
        </Modal>
      </tokenManagementModalCtx.Provider>
    </>
  );
};
