import styled from "styled-components";

import { Box } from "./box";

export const FormGroup = styled(Box)`
  margin-bottom: 4px;
  position: relative;
  width: 100%;
  flex-direction: column;
`;
