import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Card, Text, Box } from "@cyberalarm/ui";

import { yesLabel, noLabel, areYouSure } from "./schema";

interface ConfirmationModalProps {
  handleClose: () => void;
  handleDelete: () => void;
}

export const ConfirmationModal = ({
  handleClose,
  handleDelete,
}: ConfirmationModalProps) => {
  return (
    <Card p={5} minHeight="50px">
      <Text fontWeight={600} fontSize={1}>
        <FormattedMessage {...areYouSure} />
      </Text>
      <Box marginTop={5} flexDirection="row" justifyContent="space-between">
        <Button variant="primary" onClick={handleDelete}>
          <FormattedMessage {...yesLabel} />
        </Button>
        <Button variant="danger" onClick={handleClose}>
          <FormattedMessage {...noLabel} />
        </Button>
      </Box>
    </Card>
  );
};
