import React, { useCallback } from "react";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { Circle, Text, Box as BoxUI } from "@cyberalarm/ui";
import { StatusColors } from "@cyberalarm/common";
import { transparentize } from "polished";
import { compose } from "ramda";

import { useMappedTranslation } from "utils/use-mapped-translation";

const hoverColor = compose(transparentize(0.6), themeGet("colors.grays.0"));

const Box = styled(BoxUI)`
  min-height: fit-content;
`;

const MoreInfoRoot = styled(Box)`
  cursor: pointer;

  color: ${themeGet("colors.logo")};

  :hover {
    color: ${themeGet("colors.blue")};
  }

  transition: 0.2s all;
`;

const Root = styled(Box)`
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  cursor: pointer;

  padding: 24px;
  border-bottom: 1px solid ${themeGet("colors.grays.0")};

  transition: background-color 0.2s;

  :hover {
    background: ${hoverColor};
  }
`;

const Dot = styled(Circle)`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-right: 16px;
`;

const StyledInfoText = styled(Text)`
  font-size: 16px;
  color: ${themeGet("colors.black")};
  :hover {
    text-decoration: underline;
  }
`;

const StyledPathText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: ${themeGet("grays.0")};
`;

type ResultItemProps = CheckResult & { openDetails: (id: string) => void };

export const ResultItem = ({
  id,
  openDetails,
  status,
  check,
  read,
  checkDescription,
  domainName,
  variables,
}: ResultItemProps) => {
  const handleOpenDetails = useCallback(() => {
    openDetails(id);
  }, [id, openDetails]);
  const title = useMappedTranslation(checkDescription?.title, variables);
  return (
    <Root onClick={handleOpenDetails}>
      <Box flexDirection="row" alignItems="center" flex={1}>
        <Dot bg={StatusColors[status as keyof typeof StatusColors]} />
        <MoreInfoRoot>
          <StyledInfoText fontWeight={read ? 400 : 500}>{title}</StyledInfoText>
          <StyledPathText>
            {check.template.checkSubgroup?.checkGroup?.title}
            {" / "}
            {check.template.checkSubgroup?.title}
            {" / "}
            {domainName}
          </StyledPathText>
        </MoreInfoRoot>
      </Box>
    </Root>
  );
};
