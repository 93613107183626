import React from "react";
import { Card, Text, Spinner } from "@cyberalarm/ui";

interface BusyModalProps {
  messages?: string[];
}

export const BusyModal = ({ messages }: BusyModalProps) => {
  return (
    <Card p={5} minHeight="50px">
      {messages !== undefined &&
        messages.map((message, index) => (
          <Text key={index} marginBottom={6}>
            {message}
          </Text>
        ))}
      <Spinner width="4rem" height="4rem" margin="auto" />
    </Card>
  );
};
