import React from "react";
import { Field } from "react-final-form";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import styled from "styled-components";
import { useCountries } from "@cyberalarm/common";
import {
  AdaptedSelect,
  FormControlFeedback,
  FormGroup,
  Label,
} from "@cyberalarm/ui";

const SelectGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
`;

export const CountryField = ({ label }: { label: MessageDescriptor }) => {
  const countries = useCountries();

  return (
    <SelectGroup>
      <Label htmlFor="country">
        <FormattedMessage {...label} />
      </Label>
      <Field
        name="country"
        id="country"
        component={(props: any) => {
          const { input } = props;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { onFocus: _, ...rest } = input;
          return (
            <AdaptedSelect
              {...props}
              options={countries}
              skipOptionsFilter={false}
              input={{
                ...rest,
                ...rest.value,
                onChange: (option: { value: string; label: string }) =>
                  props.input.onChange(option),
              }}
            />
          );
        }}
      />
      <FormControlFeedback name="country" />
    </SelectGroup>
  );
};
