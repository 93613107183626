import { Button } from "./button";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

export const FormButton = styled(Button)`
         :hover:not(:disabled) {
           background-color: ${themeGet("colors.gray")};
         }
         :disabled {
           color: ${themeGet("colors.grays.2")};
         }
       `;
