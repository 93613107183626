import React, { useMemo } from "react";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import Downshift from "downshift";

import { Input } from ".";

const Root = styled.div`
  width: 100%;
  > input {
    width: 100%;
  }

  position: relative;
`;

const Menu = styled.ul`
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  top: 56px;
  left: 0;
  box-shadow: 0 7px 10px -4px black;
  background: white;
  z-index: 2;
`;

const Item = styled.li<{ bg: string }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0 16px;

  list-style-type: none;
  font-size: ${themeGet("fontSizes.3")}px;
  font-family: ${themeGet("fonts.montserrat")};
  color: ${themeGet("colors.gray")};
  height: 56px;
  background-color: ${(p) => themeGet(p.bg)(p)};

  :hover {
    color: ${themeGet("colors.grays.3")};
  }
`;

const getFilteredItem = (
  skipOptionsFilter: boolean,
  inputValue: string | null,
) => (item: Value) => {
  if (skipOptionsFilter || !inputValue) {
    return true;
  }

  return item.label.toLowerCase().includes(inputValue.toLowerCase());
};

const itemToString = (item: { label: string | null } | null) =>
  !item || item.label == null ? "" : String(item.label);

interface Value {
  value: string | null;
  label: string;
}

interface SelectProps {
  valid: boolean;
  id: string;
  onChange: (value: Value | null) => void;
  options: Value[];
  value: string | null;
  label: string;
  skipOptionsFilter: boolean;
}

export const Select = ({
  valid,
  onChange,
  id,
  options,
  value,
  skipOptionsFilter = true,
  label,
}: SelectProps) => {
  const selectedItem = useMemo(
    () => options.find((i) => i.value === value) || null,
    [options, value],
  );

  return (
    <Downshift
      onChange={onChange}
      itemToString={itemToString}
      selectedItem={selectedItem}
    >
      {({
        getInputProps,
        getRootProps,
        getMenuProps,
        isOpen,
        getItemProps,
        openMenu,
        inputValue,
      }) => {
        return (
          // eslint-disable-next-line unicorn/prevent-abbreviations
          <Root {...getRootProps({ refKey: "ref" })}>
            <Input
              {...getInputProps({
                onFocus: openMenu,
                defaultInputValue: label,
              } as any)}
              id={id}
              valid={valid}
              autoComplete="off"
            />

            {isOpen ? (
              <Menu {...getMenuProps()}>
                {options
                  .filter(getFilteredItem(skipOptionsFilter, inputValue))
                  .map((item, index) => {
                    const isOdd = index % 2 === 0;
                    const bg = isOdd ? "colors.white" : "colors.grays.0";
                    const key = item.value || item.label;

                    return (
                      <Item
                        key={key}
                        {...getItemProps({ key, index, item })}
                        bg={bg}
                      >
                        {item.label}
                      </Item>
                    );
                  })}
              </Menu>
            ) : null}
          </Root>
        );
      }}
    </Downshift>
  );
};
