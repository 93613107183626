import { defineMessages } from "react-intl";

export const i18n = defineMessages({
  loadMore: {
    defaultMessage: "Load more results",
    id: "dashboardActions.loadMore",
  },
  resultsDescription: {
    defaultMessage:
      "We’ve run {passedCount, plural, =0 {# tests} one {# test} other {# tests}}, the last one was {lastScanAt} and all of them are currently secure.",
    id: "dashboardActions.resultsDescription",
  },
  passedCount: {
    defaultMessage:
      "We’ve run all our tests {passedCount} came back as secure.",
    id: "dashboardActions.passedCount",
  },
  failedCount: {
    defaultMessage:
      "You have {failedCount, plural, =0 {no alarms} one {# alarm} other {# alarms}} which require action.",
    id: "dashboardActions.failedCount",
  },
  warningsCount: {
    defaultMessage:
      "{warningsCount, plural, =0 {No warnings} one {# warning} other {# warnings}} which require action.",
    id: "dashboardActions.warningsCount",
  },
});
