import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Circle, Text, Box } from "@cyberalarm/ui";

import { i18n } from "./schema";

const ItemRoot = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Dot = styled(Circle)`
  width: 13px;
  height: 13px;
  min-width: 13px;
  margin-right: 14px;
`;

export interface InfoProps {
  failedCount: number;
  passedCount: number;
  warningsCount: number;
}

export const Info = ({
  passedCount,
  failedCount,
  warningsCount,
}: InfoProps) => (
  <Box flex={1} justifyContent="space-evenly" p={5} maxHeight="250px">
    <ItemRoot>
      <Dot bg="statusOk" />
      <Text fontSize="15px" color="gray">
        <FormattedMessage {...i18n.passedCount} values={{ passedCount }} />
      </Text>
    </ItemRoot>

    <ItemRoot>
      <Dot bg="statusError" />
      <Text fontSize="15px" color="gray">
        <FormattedMessage {...i18n.failedCount} values={{ failedCount }} />
      </Text>
    </ItemRoot>

    <ItemRoot>
      <Dot bg="statusWarning" />
      <Text fontSize="15px" color="gray">
        <FormattedMessage {...i18n.warningsCount} values={{ warningsCount }} />
      </Text>
    </ItemRoot>
  </Box>
);
