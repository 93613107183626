import React from "react";
import { Text, Box, Spinner } from "@cyberalarm/ui";
import { StatusColors, Status } from "@cyberalarm/common";

import { Dot, ItemRoot, StyledLink } from "./components";

import { getDifferenceInMinutes } from "utils/get-difference-in-minutes";

interface ItemProps {
  count: number;
  isNew: boolean;
  status: Status;
  basePath: string;
}

const Item = ({ count, isNew, status, basePath }: ItemProps) => {
  return (
    <ItemRoot>
      <StyledLink to={{ pathname: basePath, search: `?status=${status}` }}>
        <Box flexDirection="row" alignItems="center" width="48px">
          <Dot bg={StatusColors[status]} />
          {isNew ? (
            <Spinner width={2} height={2} />
          ) : (
            <Text fontWeight={400} fontSize="18px">
              {count}
            </Text>
          )}
        </Box>
      </StyledLink>
    </ItemRoot>
  );
};

interface ItemStatusProps {
  failedCount: number;
  warningsCount: number;
  passedCount: number;
  createdAt: string;
  basePath: string;
}

const twoMinutes = 2;

export const ItemStatus = ({
  failedCount,
  warningsCount,
  passedCount,
  createdAt,
  basePath,
}: ItemStatusProps) => {
  const hasStatus =
    Boolean(passedCount) && Boolean(failedCount) && Boolean(warningsCount);

  const isCreatedRecently = getDifferenceInMinutes(createdAt) < twoMinutes;

  const isNewDomain = isCreatedRecently && !hasStatus;
  return (
    <Box flexDirection="row" alignItems="center" width="40%">
      <Item
        status={Status.failed}
        isNew={isNewDomain}
        count={failedCount}
        basePath={basePath}
      />
      <Item
        status={Status.warnings}
        isNew={isNewDomain}
        count={warningsCount}
        basePath={basePath}
      />
      <Item
        status={Status.passed}
        isNew={isNewDomain}
        count={passedCount}
        basePath={basePath}
      />
    </Box>
  );
};
