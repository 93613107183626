import { useIntl, defineMessages } from "react-intl";
import { useMemo } from "react";

const { defaultError } = defineMessages({
  defaultError: {
    defaultMessage: "Was not provided",
    id: "defaultError.wasNotProvided",
  },
});

export const useMappedTranslation = (value?: string, variables: {} = {}) => {
  const intl = useIntl();

  return useMemo(
    () =>
      intl.formatMessage(
        {
          id: value ?? defaultError.id,
          defaultMessage: value ?? defaultError.defaultMessage,
        },
        variables,
      ),
    [intl, value, variables],
  );
};
