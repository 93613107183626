import React, { Fragment } from "react";
import styled from "styled-components";
import { useIntl, FormattedRelativeTime } from "react-intl";
import themeGet from "@styled-system/theme-get";
import { Icons, getRelativeDateTime } from "@cyberalarm/common";
import { Icon, Circle, Text, Box } from "@cyberalarm/ui";

import { i18n } from "./schema";

const StyledCircle = styled(Circle)`
  border: 10px solid ${themeGet("colors.statusOk")};
  width: 127px;
  height: 127px;
  min-height: 127px;
  min-width: 127px;
  color: ${themeGet("colors.statusOk")};
`;

const Root = styled(Box)`
  justify-content: center;
  align-items: center;
  padding: 32px;
  border-bottom: 1px solid ${themeGet("colors.grays.0")};
  min-height: initial;
`;

export const Placeholder = ({
  lastScanAt,
  passedCount,
}: {
  lastScanAt: string | null;
  passedCount: number;
}) => {
  const intl = useIntl();
  return (
    <Fragment>
      <Root>
        <StyledCircle>
          <Icon name={Icons.check} width={60} height={58} />
        </StyledCircle>
      </Root>
      <Box justifyContent="center" paddingY={5} paddingX={7}>
        <Text fontSize="15px" color="gray" fontWeight={600} textAlign="center">
          {intl.formatMessage(i18n.resultsDescription, {
            passedCount,
            lastScanAt: lastScanAt && (
              <FormattedRelativeTime
                key={lastScanAt}
                {...getRelativeDateTime(lastScanAt)}
              />
            ),
          })}
        </Text>
      </Box>
    </Fragment>
  );
};
