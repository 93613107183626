import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

import { Box } from "./box";

export const Circle = styled(Box)`
  font-family: ${themeGet("fonts.montserrat")};
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;
