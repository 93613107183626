import React, { useState } from "react";
import { Card, Box, Text, Label, Button } from "@cyberalarm/ui";
import { Form, Field } from "react-final-form";

import { OverlimitedDomain, useRemoveOverlimitedDomains } from "./hooks";

interface RemoveDomainsProps {
  overlimitedDomains: OverlimitedDomain[];
  close: () => void;
  domainLimitAmount: number;
}

export const RemoveDomains = ({
  overlimitedDomains,
  close,
  domainLimitAmount,
}: RemoveDomainsProps) => {
  const [hideRemoveDomainslist, setHideRemoveDomainslist] = useState(false);
  const [showError, setShowError] = useState(false);
  const onSubmit = (values: any) => {
    if (domainLimitAmount < values.domains.length) {
      setShowError(true);
      return;
    } else {
      const filtered = overlimitedDomains.filter((domain) => {
        return !values.domains.includes(domain.id);
      });
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useRemoveOverlimitedDomains(
        filtered.map((domain) => domain.id),
        close,
        setHideRemoveDomainslist,
      );
    }
  };

  return (
    <>
      {overlimitedDomains && !hideRemoveDomainslist && (
        <>
          <Card p={4} overflow="auto" width="100%" display="block">
            <Box>
              <Box alignItems="center">
                <Text fontSize="25px" mb="12px">
                  {" "}
                  Domains limit reached
                </Text>
                <Text fontSize="20px" mb="12px" color="grays.3">
                  Please, select the domains you want to save. Your domain limit
                  is: {domainLimitAmount}
                </Text>
                {showError && (
                  <Text fontSize="16px" mb="12px" color="statusError">
                    Your can choose only {domainLimitAmount} domain.
                  </Text>
                )}
              </Box>
              <Form onSubmit={onSubmit}>
                {({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box>
                        {overlimitedDomains.map((domain) => (
                          <Label
                            key={domain?.id}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Field
                              name="domains"
                              component="input"
                              type="checkbox"
                              value={domain.id}
                              style={{ width: "20px", height: "20px" }}
                            />
                            <Text fontSize="14px" ml="6px">
                              {domain?.name}
                            </Text>
                          </Label>
                        ))}
                        <Box alignItems="center">
                          <Button variant="primary" width="200px">
                            Submit
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              </Form>
            </Box>
          </Card>
        </>
      )}
    </>
  );
};
