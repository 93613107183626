import { useEffect, useState, useMemo } from "react";
import normalizer from "json-api-normalizer";
import build from "redux-object";
import { mergeDeepRight } from "ramda";
import { Status, api } from "@cyberalarm/common";
import useSWR from "swr";
import qs from "qs";

import { useDomainID } from "utils/use-domain-id";

export const useDomainResults = ({
  status: currentStatus,
}: {
  status: Status | null;
}) => {
  const domainID = useDomainID();
  const [data, setData] = useState({});
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const key = useMemo(
    () =>
      domainID && currentStatus
        ? `domains/${domainID}/results?${qs.stringify({
            limit: 15,
            status: currentStatus,
            page,
          })}`
        : null,
    [currentStatus, domainID, page],
  );

  const { data: response, isValidating } = useSWR(key, api);

  useEffect(() => {
    if (response) {
      const { meta, deletedId, ...rest } = response as any;
      setHasMore(meta.has_more);
      const normalizedData = normalizer(rest) as any;
      if (page > 1 && deletedId === undefined) {
        setData((oldData) => {
          return mergeDeepRight(oldData, normalizedData);
        });
      } else if (deletedId) {
        setData((oldData: any) => {
          return {
            ...oldData,
            checkResult: Object.entries(oldData.checkResult).reduce(
              (accumulator: any, [key, value]) => {
                if (key !== deletedId) {
                  accumulator[key] = value;
                }
                return accumulator;
              },
              {},
            ),
          };
        });
      } else {
        setData(normalizedData);
      }
    }
  }, [page, response]);

  useEffect(() => {
    setPage(1);
  }, [domainID, currentStatus]);

  return {
    results: useMemo(() => data && build(data, "checkResult"), [data]) as
      | CheckResult[]
      | null,
    hasMore,
    setPage,
    loading: isValidating,
  };
};
