import React, { SVGProps } from "react";

export const Arrow = ({
  fill = "currentColor",
  width = 11,
  height = 14,
}: SVGProps<{}>) => (
  <svg width={width} height={height} viewBox="0 0 11 14" fill="none">
    <path d="M11 7L0.5 13.0622L0.5 0.937822L11 7Z" fill={fill} />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default Arrow;
