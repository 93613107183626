import React, { memo } from "react";
import { Form, Field } from "react-final-form";
import {
  FormButton,
  Box,
  Card,
  Text,
  FormInner,
  AdaptedRadio,
  Label,
  theme,
} from "@cyberalarm/ui";
import styled from "styled-components";
import build from "redux-object";
import { api } from "@cyberalarm/common";
import normalize from "json-api-normalizer";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RadioFlexBox = styled(FlexBox)`
  margin-bottom: 0;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: 8px;
  }
`;

const SectionLabel = styled(Text)`
  font-size: 16px;
  min-width: 5rem;
  margin-left: 4rem;
  text-align: left;
  font-weight: 400;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-left: 8px;
  }
`;

const FlexCard = styled(Card)`
  overflow: auto;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface ModalProps {
  close: () => void;
  user: TeamUser | undefined;
  setTeamUsers: (users: TeamUser[]) => void;
}

interface UpdateUserFormProps {
  active: string | undefined;
  role: string | undefined;
}

const Modal = ({ close, user, setTeamUsers }: ModalProps) => {
  const onSubmit = async (values: UpdateUserFormProps) => {
    const data = await api("teams/update_user", {
      method: "PATCH",
      body: {
        id: user?.id,
        active: values.active === "true",
        role: values.role,
      },
    });
    const normalizedTeamData = normalize(data);
    const builtTeamData = build(normalizedTeamData, "teamUser");
    setTeamUsers(builtTeamData);
    close();
  };

  return (
    <>
      <FlexCard>
        <Box marginX={6} marginY={6} flex={1}>
          <Box alignItems="center" marginX="auto">
            <Label>
              <Text fontFamily="title" fontSize="24px">
                Update user
              </Text>
            </Label>
          </Box>
          <Box alignItems="center" marginX="auto">
            <Label>
              <Text mt={4} style={{ opacity: 0.6 }}>
                [{user?.email}]
              </Text>
            </Label>
          </Box>
          <Box marginX="auto">
            <Form
              onSubmit={onSubmit}
              initialValues={{
                active: user?.active.toString(),
                role: user?.role,
              }}
            >
              {({ handleSubmit, submitting }) => {
                return (
                  <FormInner onSubmit={handleSubmit}>
                    <Box mt={4} justifyItems="center">
                      <FlexBox mt={3} alignItems="center">
                        <SectionLabel style={{ margin: "auto" }}>
                          Active
                        </SectionLabel>
                        <RadioFlexBox width="6rem">
                          <Field
                            component={AdaptedRadio}
                            name="active"
                            label="Yes"
                            type="radio"
                            value="true"
                          />
                        </RadioFlexBox>
                        <RadioFlexBox width="6rem">
                          <Field
                            component={AdaptedRadio}
                            name="active"
                            label="No"
                            type="radio"
                            value="false"
                          />
                        </RadioFlexBox>
                      </FlexBox>
                      <FlexBox mt={3} alignItems="center">
                        <SectionLabel style={{ margin: "auto" }}>
                          Role
                        </SectionLabel>
                        <RadioFlexBox width="6rem">
                          <Field
                            component={AdaptedRadio}
                            name="role"
                            label="User"
                            type="radio"
                            value="user"
                          />
                        </RadioFlexBox>
                        <RadioFlexBox width="6rem">
                          <Field
                            component={AdaptedRadio}
                            name="role"
                            label="Admin"
                            type="radio"
                            value="admin"
                          />
                        </RadioFlexBox>
                      </FlexBox>

                      <Box alignItems="center">
                        <FormButton
                          mt="48px"
                          type="submit"
                          variant="primary"
                          disabled={submitting}
                        >
                          Submit
                        </FormButton>
                      </Box>
                    </Box>
                  </FormInner>
                );
              }}
            </Form>
          </Box>
        </Box>
      </FlexCard>
    </>
  );
};

export const UpdateTeamUserModal = memo(Modal);

UpdateTeamUserModal.displayName = "UpdateTeamUser";
