import { useCallback } from "react";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { api } from "@cyberalarm/common";

import { i18n } from "./schema";

import {
  invalidPassword,
  accessDenied,
  errors as errorsIntl,
} from "shared-schema";

export const usePasswordUpdate = () => {
  const intl = useIntl();
  return useCallback(
    async ({ oldPassword, ...rest }, form) => {
      const user = {
        ...rest,
        currentPassword: oldPassword,
      };
      try {
        const { errors } = await api("password", {
          method: "PATCH",
          body: { user },
        });

        if (errors) {
          throw errors[0].status;
        }

        // HACK: to avoid crash https://github.com/final-form/final-form/issues/142
        setTimeout(form.reset);

        toast.success(intl.formatMessage(i18n.passwordUpdateSuccess));
      } catch (error) {
        const message = error === 422 ? invalidPassword.id : accessDenied.id;
        toast.error(intl.formatMessage(errorsIntl.commonError));

        return { oldPassword: message };
      }

      return;
    },
    [intl],
  );
};
