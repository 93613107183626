import { loadStripe } from "@stripe/stripe-js";
import normalizer from "json-api-normalizer";
import build from "redux-object";
import { api } from "@cyberalarm/common";
import useSWR from "swr";
import { toast } from "react-toastify";

export interface SubscriptionPlans {
  [key: string]: string | {};
  currency: string;
  description: string;
  id: string;
  name: string;
  price: string;
}

interface PlanProps {
  attributes: {
    metadata: { product: string };
    price: { currency: string; frequency: string; unit_price: number };
  };
}

export const snakeCaseToInitCaps = (str: string | undefined) => {
  if (str === undefined) return "";
  return str
    .split("_")
    .map((e) => e.charAt(0).toUpperCase() + e.substring(1))
    .join(" ");
};

export const useProductOptions = () => {
  return [
    { value: "email_protect", label: "Email Protect" },
    { value: "web_protect", label: "Web Protect" },
    { value: "full_protect", label: "Full Protect" },
  ] as { value: string; label: string }[];
};

export const usePlanOptions = () => {
  return [
    { value: "essential", label: "Essential" },
    { value: "premium", label: "Premium" },
  ] as { value: string; label: string }[];
};

export const useDomainsQtyOptions = () => {
  return [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 40, label: "40" },
    { value: 50, label: "50" },
    { value: 60, label: "60" },
    { value: 70, label: "70" },
    { value: 80, label: "80" },
    { value: 90, label: "90" },
    { value: 100, label: "100" },
  ] as { value: number; label: string }[];
};

export const usePlans = () => {
  const { data } = useSWR("plans", api);
  if (data?.data !== undefined) {
    const planData = data?.data.map((e: PlanProps) => {
      return {
        product: e.attributes.metadata.product,
        price: e.attributes.price,
      };
    });
    return planData;
  } else {
    return undefined;
  }
};

export const redirectToStripeCheckoutForUpdatePaymentMethod = async (
  close: () => void
) => {
  const data = await api("stripe/customer_session_update_payment_method", {
    method: "POST",
    body: {},
  }).catch(() => {
    toast.error("Something went wrong, please try again");
  });

  if (data?.data !== undefined) {
    const stripePromise = await loadStripe(data.data.pub_key);
    stripePromise?.redirectToCheckout({ sessionId: data.data.id }).catch(() => {
      toast.error("Something went wrong, please try again");
    });
    toast.success("Request for update processed successfully");
    close();
  } else {
    toast.error("Something went wrong, please try again");
  }
};

export const updateSubscription = async (
         params: {
           product: string;
           plan: string;
           domains: number;
         },
         close: () => void,
         refresh: () => void,
         setBusyOpen: (open: boolean) => void
       ) => {
         close();
         setBusyOpen(true);
         const data = await api("stripe/update_subscription", {
           method: "POST",
           body: {
             product: params.product,
             plan: params.plan,
             domains: params.domains,
           },
         }).catch(() => {
           toast.error("Something went wrong, please try again");
         });

         if (data?.data !== undefined) {
           refresh();
         } else {
           toast.error("Something went wrong, please try again");
         }
       };

export const useCurrentPaymentMethod = () => {
  const { data } = useSWR("stripe/current_payment_method", api);
  if (data?.data !== undefined) {
    return data.data as {
      brand: string;
      exp_month: number;
      exp_year: number;
      last4: string;
    };
  }
};

export const useSubscriptionPlans = () => {
  const { data } = useSWR("plans", api);
  const normalizedData = data && normalizer(data);
  const builtPlans: SubscriptionPlans[] =
    normalizedData && build(normalizedData, "plan");
  return { builtPlans };
};
