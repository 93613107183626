import React, { useMemo } from "react";
import { Redirect, Route } from "react-router-dom";
import { CookiesType, getCookie } from "@cyberalarm/common";

import { CommonRoutes } from "./routes";

interface GuestRouteProps {
  component: React.ComponentType<any>;
  path: string;
  redirect?: string;
  exact?: boolean;
}

export const GuestRoute = ({
  component: Component,
  redirect = CommonRoutes.root,
  ...rest
}: GuestRouteProps) => {
  const token = getCookie(CookiesType.token);

  return useMemo(
    () => (
      <Route
        {...rest}
        render={(props) =>
          token == null ? <Component {...props} /> : <Redirect to={redirect} />
        }
      />
    ),
    [redirect, rest, token],
  );
};
