import { useMemo } from "react";
import normalizer from "json-api-normalizer";
import build from "redux-object";
import { api } from "@cyberalarm/common";
import useSWR from "swr";

import { useDomainID } from "utils/use-domain-id";

export const useDomainCheckResults = (props: {
  id: string | null;
}): CheckResult | undefined => {
  const domID = useDomainID();

  const { data } = useSWR(
    () => (domID && props.id ? [domID, props.id] : null),
    (domainID, resultID) => api(`domains/${domainID}/results/${resultID}`),
  );

  return useMemo(() => {
    if (data) {
      const normalizedData = normalizer(data);
      return build(normalizedData, "checkResult", props.id);
    }
  }, [data, props.id]);
};
