import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import themeGet from "@styled-system/theme-get";

const Root = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  ${(p) =>
    p.isActive
      ? css`
          background: ${themeGet("colors.statusError")};
          border: 1px solid ${themeGet("colors.statusError")};
        `
      : css`
          border: 1px solid ${themeGet("colors.grays.0")};
          background: white;
        `}
`;

const Radio = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
`;

interface RadioButtonProps<T> {
  value: T;
  name: T;
  onClick: (name: T) => void;
}

export const RadioButton = <P extends {}>({
  value,
  name,
  onClick,
}: RadioButtonProps<P>) => {
  const isActive = value === name;
  const handleClick = useCallback(() => onClick(name), [name, onClick]);
  return (
    <Root isActive={isActive} onClick={handleClick}>
      {isActive && <Radio />}
    </Root>
  );
};
