import React, { memo } from "react";
import { MessageDescriptor, defineMessages, useIntl } from "react-intl";
import * as yup from "yup";
import { Form, Field } from "react-final-form";
import {
  FormInner,
  Button,
  Box,
  FormGroup,
  Label,
  AdaptedInput,
  FormControlFeedback,
} from "@cyberalarm/ui";
import { form } from "@cyberalarm/common";

const { error } = defineMessages({
  error: {
    defaultMessage: "Minimum 3 chars",
    id: "searchInput.error",
  },
});

const minimalLength = 3;
const schema = yup.object().shape({
  query: yup
    .string()
    .ensure()
    .test("test", error.id, (value?: string) => {
      if (value && value.length < minimalLength) {
        return false;
      }

      return true;
    }),
});

interface SearchProps {
  onSubmit: (value: { [key: string]: string }) => void;
  label: MessageDescriptor;
  buttonLabel: MessageDescriptor;
}

export const SearchInput = memo(
  ({ onSubmit, label, buttonLabel }: SearchProps) => {
    const intl = useIntl();
    return (
      <Form onSubmit={onSubmit} validate={form.validateForm({ schema })}>
        {({ handleSubmit }) => (
          <FormInner onSubmit={handleSubmit}>
            <Box
              flexDirection="row"
              alignItems="center"
              width="400px"
              marginBottom={5}
            >
              <FormGroup>
                <Label htmlFor="query">{intl.formatMessage(label)}</Label>
                <Box flexDirection="row" minWidth="400px">
                  <Field id="query" name="query" component={AdaptedInput} />
                  <Button type="submit" variant="primary" marginLeft="16px">
                    {intl.formatMessage(buttonLabel)}
                  </Button>
                </Box>
                <FormControlFeedback name="query" />
              </FormGroup>
            </Box>
          </FormInner>
        )}
      </Form>
    );
  },
);

SearchInput.displayName = "SearchInput";
