import React, { Fragment } from "react";

import { MobileSideBar } from "./mobile-side-bar";
import { TabletSideBar } from "./tablet-side-bar";
import { DesktopSidebar } from "./desktop-side-bar";

export const UserSideBar = () => (
  <Fragment>
    <MobileSideBar />
    <TabletSideBar />
    <DesktopSidebar />
  </Fragment>
);
