import React from "react";
import styled from "styled-components";
import { Link as RLink, Route } from "react-router-dom";
import { MessageDescriptor, useIntl } from "react-intl";
import { Text, Box } from "@cyberalarm/ui";

export const StyledLink = styled(RLink)`
  text-decoration: none;
  margin-bottom: 6px;
`;

export const IconRoot = styled(Box)`
  width: 29px;
  height: 29px;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  margin-bottom: auto;
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;
`;

export const Link = ({
  to,
  label,
}: {
  to: string;
  label: MessageDescriptor;
}) => {
  const intl = useIntl();
  return (
    <Route path={to} exact={false}>
      {({ match }) => (
        <StyledLink to={to}>
          <Text fontWeight={500} fontSize={0} color={match ? "blue" : "logo"}>
            {intl.formatMessage(label)}
          </Text>
        </StyledLink>
      )}
    </Route>
  );
};
