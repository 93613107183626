import * as yup from "yup";
import { defineMessages } from "react-intl";

export const { required, yesLabel, noLabel, areYouSure } = defineMessages({
  required: {
    defaultMessage: "Field is required",
    id: "manageSubscription.required",
  },
  yesLabel: {
    defaultMessage: "Yes",
    id: "confirmationModal.yes",
  },
  noLabel: {
    defaultMessage: "No",
    id: "confirmationModal.no",
  },
  areYouSure: {
    defaultMessage: "Are you sure?",
    id: "confirmationModal.areYouSure",
  },
});

export const manageSubscriptionSchema = yup.object().shape({
  product: yup.string().required(required.id),
  plan: yup.string().required(required.id),
  domainsInput: yup.number().required(required.id),
  domainsSelect: yup.object().shape({
    value: yup.number().required(required.id),
  }),
});
