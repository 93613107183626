import React from "react";
import styled from "styled-components";
import { Icons } from "@cyberalarm/common";

import { getErrorBorder } from "../theme";

import { Icon } from "./icon";
import { Box } from "./box";

const Root = styled(Box)<{ valid?: boolean; width?: string; height?: string }>`
  ${getErrorBorder};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: ${(p) => (p.width ? p.width : "16px")};
  height: ${(p) => (p.height ? p.height : "16px")};
`;

interface CheckboxProps {
  checked: boolean;
  onClick: () => void;
  valid?: boolean;
  width?: string;
  height?: string;
}

export const Checkbox = ({
  checked,
  onClick,
  valid,
  width,
  height,
}: CheckboxProps) => (
  <Root valid={valid} onClick={onClick} width={width} height={height}>
    {checked && <Icon name={Icons.check} />}
  </Root>
);
