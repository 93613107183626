import React, { useContext, memo } from "react";

import { DetailsContext } from "./context";

import { CheckDetails } from "ui";
import { useDomainCheckResults } from "hooks";

export const ModalDetails = memo(() => {
  const { close, isOpen, id } = useContext(DetailsContext);
  const details = useDomainCheckResults({ id });

  return <CheckDetails details={details} close={close} isOpen={isOpen} />;
});

ModalDetails.displayName = "ModalDetails";
