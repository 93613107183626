import { useMemo } from "react";
import normalizer from "json-api-normalizer";
import build from "redux-object";
import { api } from "@cyberalarm/common";
import useSWR from "swr";

export interface DomainMeta {
  id: string;
  domainHosting: string;
  emailHosting: string;
  primaryWebsites: string[];
  primaryRedirects: string[];
  registrar: string;
  websites: string[];
}

export const useDomainProfile = (domain: string): DomainMeta => {
  const domainId = domain ? `domains/${domain}/meta` : null;
  const { data } = useSWR(domainId, api);
  return useMemo(() => {
    if (data && data !== null) {
      const normalizedData = normalizer(data);
      const builtData = build(normalizedData, "domainMetum");
      if (builtData) {
        return builtData[0] as DomainMeta;
      }
    }
    return data;
  }, [data]);
};
