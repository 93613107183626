import React, { useEffect, useState } from "react";
import { Mobile, Icon, Box } from "@cyberalarm/ui";
import { Icons, useToggle } from "@cyberalarm/common";

import { MobileIconRoot, MobileListRoot, StyledLink } from "./components";

import { DomainManagement, Control } from "ui";
import { CommonRoutes, UserRoutes } from "routes";
import { useProfileData } from "hooks";

import { useLocation } from "react-router";

import { SubscriptionInfo } from "./subscription-info";
import styled from "styled-components";
import { useLogout } from "hooks";

const RotatedLink = styled(StyledLink)`
  transform: rotate(90deg);
  margin-bottom: 12px;
  height: auto;
`;

export const MobileSideBar = () => {
  const [isOpen, toggle] = useToggle();
  const user = useProfileData();
  const [showTeamLink, setShowTeamLink] = useState(false);
  const location = useLocation();
  const logout = useLogout();
  const showSubscriptionInfo =
    location.pathname === "/user/team" || location.pathname === "/user/profile";

  useEffect(() => {
    if (user?.teamAttributes?.role === "admin") {
      setShowTeamLink(true);
    }
  }, [user]);

  return (
    <Mobile>
      <MobileIconRoot onClick={toggle}>
        <Icon name={isOpen ? Icons.close : Icons.hamburger} />
      </MobileIconRoot>

      <MobileListRoot isOpen={isOpen}>
        <Box
          justifyContent="space-evenly"
          alignItems="center"
          flexDirection="row"
          marginBottom={6}
        >
          <Control />
          {showTeamLink && (
            <StyledLink
              to={UserRoutes.team}
              style={{ padding: "12px", paddingTop: "8px" }}
            >
              <Icon name={Icons.gear} />
            </StyledLink>
          )}
          <StyledLink to={UserRoutes.profile} style={{ padding: "12px" }}>
            <Icon name={Icons.user} />
          </StyledLink>
          <StyledLink to={UserRoutes.support} style={{ padding: "8px" }}>
            <Icon name={Icons.support} />
          </StyledLink>
          <RotatedLink to={CommonRoutes.signIn} onClick={logout}>
            <Icon name={Icons.arrowUp} width="24px" height="24px" />
          </RotatedLink>
        </Box>
        {showSubscriptionInfo && <SubscriptionInfo />}
        {!showSubscriptionInfo && <DomainManagement />}
      </MobileListRoot>
    </Mobile>
  );
};
