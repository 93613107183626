import React, { memo } from "react";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { Icons } from "@cyberalarm/common";

import { Icon, Box } from "../atoms";

const IconRoot = styled(Box)`
  cursor: pointer;
  position: absolute;
  top: calc(50% - 10px);
  right: 16px;
  opacity: ${(p: { show: boolean }) => (p.show ? 0.8 : 0.6)};
  z-index: 1;

  :hover {
    color: ${themeGet("colors.grays.2")};
  }

  transition: all 0.2s;
`;

interface ShowPasswordIconProps {
  show: boolean;
  onClick: () => void;
}

const BaseComponent = ({ show, onClick }: ShowPasswordIconProps) => (
  <IconRoot show={show} onClick={onClick}>
    <Icon name={Icons.eye} />
  </IconRoot>
);

export const ShowPasswordIcon = memo(BaseComponent);
