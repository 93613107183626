import React, { SVGProps } from "react";

const User = ({
  width = 28,
  height = 28,
  fill = "none",
  stroke = "#000000",
}: SVGProps<{}>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.81106 4.11106C4.81106 2.34905 6.23793 0.922168 7.99995 0.922168C9.76196 0.922168 11.1888 2.34904 11.1888 4.11106C11.1888 5.87307 9.76196 7.29995 7.99995 7.29995C6.23793 7.29995 4.81106 5.87307 4.81106 4.11106ZM0.922168 12.8611C0.922168 12.4716 1.11169 12.0787 1.54717 11.6768C1.9874 11.2705 2.63067 10.904 3.39793 10.5966C4.93358 9.98144 6.78953 9.67217 7.99995 9.67217C9.21036 9.67217 11.0663 9.98144 12.602 10.5966C13.3692 10.904 14.0125 11.2705 14.4527 11.6768C14.8882 12.0787 15.0777 12.4716 15.0777 12.8611V15.0777H0.922168V12.8611Z"
      stroke={stroke}
      fill={fill}
      strokeWidth="2"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default User;
