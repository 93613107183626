import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

import { getErrorBorder } from "../theme";

export const Textarea = styled.textarea<{ valid: boolean }>`
         border-radius: 0;
         border: 1px solid ${themeGet("colors.grays.8")};
         padding: 16px;
         color: ${themeGet("colors.gray")};
         font-size: 14px;
         font-family: "montserrat";
         font-weight: 400;
         background: ${themeGet("colors.white")};
         min-height: 156px;
         max-width: 100%;
         white-space: pre-wrap;
         overflow-wrap: break-word;
         letter-spacing: 0.01em;

         ${getErrorBorder};

         :focus,
         :active {
           border: 1px solid ${themeGet("colors.brand")};
         }

         :hover {
           border: 1px solid ${themeGet("colors.grays.2")};
         }

         transition: all 0.2s;
       `;
