import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CardHeader, Text, Box, Loader, Modal } from "@cyberalarm/ui";
import { StatusColors } from "@cyberalarm/common";
import { isEmpty } from "ramda";
import { toast } from "react-toastify";

import { TokenManagementModal } from "../token-management/modal";

import {
  title,
  noTokensTitle,
  manage,
  setupRequired,
  tokenDeleteSuccess,
  tokenDeleteFailure,
} from "./schema";
import {
  GdprToken,
  useGDPRTokens,
  deleteTokenAsync,
} from "./hooks";
import { TokenItem } from "./token";
import styled from "styled-components";

import { BusyModal } from "./busy-modal";
import { ConfirmationModal } from "./confirmation-modal";

const StyledHeaderText = styled(Text)`
  font-size: 20px;
  font-weight: 400;
`;

const StyledInfoText = styled(Text)`
  font-size: 18px;
  font-weight: 500;
`;

export const TokensList = () => {
  const [busyOpen, setBusyOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [token, setToken] = useState<GdprToken | undefined>(undefined);
  const data = useGDPRTokens();
  const intl = useIntl();
  const dataAlerts = data?.filter((data) => data.compromised === true).length;
  const [confirmMessages, setConfirmMessages] = useState<string[]>([]);

  const closeDeleteBusyModal = (success: boolean) => {
    if (success) {
      toast.success(intl.formatMessage(tokenDeleteSuccess));
    } else {
      toast.error(intl.formatMessage(tokenDeleteFailure));
    }
    setBusyOpen(false);
  };

  const deleteToken = (token: GdprToken) => {
    setToken(token);
    setConfirmMessages([
      "Are you sure you want to delete this token?",
      `[${token.generatedInfo.token}]`,
    ]);
    setConfirmOpen(true);
  };

  const onConfirmYes = () => {
    setConfirmOpen(false);
    setBusyOpen(true);
    deleteTokenAsync(token, closeDeleteBusyModal);
  };

  const onConfirmNo = () => {
    setConfirmOpen(false);
  };

  return (
    <>
      {isEmpty(data) ? (
        <Box
          display="flex"
          margin={5}
          justifyContent="space-between"
          flexDirection="row"
          marginTop="24px"
          height="fit-content"
        >
          <StyledHeaderText>
            {intl.formatMessage(noTokensTitle)}
          </StyledHeaderText>
          <TokenManagementModal label={intl.formatMessage(setupRequired)} />
        </Box>
      ) : (
        <>
          <CardHeader minHeight="54px">
            <Box flexDirection="row" justifyContent="space-between">
              <StyledInfoText
                color={dataAlerts ? StatusColors.failed : StatusColors.passed}
                marginRight="8px"
              >
                {dataAlerts ?? 0}
              </StyledInfoText>
              <StyledInfoText>{intl.formatMessage(title)}</StyledInfoText>
            </Box>
            <Box flexDirection="row" justifyContent="flex-end">
              <TokenManagementModal label={intl.formatMessage(manage)} />
            </Box>
          </CardHeader>
          <Box
            maxHeight={{ _: "150px", xl: "calc(27vh - 180px)" }}
            overflowY="auto"
            display="grid"
          >
            {data === undefined && <Loader />}
            {data &&
              data.map((item) => (
                <TokenItem
                  key={item.id}
                  token={item}
                  deleteToken={deleteToken}
                />
              ))}
          </Box>
        </>
      )}
      {confirmOpen && (
        <Modal isOpen={confirmOpen} close={onConfirmNo} zIndex={1001}>
          <ConfirmationModal
            handleYes={onConfirmYes}
            handleNo={onConfirmNo}
            messages={confirmMessages}
          />
        </Modal>
      )}
      {busyOpen && (
        <Modal
          isOpen={busyOpen}
          close={() => {}}
          zIndex={1002}
          showCloseIcon={false}
        >
          <BusyModal
            messages={["Processing...", "This might take a few seconds..."]}
          />
        </Modal>
      )}
    </>
  );
};
