import React from "react";
import { Box, Text, CardHeader, TruncateText } from "@cyberalarm/ui";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

import { useDomainProfile } from "./hooks";

export const Image = styled.img`
  height: 124px;
  width: 124px;
  margin-bottom: 8px;
`;

const StyledHeaderText = styled(Text)`
  font-size: 20px;
  font-weight: 600;
`;

const StyledLabelText = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  width: 172px;
  min-width: 172px;
  margin-right: 8px;
  color: ${themeGet("colors.black")};
`;
const StyledTruncateText = styled(TruncateText)`
  font-size: 16px;
  margin: 4px 0px;
  font-weight: 300;
  color: ${themeGet("colors.black")};
`;

interface DomainProfileProps {
  domain: any;
}

export const DomainProfile = ({ domain }: DomainProfileProps) => {
  const data = useDomainProfile(domain?.id);
  return (
    <Box>
      <CardHeader
        minHeight="54px"
        height="194px"
        flexDirection="column"
        p={0}
        py="24px"
      >
        <Image
          src={`http://logo.clearbit.com/${domain?.name ?? "null"}`}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError={(event: any) => {
            event.target.removeEventListener = null;
            event.target.src = "http://logo.clearbit.com/cyberalarm.org";
          }}
        />
        <StyledHeaderText>Your online profile</StyledHeaderText>
      </CardHeader>
      <Box
        p="24px 24px 16px 24px"
        height="fit-content"
        maxHeight={{
          _: "400px",
          xl: "calc(63vh - 214px)",
        }}
        overflowY="auto"
        mb="16px"
      >
        <Box flexDirection="column" mb="16px">
          <StyledLabelText>Web hosting</StyledLabelText>
          <Box>
            <StyledTruncateText title={domain?.name ?? "N/A"}>
              {domain?.name ?? "N/A"}
            </StyledTruncateText>
            {data?.primaryRedirects?.length > 0 && (
              <StyledTruncateText> also known as </StyledTruncateText>
            )}
            {data?.primaryRedirects?.map((url) => {
              return <StyledTruncateText key={url}>{url}</StyledTruncateText>;
            }) ?? <StyledTruncateText>N/A</StyledTruncateText>}
          </Box>
        </Box>
        <Box flexDirection="column" mb="16px">
          <StyledLabelText>Other Web Sites</StyledLabelText>
          <Box>
            {data?.websites?.map((url) => {
              return (
                <StyledTruncateText mb="8px" key={url}>
                  {url}
                </StyledTruncateText>
              );
            }) ?? <StyledTruncateText>N/A</StyledTruncateText>}
          </Box>
        </Box>
        <Box flexDirection="column" mb="16px">
          <StyledLabelText>Email Hosting</StyledLabelText>
          <Box>
            <StyledTruncateText>
              {data?.emailHosting ?? "N/A"}
            </StyledTruncateText>
          </Box>
        </Box>
        <Box flexDirection="column" mb="16px">
          <StyledLabelText>Domain Registrar</StyledLabelText>
          <Box>
            <StyledTruncateText title={data?.registrar ?? "N/A"}>
              {data?.registrar ?? "N/A"}
            </StyledTruncateText>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
