import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";

import { theme } from "../theme";

export const Desktop = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.xl });

  return <Fragment>{isDesktop ? children : null}</Fragment>;
};
