/* eslint-disable @typescript-eslint/camelcase */
import { defineMessages } from "react-intl";

export const {
  required,
  invalidDomain,
  invalidEmail,
  invalidPassword,
  howDoIFix,
  additionalInformation,
  closeLabel,
} = defineMessages({
  required: {
    defaultMessage: "Required",
    id: "sharedIntl.required",
  },
  invalidDomain: {
    defaultMessage: "Invalid domain",
    id: "sharedIntl.invalidDomain",
  },
  invalidEmail: {
    defaultMessage: "Invalid email",
    id: "sharedIntl.invalidEmail",
  },
  invalidPassword: {
    defaultMessage: "Invalid password",
    id: "sharedIntl.invalidPassword",
  },
  howDoIFix: {
    defaultMessage: "How do I fix this?",
    id: "sharedIntl.howDoIFix",
  },
  additionalInformation: {
    defaultMessage: "Additional information",
    id: "sharedIntl.additionalInformation",
  },
  closeLabel: {
    defaultMessage: "Close",
    id: "sharedIntl.closeLabel",
  },
});

export const errors = defineMessages({
  domainTaken: {
    defaultMessage: "Domain is taken",
    id: "taken",
  },
  invalid_hostname_length: {
    defaultMessage: "hostname must be between 1 and 255 characters long",
    id: "invalid_hostname_length",
  },
  invalid_label_length: {
    defaultMessage: "Domain must be between 1 and 63 characters long",
    id: "invalid_label_length",
  },
  label_begins_or_ends_with_hyphen: {
    defaultMessage: "Begins or ends with hyphen",
    id: "label_begins_or_ends_with_hyphen",
  },
  label_contains_invalid_characters: {
    defaultMessage: "Contains invalid characters",
    id: "label_contains_invalid_characters",
  },
  hostname_label_is_numeric: {
    defaultMessage: "Unqualified part cannot consist of numeric values only",
    id: "hostname_label_is_numeric",
  },
  hostname_is_not_fqdn: {
    defaultMessage: "Isn't a fully qualified domain name",
    id: "hostname_is_not_fqdn",
  },
  single_numeric_hostname_label: {
    defaultMessage: "Can't consist of a single numeric label",
    id: "single_numeric_hostname_label",
  },
  hostname_contains_consecutive_dots: {
    defaultMessage: "Mustn't contain consecutive dots",
    id: "hostname_contains_consecutive_dots",
  },
  hostname_ends_with_dot: {
    defaultMessage: "Mustn't end with a dot",
    id: "hostname_ends_with_dot",
  },
  invalid_country: { defaultMessage: "Does not exist", id: "invalid_country" },
  invalid_complexity: {
    defaultMessage: "Too simple",
    id: "invalid_complexity",
  },
  invalid_format: {
    defaultMessage: "Must be cron expression",
    id: "invalid_format",
  },
  accepted: { defaultMessage: "Must be accepted", id: "accepted" },
  blank: { defaultMessage: "Can't be blank", id: "blank" },
  confirmation: { defaultMessage: "Doesn't match", id: "confirmation" },
  empty: { defaultMessage: "Can't be empty", id: "empty" },
  equal_to: { defaultMessage: "Must be equal", id: "equal_to" },
  even: { defaultMessage: "Must be even", id: "even" },
  exclusion: { defaultMessage: "Reserved", id: "exclusion" },
  greater_than: { defaultMessage: "Must be greater", id: "greater_than" },
  greater_than_or_equal_to: {
    defaultMessage: "mMust be greater or equal",
    id: "greater_than_or_equal_to",
  },
  inclusion: { defaultMessage: "Not included in the list", id: "inclusion" },
  invalid: { defaultMessage: "Invalid", id: "invalid" },
  less_than: { defaultMessage: "Must be less", id: "less_than" },
  less_than_or_equal_to: {
    defaultMessage: "Must be less or equal",
    id: "less_than_or_equal_to",
  },
  model_invalid: { defaultMessage: "'Validation failed", id: "model_invalid" },
  not_a_number: { defaultMessage: "Not a number", id: "not_a_number" },
  not_an_integer: {
    defaultMessage: "Must be an integer",
    id: "not_an_integer",
  },
  odd: { defaultMessage: "Must be odd", id: "odd" },
  other_than: { defaultMessage: "Must be other", id: "other_than" },
  present: { defaultMessage: "Must be blank", id: "present" },
  required: { defaultMessage: "Required", id: "required" },
  too_long: { defaultMessage: "Too long", id: "too_long" },
  too_short: { defaultMessage: "Too short", id: "too_short" },
  wrong_length: { defaultMessage: "Wrong length", id: "wrong_length" },
});
