import React, { memo, useEffect, useState } from "react";
import { Checkbox } from "@cyberalarm/ui";
import { _, Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";

export interface ResultsGridProps {
  results: CheckResult[];
  setCheckResultIds: (ids: string[]) => void;
}

export const ResultsGrid = memo((props: ResultsGridProps) => {
  const { results, setCheckResultIds } = props;
  const [selectedResultIds, setSelectedResultIds] = useState<string[]>([]);

  const toggleSelection = (value: string) => {
    setSelectedResultIds((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((id) => id !== value)
        : [...prevSelected, value]
    );
  };

  useEffect(() => {
    setCheckResultIds(selectedResultIds);
  }, [selectedResultIds, setCheckResultIds]);

  return (
    <>
      <Grid
        columns={[
          {
            name: "Select",
            data: (row: CheckResult) =>
              _(
                <Checkbox
                  checked={selectedResultIds.includes(row.id)}
                  onClick={() => toggleSelection(row.id)}
                  height="24px"
                  width="24px"
                />
              ),
            width: "5%",
            sort: false,
          },
          {
            name: "Type",
            data: (row: CheckResult) =>
              row.status === "failed"
                ? "Alarm"
                : row.status === "warnings"
                ? "Warning"
                : row.status,
            width: "12%",
          },
          {
            name: "Element",
            data: (row: CheckResult) => row.domainName,
            width: "15%",
          },
          {
            name: "Test",
            data: (row: CheckResult) => row.check.template.title,
            width: "18%",
          },
          {
            name: "Result",
            data: (row: CheckResult) => row.info,
            width: "50%",
          },
        ]}
        data={results}
        sort={true}
        autoWidth={false}
        search={true}
        style={{
          table: {
            "font-family": `Helvetic Neue, Helvetica`,
            "font-size": "16px",
            "max-width": " 900px",
            border: "1px solid #dedede",
          },
          th: {
            "text-wrap": "wrap",
            "font-weight": "600",
          },
          td: {
            "overflow-wrap": "break-word",
            "padding-left": "16px",
            "padding-right": "16px",
          },
        }}
      />
    </>
  );
});
