import React, { useCallback, Fragment, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { sortBy, prop } from "ramda";
import themeGet from "@styled-system/theme-get";
import { Text, Spinner, Box } from "@cyberalarm/ui";
import { Status } from "@cyberalarm/common";

import { i18n } from "./schema";
import { ResultItem } from "./result-item";
import { Placeholder } from "./placeholder";
import { Info } from "./info";

import { useDomainResults } from "hooks";

const sortByStatus = sortBy(prop("status"));

const LoadMoreRoot = styled(Box)`
  cursor: pointer;
  min-height: 30px;
  justify-content: flex-end;
  align-items: center;
  padding: 35px 24px;
  flex-direction: row;

  ${Text} {
    color: ${themeGet("colors.black")};

    :hover {
      text-decoration: underline;
    }
  }
`;

const ResultsRoot = styled(Box)`
  overflow: auto;
  max-height: fit-content;
  min-height: 50px;
`;

interface ResultsProps {
  status: Status | null;
  failedCount: number;
  passedCount: number;
  warningsCount: number;
  isTestPassed: boolean;
  hasProblems: boolean;
  lastScanAt: string | null;
  openDetails: (id: string) => void;
}

export const Results = ({
  status,
  passedCount,
  failedCount,
  warningsCount,
  isTestPassed,
  hasProblems,
  lastScanAt,
  openDetails,
}: ResultsProps) => {
  const { results, hasMore, setPage, loading } = useDomainResults({
    status,
  });

  const [sortedResults, setSortedResults] = useState<CheckResult[]>();

  const openResult = (id: string) => {
    const updatedResults = sortedResults?.map((result) => {
      return result.id === id ? { ...result, read: true } : result;
    });

    setSortedResults(updatedResults);

    openDetails(id);
  };

  const handleLoadMore = useCallback(() => setPage((page) => page + 1), [
    setPage,
  ]);

  useMemo(() => results && setSortedResults(sortByStatus(results)), [results]);

  const showWarnings = status === Status.warnings && Boolean(warningsCount);
  const showErrors = status === Status.failed && Boolean(failedCount);

  if (isTestPassed) {
    return <Placeholder lastScanAt={lastScanAt} passedCount={passedCount} />;
  }

  return (
    <Fragment>
      {(showWarnings || showErrors) && (
        <ResultsRoot>
          {sortedResults &&
            sortedResults.map((item: CheckResult) => (
              <ResultItem key={item.id} {...item} openDetails={openResult} />
            ))}

          {hasMore && (
            <LoadMoreRoot>
              <Text fontWeight={400} onClick={handleLoadMore}>
                <FormattedMessage {...i18n.loadMore} />
              </Text>
            </LoadMoreRoot>
          )}
        </ResultsRoot>
      )}

      {!(showWarnings || showErrors) && hasProblems && (
        <Info
          failedCount={failedCount}
          passedCount={passedCount}
          warningsCount={warningsCount}
        />
      )}

      {loading && (
        <Box alignItems="center" justifyContent="center" height="196px">
          <Box display="block">
            <Spinner />
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
