import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { Icons } from "@cyberalarm/common";

import { Icon, Portal, Box } from "../atoms";
import { theme } from "../theme";

const Root = styled(Box)<{ zIndex?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${(p) => p.zIndex};

  ::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(135, 179, 137, 0.87);
    z-index: -1;
  }
`;

const ContentRoot = styled(Box)`
  position: relative;
  margin: 24px 0;
  min-height: 0;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    margin: 0;
    width: 100%;
    overflow-y: auto;
  }
`;

const CloseRoot = styled(Box)`
  cursor: pointer;
  color: white;
  position: absolute;
  right: -25px;
  top: 0;
  transition: 0.2s;

  :hover {
    color: ${themeGet("colors.secondary")};
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    right: 15px;
    top: 15px;
    color: ${themeGet("colors.gray")};
  }
`;

interface ModalProps {
  isOpen: boolean;
  close: () => void;
  children: React.ReactNode;
  zIndex?: number;
  showCloseIcon?: boolean;
  width?: string | {};
  height?: string;
  maxWidth?: string;
}

export const Modal = ({
  isOpen,
  close,
  children,
  zIndex = 1000,
  showCloseIcon = true,
  width,
  height,
  maxWidth,
}: ModalProps) => {
  const reference = useRef<null | HTMLDivElement>(null);
  /*
                    //Commented out on 2024-07-11 due to scroll issue on iPhone - have to use two fingers
  //Original
  useEffect(() => {
    if (isOpen && reference.current != null) {
      disableBodyScroll(reference.current);
    }
    if (!isOpen && reference.current != null) {
      enableBodyScroll(reference.current);
    }
    return () => clearAllBodyScrollLocks();
  }, [isOpen]);
  //Modified trying to handle allowTouchMove
  useEffect(() => {
    if (isOpen && reference.current != null) {
      disableBodyScroll(reference.current, {
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.getAttribute("body-scroll-lock-ignore") !== null) {
              return true;
            }
            if (el.parentElement) {
              el = el.parentElement;
            }
          }
        },
      });
    }
    if (!isOpen && reference.current != null) {
      enableBodyScroll(reference.current);
    }
    return () => clearAllBodyScrollLocks();
  }, [isOpen]);
*/
  return (
    <Portal>
      {isOpen && (
        <Root zIndex={zIndex}>
          <ContentRoot width={width} maxWidth={maxWidth}>
            {showCloseIcon && (
              <CloseRoot onClick={close}>
                <Icon name={Icons.close} />
              </CloseRoot>
            )}
            <Box ref={reference} height={height ?? "100%"} alignItems="center">
              {children}
            </Box>
          </ContentRoot>
        </Root>
      )}
    </Portal>
  );
};
