import { useCallback } from "react";
import { api } from "@cyberalarm/common";
import normalizer from "json-api-normalizer";
import build from "redux-object";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { tokensListRequest } from "../token-list/hooks";

import {
  GDPRTokenCreateSchema,
  tokenGenerated,
  errorOnGenerateToken,
} from "./schema";

export interface Token {
  id: string;
  tokenName: string;
  tokenType: string;
  generatedInfo: {
    token: string;
    fullName: string;
  };
  updatedAt: string;
  createdAt: string;
}

export const useGDPRTokenGenerate = () => {
  const intl = useIntl();

  return useCallback(
    async (values: GDPRTokenCreateSchema) => {
      try {
        const data = await api("tokens", {
          method: "POST",
          body: values,
        });
        const normalizedData = normalizer(data);
        const builtData: Token = build(normalizedData, "gdprToken")[0];

        toast.success(intl.formatMessage(tokenGenerated));
        mutate(tokensListRequest);
        return builtData;
      } catch (error) {
        toast.error(intl.formatMessage(errorOnGenerateToken));
      }
    },
    [intl],
  );
};
