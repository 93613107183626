import styled from "styled-components";

import { Text } from "./text";

export const FormTitle = styled(Text)`
         font-weight: 400;
         line-height: 1.43;
         font-size: 28px;
       `;

FormTitle.defaultProps = {
  color: "logo",
  fontFamily: "title",
};
