import React, { useContext, useState, useEffect } from "react";

import { Box, Text, Card } from "@cyberalarm/ui";

import { StoreContext, DomainStateContext, domainSelectors } from "store";

import styled from "styled-components";

import themeGet from "@styled-system/theme-get";

const snakeCaseToInitCaps = (str: string | undefined) => {
  if (str === undefined) return "";
  return str
    .split("_")
    .map((e) => e.charAt(0).toUpperCase() + e.substring(1))
    .join(" ");
};

const StyledCard = styled(Card)`
  min-width: 200px;
`;

const DomainsUsage = styled.svg`
  margin: 24px auto;
  height: 170px;
  width: 170px;
`;

const DomainsUsageText = styled.text`
  font-family: ${themeGet("fonts.montserrat")};
  font-size: 32px;
`;

const DomainUsageTspan = styled.tspan``;
const StyledDomainUsageTspan = styled(DomainUsageTspan)`
  opacity: 0.5;
`;

export const SubscriptionInfo = () => {
  const { team } = useContext(StoreContext);
  const state = useContext(DomainStateContext);
  const domains = domainSelectors.getDomainList(state);
  const [plan, setPlan] = useState("");
  const [domainsUsage, setDomainsUsage] = useState("");
  const [domainsLimit, setDomainsLimit] = useState("");
  const [usagePercentage, setUsagePercentage] = useState(40);
  const [circleStroke, setCircleStroke] = useState("#A5D560");

  useEffect(() => {
    if (team && domains) {
      setPlan(
        `${snakeCaseToInitCaps(
          team.planPreferences?.product
        )} (${snakeCaseToInitCaps(team?.planPreferences?.plan)})`
      );
      setDomainsLimit(`${team?.planPreferences?.domains}`);
      setDomainsUsage(`${domains.result.length}`);
      if (team.planPreferences?.domains) {
        const percentage = Math.ceil(
          (domains.result.length / team?.planPreferences?.domains) * 100
        );
        setUsagePercentage(percentage);
        setCircleStroke(
          percentage <= 50
            ? "#A5D560"
            : percentage <= 75
            ? "#F6955A"
            : "#E25A5A"
        );
      }
    }
  }, [team, domains, setPlan, setDomainsLimit, setDomainsUsage]);
  return (
    <Box marginTop="48px">
      <StyledCard>
        <Box>
          <Text fontSize="20px" padding="16px">
            Your Plan
          </Text>
        </Box>
        <Text padding="0px 16px">{plan}</Text>
        <DomainsUsage>
          <path d="M85 5" />
          <circle
            cx="75"
            cy="75"
            r="65"
            fill="#f5f3ec"
            stroke={`${circleStroke}`}
            stroke-width="4"
            stroke-linecap="round"
            pathLength="100"
            stroke-dasharray={`${usagePercentage}, 100`}
            transform={`rotate(-90, 75, 75)`}
          />
          <DomainsUsageText textAnchor="middle" x="75" y="90">
            <DomainUsageTspan>{domainsUsage}</DomainUsageTspan>
            <StyledDomainUsageTspan>/{domainsLimit}</StyledDomainUsageTspan>
          </DomainsUsageText>
        </DomainsUsage>
      </StyledCard>
    </Box>
  );
};
