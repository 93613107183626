import { createSelector } from "reselect";

import { DomainList } from "./actions";

const getState = (state: DomainList) => state;

export const getActiveDomainID = (
  _: DomainList,
  props: { activeDomainID: string | null },
) => props.activeDomainID;

export const getDomainList = createSelector(
  getState,
  ({ entities, result }: DomainList) => ({ entities, result }),
);

export const getActiveDomain = createSelector(
  getDomainList,
  getActiveDomainID,
  ({ entities }: DomainList, activeDomainID: string | null) =>
    entities.domain && activeDomainID
      ? { [activeDomainID]: entities.domain[activeDomainID] }
      : null,
);
