import React, { memo, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import themeGet from "@styled-system/theme-get";
import { Form, Field } from "react-final-form";
import styled from "styled-components";
import {
  Button,
  Box,
  Card,
  CardHeader,
  Text,
  AdaptedInput,
  FormControlFeedback,
  FormInner,
  Input,
  theme,
  Modal,
} from "@cyberalarm/ui";

import { useGDPRTokens } from "../token-list/hooks";

import {
  dataBreachTokens,
  createTestEntry,
  GDPRTokenCreateValidator,
  GDPRTokenCreateSchema,
  generate,
  activeTokens,
  generatedUserName,
  generatedEmailAddress,
  dataBreachDescription,
} from "./schema";
import { useGDPRTokenGenerate, Token } from "./hooks";
import { ActiveGdprTokenItem } from "./active-tokens-list-item";
import { BusyModal } from "../token-list/busy-modal";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FlexCard = styled(Card)`
  max-width: 800px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    max-width: 100%;
  }
`;

const ActiveTokens = styled(Box)`
  cursor: pointer;
  border-top: 1px solid ${themeGet("colors.grays.0")};
  ${Text} {
    color: ${themeGet("colors.black")};

    :hover {
      text-decoration: underline;
    }
  }
`;

const TokenListBox = styled(Box)`
  margin: 0px 32px 32px 32px;
  max-height: 120px;
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    overflow-x: auto;
  }
`;

const StyledInput = styled(AdaptedInput)`
  width: 650px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    width: 350px;
  }
`;

const TokenManagementModal = () => {
  const intl = useIntl();
  const [busyOpen, setBusyOpen] = useState(false);
  const onSubmit = useGDPRTokenGenerate();
  const activeTokenList = useGDPRTokens();
  const [currentGeneratedToken, setCurrentGeneratedToken] = useState<Token>();
  const [activeGdprTokenState, setActiveGdprTokenState] = useState<boolean>(
    false
  );

  const handleResponse = useCallback(
    async (values: GDPRTokenCreateSchema) => {
      setBusyOpen(true);
      const response = await onSubmit(values);
      setCurrentGeneratedToken(response);
      setBusyOpen(false);
    },
    [setCurrentGeneratedToken, onSubmit]
  );

  return (
    <>
      <FlexCard overflowY="auto">
        <Box>
          <CardHeader margin="8px">
            <Text fontSize="20px" fontWeight={600}>
              {intl.formatMessage(dataBreachTokens)}
            </Text>
          </CardHeader>
          <Box margin="32px" flex={1} minHeight="68px">
            <Text fontSize="16px" fontWeight={400}>
              {intl.formatMessage(dataBreachDescription)}
            </Text>
          </Box>
        </Box>

        <FlexBox margin="32px" flex={1} minHeight="68px">
          <Form onSubmit={handleResponse} validate={GDPRTokenCreateValidator}>
            {({ handleSubmit, submitting }) => (
              <FormInner onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" alignItems="left">
                  <FlexBox marginBottom="16px">
                    <Text fontSize="16px" fontWeight={400}>
                      {intl.formatMessage(createTestEntry)} :
                    </Text>
                  </FlexBox>
                  <FlexBox>
                    <Field
                      id="tokenName"
                      name="tokenName"
                      placeholder="Choose a name relevant to the platform where you will add it"
                      component={StyledInput}
                    />
                  </FlexBox>
                  <Box
                    flexDirection="column"
                    text-align="center"
                    marginTop="16px"
                    width="100%"
                  >
                    <FormControlFeedback name="tokenName" />
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={submitting}
                    >
                      {intl.formatMessage(generate)}
                    </Button>
                  </Box>
                </Box>
              </FormInner>
            )}
          </Form>
        </FlexBox>
        <Box flexDirection="column">
          {currentGeneratedToken ? (
            <Box flexDirection="column" margin="32px" minHeight="68px">
              <Box
                flexDirection="row"
                marginY={2}
                justifyContent="space-between"
              >
                <Text fontSize="16px" fontWeight={400}>
                  {intl.formatMessage(generatedUserName)} :
                </Text>
                <Box width={["300px", "400px", "500px"]}>
                  <Input
                    disabled
                    valid={true}
                    style={{ height: 24, width: "100%" }}
                    value={currentGeneratedToken.generatedInfo.fullName}
                  />
                </Box>
              </Box>
              <Box
                flexDirection="row"
                marginY={2}
                justifyContent="space-between"
              >
                <Text fontSize="16px" fontWeight={400}>
                  {intl.formatMessage(generatedEmailAddress)} :
                </Text>
                <Box width={["300px", "400px", "500px"]}>
                  <Input
                    disabled
                    valid={true}
                    style={{ height: 24, width: "100%" }}
                    value={currentGeneratedToken.generatedInfo.token}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}

          <ActiveTokens>
            <Text
              fontSize="16px"
              fontWeight={600}
              marginBottom="16px"
              marginLeft="32px"
              onClick={() => setActiveGdprTokenState(!activeGdprTokenState)}
            >
              {intl.formatMessage(activeTokens, {
                value: activeTokenList?.length ?? 0,
              })}
            </Text>
          </ActiveTokens>
          <TokenListBox>
            {activeGdprTokenState && activeTokenList && (
              <Box>
                {activeTokenList.map((item) => (
                  <ActiveGdprTokenItem key={item.id} item={item} />
                ))}
              </Box>
            )}
          </TokenListBox>
        </Box>
      </FlexCard>
      {busyOpen && (
        <Modal
          isOpen={busyOpen}
          close={() => {}}
          zIndex={1002}
          showCloseIcon={false}
        >
          <BusyModal
            messages={["Processing...", "This might take a few seconds..."]}
          />
        </Modal>
      )}
    </>
  );
};

export const TokenManagement = memo(TokenManagementModal);

TokenManagement.displayName = "TokenManagement";
