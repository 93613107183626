import styled, { css } from "styled-components";
import themeGet from "@styled-system/theme-get";
import { color, ColorProps } from "styled-system";

export const Label = styled.label<ColorProps>(
         {
           fontSize: "16px",
           fontWeight: 400,
           marginBottom: "8px",
         },
         ...css`
           font-family: ${themeGet("fonts.montserrat")};
         `,
         color
       );

Label.defaultProps = {
  color: "logo",
};
