import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

import { Box } from "../atoms";

export const BaseTemplate = styled(Box)`
  background-color: ${themeGet("colors.backgrounds.1")};
  flex: 1;
  width: 100%;
  min-height: 100vh;
`;
