import React, { memo } from "react";
import { Field } from "react-final-form";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import {
  AdaptedNumberInput,
  FormControlFeedback,
  FormGroup,
  Label,
} from "@cyberalarm/ui";

export const PhoneField = memo(({ label }: { label: MessageDescriptor }) => (
  <FormGroup>
    <Label htmlFor="phone">
      <FormattedMessage {...label} />
    </Label>
    <Field
      id="phone"
      name="phone"
      component={(props) => (
        <AdaptedNumberInput {...props} format="+###############" />
      )}
    />
    <FormControlFeedback name="phone" />
  </FormGroup>
));

PhoneField.displayName = "PhoneField";
