import { useContext, useEffect, useState } from "react";
import {
  api,
  CookiesType,
  getCookie,
  GrantType,
  setCookie,
  resetCookies,
  expireOption,
  Role,
} from "@cyberalarm/common";
import { useHistory } from "react-router";

import { StoreContext } from "../store";
import { CommonRoutes, AdminRoutes, UserRoutes } from "../routes";

const { REACT_APP_API } = process.env;
const path =
  REACT_APP_API && REACT_APP_API.slice(0, REACT_APP_API.lastIndexOf("api"));

export const useAuth = () => {
  const history = useHistory();
  const { setUser } = useContext(StoreContext);
  const refreshToken = getCookie(CookiesType.refreshToken);
  const [loading, setLoading] = useState(true);

  const isEmailVerificationPage =
    history.location.pathname === CommonRoutes.emailVerification;

  const isCommonPage = Object.keys(CommonRoutes).some((route) => {
    return (
      CommonRoutes[route as keyof typeof CommonRoutes] ===
      history.location.pathname
    );
  });

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const data = await api(`${path}oauth/token`, {
          method: "POST",
          body: {
            grantType: GrantType.refreshToken,
            refreshToken,
          },
        });

        if (data.error) {
          throw data.error;
        }

        setUser({
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          role: data.role,
          scope: data.scope,
        });

        setCookie(CookiesType.token, data.access_token, expireOption); // expires in one hour
        setCookie(CookiesType.refreshToken, data.refresh_token);
        setCookie(CookiesType.role, data.role, expireOption);

        const redirectTo =
          data.role === Role.admin
            ? AdminRoutes.activeDomainList
            : UserRoutes.root;

        if (!isEmailVerificationPage && isCommonPage) {
          history.push(redirectTo);
        }
      } catch (error) {
        resetCookies();

        if (!isCommonPage) {
          history.push(CommonRoutes.signIn);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading;
};
