import React, { memo } from "react";
import { Form, Field } from "react-final-form";
import {
  FormButton,
  Box,
  Card,
  Text,
  FormInner,
  Label,
  theme,
  AdaptedInput,
  FormGroup,
  FormControlFeedback,
} from "@cyberalarm/ui";
import styled from "styled-components";
import build from "redux-object";
import { toast } from "react-toastify";
import { api } from "@cyberalarm/common";
import normalize from "json-api-normalizer";
import { form } from "@cyberalarm/common";
import { inviteSchema } from "./schema";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FlexCard = styled(Card)`
  max-width: 800px;
  overflow: auto;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface ModalProps {
  close: () => void;
  setTeamUsers: (users: TeamUser[]) => void;
}

interface InviteUserFormProps {
  email: string;
  confirmEmail: string;
}

interface ErrorType {
  code: string;
}

const validator = form.validateForm({ schema: inviteSchema });

const Modal = ({ close, setTeamUsers }: ModalProps) => {
  const onSubmit = async (values: InviteUserFormProps) => {
    try {
      const data = await api("teams/invite_user", {
        method: "POST",
        body: {
          email: values.email,
          confirmEmail: values.confirmEmail,
        },
      });
      const normalizedTeamData = normalize(data);
      //Team users refresh
      const builtTeamData = build(normalizedTeamData, "teamUser");
      setTeamUsers(builtTeamData);
      toast.success("User invite successful.");
      close();
    } catch (error) {
      const e = error as ErrorType;
      const code = e.code;
      switch (code) {
        case "invite_already_created":
          toast.error("User has been already invited!");
          break;
        case "already_signed_up":
          toast.error("User has already signed up!");
          break;
        default:
          toast.error("Unexpected error! Contact System Administrator.");
      }
    }
  };

  return (
    <>
      <FlexCard>
        <Box marginX={6} marginY={6} flex={1} minHeight="68px">
          <Box alignItems="center" marginX="auto">
            <Label>
              <Text fontSize="24px" fontFamily="title">
                Invite user
              </Text>
            </Label>
          </Box>

          <Form onSubmit={onSubmit} validate={validator}>
            {({ values, handleSubmit, submitting, valid }) => {
              return (
                <FormInner onSubmit={handleSubmit}>
                  <Box mt={4}>
                    <FlexBox mt={3}>
                      <FormGroup>
                        <Label>Email</Label>
                        <Field
                          component={AdaptedInput}
                          id="email"
                          name="email"
                          label="Email"
                        />
                        <FormControlFeedback name="email" />
                      </FormGroup>
                    </FlexBox>
                    <FlexBox mt={3}>
                      <FormGroup>
                        <Label>Confirm email</Label>
                        <Field
                          component={AdaptedInput}
                          id="confirmEmail"
                          name="confirmEmail"
                          label="Confirm email"
                        />
                        <FormControlFeedback name="confirmEmail" />
                      </FormGroup>
                    </FlexBox>

                    <Box alignItems="center">
                      <FormButton
                        mt="48px"
                        type="submit"
                        variant="primary"
                        disabled={
                          !valid ||
                          submitting ||
                          values.email === undefined ||
                          values.email !== values.confirmEmail
                        }
                      >
                        Submit
                      </FormButton>
                    </Box>
                  </Box>
                </FormInner>
              );
            }}
          </Form>
        </Box>
      </FlexCard>
    </>
  );
};

export const InviteTeamUserModal = memo(Modal);

InviteTeamUserModal.displayName = "InviteTeamUser";
