import React from "react";
import { Field, Form } from "react-final-form";
import { FormattedMessage } from "react-intl";
import { form, useToggle } from "@cyberalarm/common";
import {
  AdaptedInput,
  FormButton,
  Card,
  FormControlFeedback,
  FormGroup,
  FormInner,
  FormTitle,
  Label,
  ShowPasswordIcon,
  Box,
} from "@cyberalarm/ui";

import { i18n, passwordSchema } from "./schema";
import { usePasswordUpdate } from "./hooks";

const validator = form.validateForm({ schema: passwordSchema });

export const PasswordForm = () => {
  const [showOldPassword, setOldPasswordVisibility] = useToggle();
  const [showPassword, setPasswordVisibility] = useToggle();
  const [showRepeatPassword, setRepeatPasswordVisibility] = useToggle();

  const onSubmit = usePasswordUpdate();

  return (
    <Card
      p={5}
      height="fit-content"
      minHeight="fit-content"
      minWidth={0}
      marginLeft={[0, 0, 2]}
      marginBottom={[4, 4, 2]}
      flex={1}
      borderRadius={1}
    >
      <Form
        onSubmit={onSubmit}
        validate={validator}
        component={({ handleSubmit, pristine, submitting }) => {
          return (
            <FormInner onSubmit={handleSubmit}>
              <FormTitle>
                <FormattedMessage {...i18n.passwordFormTitle} />
              </FormTitle>

              <FormGroup marginTop={5}>
                <Label htmlFor="oldPassword">
                  <FormattedMessage {...i18n.oldPasswordLabel} />
                </Label>
                <ShowPasswordIcon
                  show={showOldPassword}
                  onClick={setOldPasswordVisibility}
                />
                <Field
                  id="oldPassword"
                  name="oldPassword"
                  type={showOldPassword ? "text" : "password"}
                  component={AdaptedInput}
                />
                <FormControlFeedback name="oldPassword" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="password">
                  <FormattedMessage {...i18n.newPasswordLabel} />
                </Label>
                <ShowPasswordIcon
                  show={showPassword}
                  onClick={setPasswordVisibility}
                />
                <Field
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  component={AdaptedInput}
                />
                <FormControlFeedback name="password" />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="passwordConfirmation">
                  <FormattedMessage {...i18n.passwordConfirmationLabel} />
                </Label>
                <ShowPasswordIcon
                  show={showRepeatPassword}
                  onClick={setRepeatPasswordVisibility}
                />
                <Field
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type={showRepeatPassword ? "text" : "password"}
                  component={AdaptedInput}
                />
                <FormControlFeedback name="passwordConfirmation" />
              </FormGroup>

              <Box>
                <FormButton
                  variant="primary"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  <FormattedMessage {...i18n.save} />
                </FormButton>
              </Box>
            </FormInner>
          );
        }}
      />
    </Card>
  );
};
