import React from "react";
import styled from "styled-components";
import { FormattedMessage, defineMessages } from "react-intl";
import themeGet from "@styled-system/theme-get";
import { Icon, Text, Box } from "@cyberalarm/ui";
import { Icons } from "@cyberalarm/common";

const { actAs, logout } = defineMessages({
  actAs: {
    defaultMessage: "You are acting as {name}",
    id: "userPreview.actAs",
  },
  logout: {
    defaultMessage: "Return to Admin Dashboard",
    id: "userPreview.logout",
  },
});

const Root = styled(Box)`
  height: 48px;
  min-height: 48px;
  background: ${themeGet("colors.statusError")};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const IconRoot = styled(Box)`
  color: white;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`;

export const StatusBar = ({
  name,
  handleLogout,
}: {
  name?: string | null;
  handleLogout: () => void;
}) => (
  <Root>
    <Text fontSize={4} marginX="auto">
      <FormattedMessage {...actAs} values={{ name }} />
    </Text>

    <IconRoot onClick={handleLogout}>
      <Icon name={Icons.close} />
      <Text marginX={3} fontSize={0} color="white">
        <FormattedMessage {...logout} />
      </Text>
    </IconRoot>
  </Root>
);
