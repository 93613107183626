import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

export const Portal = ({ children }: { children: React.ReactNode }) => {
  const modalRoot = useRef(document.createElement("div"));

  useEffect(() => {
    const parentElement = document.querySelector("#modal");
    const reference = modalRoot.current;

    if (parentElement) {
      parentElement.append(reference);
      return () => {
        reference.remove();
      };
    }
  }, []);

  return createPortal(children, modalRoot.current);
};
