import React, { memo, useCallback } from "react";
import styled, { css } from "styled-components";
import { MessageDescriptor, useIntl } from "react-intl";
import themeGet from "@styled-system/theme-get";
import { Text, Icon, CardHeader, Box } from "@cyberalarm/ui";
import { Icons } from "@cyberalarm/common";

const asc = css`
  transform: rotate(-90deg);
`;

const desc = css`
  transform: rotate(90deg);
`;

const Root = styled(CardHeader)`
  justify-content: start;
  background: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  flex-wrap: wrap;
  z-index: 3;
  cursor: pointer;
  color: ${themeGet("colors.grays.4")};

  :hover {
    color: ${themeGet("colors.gray")};
  }
`;

const IconRoot = styled(Box)<{ active: boolean; isAsc: boolean }>`
  margin-left: 8px;
  transition: 0.2s;
  ${(p) =>
    p.active &&
    css`
      filter: drop-shadow(0 0 2px ${themeGet("colors.logo")});
    `}

  > svg {
    ${(p) => (p.active && p.isAsc ? asc : desc)};
  }
`;

interface HeaderItemProps<T> {
  label: MessageDescriptor;
  asc: T;
  desc: T;
  currentSort: T;
  handleSort: (newSort: T) => void;
}

const Item = <T extends string>({
  label,
  currentSort,
  asc,
  desc,
  handleSort,
}: HeaderItemProps<T>) => {
  const intl = useIntl();
  const isAsc = currentSort === asc;
  const isDesc = currentSort === desc;
  const isActive = isAsc || isDesc;
  const handleClick = useCallback(() => {
    isDesc ? handleSort(asc) : handleSort(desc);
  }, [asc, desc, isDesc, handleSort]);

  return (
    <Root onClick={handleClick}>
      <Text fontSize={1}>{intl.formatMessage(label)}</Text>
      <IconRoot active={isActive} isAsc={isAsc}>
        <Icon name={Icons.arrow} width={6} height={8} />
      </IconRoot>
    </Root>
  );
};

export const HeaderItem = memo(Item) as typeof Item;
