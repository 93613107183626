import { ThemeProvider } from "styled-components";
import React, { memo, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter as RRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { theme, GlobalStyles } from "@cyberalarm/ui";

import "react-toastify/dist/ReactToastify.css";

import messages from "./i18n/en.json";
import { Router } from "./routes";
import { Store, ResponsiveState } from "./store";
import { Alerts } from "./features/user";
import { useLogoutWithoutRR } from "./hooks/use-logout";

// add polyfills for  IE11 & Safari 12-
if (!Intl.PluralRules) {
  require("intl-pluralrules");
}

if (
  !((Intl as unknown) as { RelativeTimeFormat: unknown }).RelativeTimeFormat
) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-relativetimeformat/dist/locale-data/en"); // Add locale data for de
}

const { REACT_APP_SESSION_TIME } = process.env;

export const BaseComponent = () => {
  const defaultLanguage = "en-GB";

  const logout = useLogoutWithoutRR();
  //Temporary - to review and remove the component in the final version
  const alertsEnabled = false;

  useEffect(() => {
    let time: any;

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(
        logout,
        60000 * parseInt(`${REACT_APP_SESSION_TIME ?? 30}`)
      );
    }
    window.addEventListener("load", resetTimer);
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keypress", resetTimer);
    window.addEventListener("mousewheel", resetTimer);
  }, [logout]);

  return (
    <IntlProvider locale={defaultLanguage} messages={messages}>
      <Store>
        <ResponsiveState>
          <ToastContainer />
          <GlobalStyles />
          <ThemeProvider theme={theme}>
            <RRouter>
              {alertsEnabled && <Alerts />}
              <Router />
            </RRouter>
          </ThemeProvider>
        </ResponsiveState>
      </Store>
    </IntlProvider>
  );
};

export const App = memo(BaseComponent);
