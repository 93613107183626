import { Status } from "@cyberalarm/common";
import { useEffect, useContext, useMemo} from "react";
import build from "redux-object";
import { useHistory, useLocation } from "react-router";

import { useDomainID } from "utils/use-domain-id";
import { domainSelectors, DomainStateContext } from "store";

interface DomainStatusProps {
  failedCount: number;
  warningsCount: number;
  passedCount: number;
}

export const useDomainStatus = ({
  failedCount,
  warningsCount,
  passedCount,
}: DomainStatusProps) => {
  const history = useHistory();
  const location = useLocation();
  const parameters = new URLSearchParams(location.search);
  const status = parameters.get("status") as Status;

  useEffect(() => {
    if (status) {
      return;
    }

    if (failedCount > 0) {
      return history.push({ search: `?status=${Status.failed}` });
    }

    if (warningsCount > 0) {
      return history.push({ search: `?status=${Status.warnings}` });
    }

    if (passedCount > 0) {
      return history.push({ search: `?status=${Status.passed}` });
    }
  }, [failedCount, history, passedCount, status, warningsCount]);

  return status;
};

export const useDomainDetails = () => {
  // const dispatch = useContext(DomainDispatchContext);
  const state = useContext(DomainStateContext);
  const domainID = useDomainID();

  const activeDomain = domainSelectors.getActiveDomain(state, {
    activeDomainID: domainID ?? "",
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const url = `domains/${domainID}`;
  //       const data = await api(url);
  //       console.log("CALL RE RENDER");
  //       const normalizedData = normalize<DomainEntity>(data);

  //       dispatch(
  //         domainActions.domainReadSuccess(
  //           normalizedData as domainActions.DomainList,
  //         ),
  //       );
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   if (!activeDomain && domainID) {
  //     fetchData();
  //   }
  // }, [activeDomain, dispatch, domainID, state]);

  const memoizedDomain = useMemo(
    () =>
      activeDomain &&
      domainID &&
      build({ domain: activeDomain }, "domain", domainID),
    [activeDomain, domainID],
  );

  return memoizedDomain;
};

