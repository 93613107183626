import { DomainActions, ActionTypes, DomainList } from "./actions";

export const initialDomainList: DomainList = {
  entities: { domain: null },
  result: [],
};

export const domainReducer = (
  state: DomainList,
  action: DomainActions,
): DomainList => {
  switch (action.type) {
    case ActionTypes.DOMAINS_LIST_SUCCESS:
    case ActionTypes.DOMAIN_READ_SUCCESS:
      return {
        ...state,
        entities: {
          domain: {
            ...action.payload.entities.domain,
          },
        },
        result: [...new Set([...action.payload.result])],
      };

    case ActionTypes.DOMAIN_UPDATE_SUCCESS:
    case ActionTypes.DOMAIN_CREATE_SUCCESS:
      return {
        ...state,
        entities: {
          domain: {
            ...state.entities.domain,
            ...action.payload.domain,
          },
        },
        result: [
          ...new Set([
            ...state.result,
            ...Object.keys(action.payload.domain as {}),
          ]),
        ],
      };

    case ActionTypes.DOMAIN_DELETE_SUCCESS:
      if (state.entities.domain) {
        const {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          [action.payload]: deletedDomain,
          ...rest
        } = state.entities.domain;
        return {
          ...state,
          entities: { domain: rest },
          result: state.result.filter((item) => item !== action.payload),
        };
      } else {
        return state;
      }

    case ActionTypes.DOMAIN_STATE_RESET:
      return initialDomainList;

    default:
      return state;
  }
};
