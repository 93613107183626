import React, {
  Fragment,
  memo,
  useCallback,
  useState,
  useRef,
  useContext,
  useEffect,
} from "react";
import { FormattedMessage } from "react-intl";
import { Icons, useToggle } from "@cyberalarm/common";
import { Icon, Text, Box } from "@cyberalarm/ui";

import { List } from "./list";
import { title, ModalFlowEnum } from "./schema";
import { AddCircle, DeleteCircle, SortCircle } from "./components";
import SortMenu from "./sort-menu";
import { ModalFlow } from "./modal-flow";
import { useProfileData, useDomainIds } from "hooks";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { StoreContext } from "store";

const ListBox = styled(Box)`
  border-top: 1px solid ${themeGet("colors.grays.9")};
  margin: 0;
  padding: 0;
`;

const BaseComponent = () => {
  const { sortPreferences } = useContext(StoreContext);
  const [domainID, setDomainID] = useState<null | string>(null);
  const [currentModal, setModal] = useState<null | ModalFlowEnum>(null);
  const openCreate = useCallback(() => setModal(ModalFlowEnum.CREATE), []);
  const user = useProfileData();
  const [sortMenuOpen, toggleSortMenuOpen] = useToggle(false);
  const [sortOption, setSortOption] = useState(sortPreferences.sortOption);
  const [sortOrder, setSortOrder] = useState(sortPreferences.sortOrder);
  const dropdownIconRef = useRef(null);
  const domainIds = useDomainIds();
  const domainsCount = useDomainIds().length;

  const setID = useCallback(
    (id: string) => {
      setDomainID(id);
      sortPreferences.currentDomainId = id;
    },
    [sortPreferences.currentDomainId]
  );

  const openDelete = useCallback(() => {
    setModal(ModalFlowEnum.DELETE);
  }, []);

  const sortOptions = [
    { value: "name", label: "Name" },
    { value: "status", label: "Status" },
    { value: "last_scan_at", label: "Last scan" },
  ];

  const handleSort = useCallback(
    (value: string, order: string) => {
      setSortOption(value);
      setSortOrder(order);
      sortPreferences.sortOption = value;
      sortPreferences.sortOrder = order;
    },
    [sortPreferences.sortOption, sortPreferences.sortOrder]
  );
  useEffect(() => {
    if (domainIds.length > 0 && domainID === null) {
      setID(domainIds[0]);
    }
  }, [domainIds, setID, domainID]);

  return (
    <Fragment>
      {currentModal && (
        <ModalFlow
          id={domainID}
          currentModal={currentModal}
          setCurrentModal={setModal}
        />
      )}
      <Box
        minHeight="initial"
        height="100%"
        flex={1}
        overflowY="hidden"
        overflowX="hidden"
        display="block"
      >
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="25px"
        >
          <Box flexDirection="row" alignItems="center">
            <Text fontSize="22px">
              <FormattedMessage {...title} />
            </Text>
          </Box>
          <Box flexDirection="row">
            {user?.domainsLimit && user?.domainsLimit > domainsCount && (
              <AddCircle onClick={openCreate}>
                <Icon name={Icons.plus} width={15} height={15} />
              </AddCircle>
            )}
            {domainsCount > 0 && (
              <DeleteCircle onClick={openDelete} marginLeft="6px">
                <Icon
                  name={Icons.minus}
                  width={15}
                  height={15}
                  fill="#000000"
                />
              </DeleteCircle>
            )}
            {domainsCount > 1 && (
              <SortCircle
                onClick={toggleSortMenuOpen}
                marginLeft="6px"
                ref={dropdownIconRef}
              >
                <Icon name={Icons.sort} width={18} height={18} fill="#ffffff" />
                {sortMenuOpen && (
                  <SortMenu
                    options={sortOptions}
                    onSortChange={handleSort}
                    toggleSortMenuOpen={toggleSortMenuOpen}
                    sortOption={sortOption}
                    sortOrder={sortOrder}
                    menuIconRef={dropdownIconRef}
                  />
                )}
              </SortCircle>
            )}
          </Box>
        </Box>
        <ListBox>
          <List setID={setID} sortOption={sortOption} sortOrder={sortOrder} />
        </ListBox>
      </Box>
    </Fragment>
  );
};

export const DomainManagement = memo(BaseComponent);
