import React, { memo } from "react";
import { Modal } from "@cyberalarm/ui";

import { useAlerts, useOverlimitedDomains } from "./hooks";
import { RemoveDomains } from "./remove-domains";
import { PaymentRequired } from "./payment-required";

export const Alerts = memo(() => {
  const { close, isOpen, alertsErrorStatus, domainLimitAmount } = useAlerts();
  const { overlimitedDomains } = useOverlimitedDomains(alertsErrorStatus);

  if (alertsErrorStatus !== 403 && alertsErrorStatus !== 402) {
    return null;
  }
  return (
    <Modal
      isOpen={isOpen}
      close={close}
      width={{ xs: "100%", md: "70%" }}
      maxWidth="900px"
    >
      {overlimitedDomains?.length !== 0 && overlimitedDomains !== undefined && (
        <RemoveDomains
          overlimitedDomains={overlimitedDomains}
          close={close}
          domainLimitAmount={domainLimitAmount}
        />
      )}
      {alertsErrorStatus === 402 && <PaymentRequired close={close} />}
    </Modal>
  );
});

Alerts.displayName = "Alerts";
