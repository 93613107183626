import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

import {
  compose,
  SpaceProps,
  space,
  LayoutProps,
  layout,
  flexbox,
  FlexboxProps,
  overflow,
  OverflowProps,
  BorderRadiusProps,
  borderRadius,
  position,
  PositionProps,
  bottom,
  BottomProps
} from "styled-system";

export type FeedbackProps = SpaceProps &
  LayoutProps &
  FlexboxProps &
  OverflowProps &
  BorderRadiusProps & PositionProps & BottomProps;

export const composedHelpers = compose(
  space,
  layout,
  flexbox,
  overflow,
  borderRadius,
  position,
  bottom,
);

export const Feedback = styled.div<FeedbackProps>`
         color: ${themeGet("colors.statusError")};
         font-family: ${themeGet("fonts.montserrat")};
         font-size: 14px;
         margin-top: 4px;
         min-height: 20px;
         white-space: pre-wrap;
         ${composedHelpers}
       `;

Feedback.defaultProps = {};
