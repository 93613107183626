import { defineMessages } from "react-intl";
import * as yup from "yup";
import { form } from "@cyberalarm/common";

export const {
         manage,
         dataBreachTokens,
         dataBreachDescription,
         tokenName,
         generate,
         activeTokens,
         userNameIs,
         userName,
         generatedUserName,
         generatedEmailAddress,
         emailAddressIs,
         emailAddress,
         tokenIssued,
         numberOfHits,
         tokenNameWithValue,
         createTestEntry,
         compromisedDate,
         compromisedFrom,
         compromisedSubject,
         tokenGenerated,
         errorOnGenerateToken,
         setupRequired,
       } = defineMessages({
         manage: {
           defaultMessage: "Setup/manage",
           id: "GDPRTokenManagement.manage",
         },
         setupRequired: {
           defaultMessage: "Setup required",
           id: "GDPRTokensList.setupRequired",
         },
         dataBreachTokens: {
           defaultMessage: "Data breaches",
           id: "GDPRTokenManagement.dataBreachTokens",
         },
         dataBreachDescription: {
           defaultMessage:
             "This functionality allows you to track if your company data has been breached, by generating a test entry for you to add in places where you store and manage your customer/supplier data. Once you have generated a test user name and email address below, add the details as a new record or contact in services such as your CRM, invoicing or contact management platforms. We will then track the internet to see if the email is ever used and alert you as it could indicate a breach of your company data.",
           id: "GDPRTokenManagement.dataBreachDescription",
         },
         tokenName: {
           defaultMessage: "Token name",
           id: "GDPRTokenManagement.tokenName",
         },
         createTestEntry: {
           defaultMessage: "Create test entry",
           id: "GDPRTokenManagement.createTestEntry",
         },
         generate: {
           defaultMessage: "Generate",
           id: "GDPRTokenManagement.generate",
         },
         activeTokens: {
           defaultMessage: "You currently have {value} active tokens",
           id: "GDPRTokenManagement.activeTokens",
         },
         userNameIs: {
           defaultMessage: "User name is",
           id: "GDPRTokenManagement.userNameIs",
         },
         emailAddressIs: {
           defaultMessage: "Email : {value}",
           id: "GDPRTokenManagement.emailAddressIs",
         },
         userName: {
           defaultMessage: "User name : {value}",
           id: "GDPRTokenManagement.userName",
         },
         generatedUserName: {
           defaultMessage: "Generated user name",
           id: "GDPRTokenManagement.generatedUserName",
         },
         generatedEmailAddress: {
           defaultMessage: "Generated email address",
           id: "GDPRTokenManagement.generatedEmailAddress",
         },
         emailAddress: {
           defaultMessage: "Email Address",
           id: "GDPRTokenManagement.emailAddress",
         },
         tokenIssued: {
           defaultMessage: "Token issued on : {value}",
           id: "GDPRTokenManagement.tokenIssued",
         },
         numberOfHits: {
           defaultMessage: "Number of hits : {value}",
           id: "GDPRTokenManagement.numberOfHits",
         },
         tokenNameWithValue: {
           defaultMessage: "Token Name : {value}",
           id: "GDPRTokenManagement.tokenNameWithValue",
         },
         compromisedDate: {
           defaultMessage: "Date : {value}",
           id: "GDPRTokenManagement.compromisedDate",
         },
         compromisedFrom: {
           defaultMessage: "From : {value}",
           id: "GDPRTokenManagement.compromisedFrom",
         },
         compromisedSubject: {
           defaultMessage: "Subject : {value}",
           id: "GDPRTokenManagement.compromisedSubject",
         },
         tokenGenerated: {
           defaultMessage: "Token generated",
           id: "GDPRTokenManagement.tokenGenerated",
         },
         errorOnGenerateToken: {
           defaultMessage: "Error on generating token",
           id: "GDPRTokenManagement.errorOnGenerateToken",
         },
       });

export const GDPRTokenCreateSchema = yup.object({
  tokenName: yup.string().required(),
});

export const GDPRTokenCreateValidator = form.validateForm({
  schema: GDPRTokenCreateSchema,
});

export type GDPRTokenCreateSchema = yup.InferType<typeof GDPRTokenCreateSchema>;
