import React, { SVGProps } from "react";

const Close = ({
  fill = "currentColor",
  width = 16,
  height = 16,
}: SVGProps<{}>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.76 19.368L10.512 13.428L6.12 19.368H0L7.452 9.612L0.252 0H6.552L10.656 5.652L14.868 0H20.844L13.644 9.468L21.132 19.368H14.76Z"
      fill={fill}
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default Close;
