import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { Text, Icon, Box } from "@cyberalarm/ui";
import { Icons } from "@cyberalarm/common";

import { i18n } from "./schema";
import { IconRoot, Image, Link } from "./components";

import { AdminRoutes } from "routes";

const textProps = {
  fontWeight: 600,
  fontSize: "13px",
  marginY: "6px",
};

const Controls = () => {
  return (
    <Box justifyContent="center">
      <Box flexDirection="row" marginBottom={1}>
        <IconRoot>
          <Icon name={Icons.user} />
        </IconRoot>
        <Box alignItems="flex-start" flex={1}>
          <Text {...textProps}>
            <FormattedMessage {...i18n.users} />
          </Text>
          <Link to={AdminRoutes.managerList} label={i18n.managers} />
          <Link to={AdminRoutes.userList} label={i18n.clients} />
        </Box>
      </Box>

      <Box flexDirection="row" marginBottom={3}>
        <IconRoot>
          <Icon name={Icons.domain} />
        </IconRoot>
        <Box alignItems="flex-start" flex={1}>
          <Text {...textProps}>
            <FormattedMessage {...i18n.domains} />
          </Text>
          <Link to={AdminRoutes.activeDomainList} label={i18n.active} />
          <Link
            to={AdminRoutes.deactivatedDomainList}
            label={i18n.deactivated}
          />
        </Box>
      </Box>

      <Box flexDirection="row" marginBottom={3}>
        <IconRoot>
          <Image src={`${process.env.PUBLIC_URL}/images/logo_small.png`} />
        </IconRoot>

        <Box alignItems="flex-start" flex={1}>
          <Text {...textProps}>
            <FormattedMessage {...i18n.tests} />
          </Text>
          <Link to={AdminRoutes.activeTestList} label={i18n.active} />
          <Link to={AdminRoutes.deactivatedTestList} label={i18n.deactivated} />
        </Box>
      </Box>

      <Box alignItems="center" flexDirection="row" marginBottom={3}>
        <IconRoot>
          <Image src={`${process.env.PUBLIC_URL}/images/error_icon.png`} />
        </IconRoot>
        <Box alignItems="flex-start" flex={1}>
          <Text {...textProps}>
            <FormattedMessage {...i18n.errors} />
          </Text>

          <Link to={AdminRoutes.activeErrorList} label={i18n.active} />
          <Link
            to={AdminRoutes.deactivatedErrorList}
            label={i18n.deactivated}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const AdminControls = memo(Controls);
