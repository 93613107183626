import React, { useCallback } from "react";
import styled from "styled-components";
import { MessageDescriptor, defineMessages, useIntl } from "react-intl";
import themeGet from "@styled-system/theme-get";
import { Status } from "@cyberalarm/common";
import { RadioButton, Text, Box } from "@cyberalarm/ui";

const i18n: {
  [key: string]: MessageDescriptor;
} = defineMessages({
  failed: {
    defaultMessage: "Alarm",
    id: "editModal.failed",
  },
  passed: {
    defaultMessage: "Clear",
    id: "editModal.passed",
  },
  warnings: {
    defaultMessage: "Warnings",
    id: "editModal.warnings",
  },
});

const RadioButtonsRoot = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid ${themeGet("colors.grays.0")};
  border-bottom: 1px solid ${themeGet("colors.grays.0")};
  padding: 16px 54px;
`;

const ItemRoot = styled(Box)`
  cursor: pointer;
  flex-direction: row;
`;
interface StatusItemProps {
  name: Status;
  status: Status;
  updateStatus: (newValue: Status) => void;
}

const Item = ({ updateStatus, status, name }: StatusItemProps) => {
  const intl = useIntl();
  const handleClick = useCallback(() => {
    updateStatus(name);
  }, [name, updateStatus]);
  return (
    <ItemRoot onClick={handleClick}>
      <RadioButton<Status> name={name} value={status} onClick={updateStatus} />
      <Text fontSize={2} marginLeft={2}>
        {intl.formatMessage(i18n[name as string])}
      </Text>
    </ItemRoot>
  );
};

interface StatusControlProps {
  updateStatus: (newValue: Status) => void;
  status: Status;
}

export const StatusControl = ({ updateStatus, status }: StatusControlProps) => (
  <RadioButtonsRoot flex={1}>
    {Object.keys(Status).map((key) => (
      <Item
        key={key}
        updateStatus={updateStatus}
        status={status}
        name={key as Status}
      />
    ))}
  </RadioButtonsRoot>
);
