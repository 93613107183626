import * as yup from "yup";
import { defineMessages } from "react-intl";

export const manageNotificationsSchema = yup.object().shape({
  digestNotifications: yup.boolean().when('individualNotifications', {
    is: (individualNotifications) => !individualNotifications,
    then: yup.boolean().required()
  }),
  individualNotifications: yup.boolean().when('digestNotifications', {
    is: (digestNotifications) => !digestNotifications,
    then: yup.boolean().required()
  }),
  digestNotificationsContent: yup.string().when('digestNotifications', {
    is: (digestNotifications) => digestNotifications,
    then: yup.string().required(),
  }),
  digestNotificationConsolidation: yup.string().when('digestNotifications', {
    is: (digestNotifications) => digestNotifications,
    then: yup.string().required(),
  }),
},
  [['digestNotifications', 'individualNotifications']]);


export const i18n = defineMessages({
         notificationsPreferencesFormTitle: {
           defaultMessage: "Configure notification frequency and information",
           id: "profile.notificationsPreferencesFormTitle",
         },
         notificationsPreferencesFormSubTitle: {
           defaultMessage:
             "You may configure the frequency and the details of the information you wish to receive through email notifications",
           id: "profile.notificationsPreferencesFormSubTitle",
         },
         digestNotificationsLabel: {
           defaultMessage: "Digest notifications (Daily)",
           id: "profile.digestNotificationsLabel",
         },
         individualNotificationsLabel: {
           defaultMessage: "Individual notifications (Immediate)",
           id: "profile.individualNotificationsLabel",
         },
         newOnlyLabel: {
           defaultMessage: "New notifications only",
           id: "profile.newOnlyLabel",
         },
         newAndExistingLabel: {
           defaultMessage: "New and existing notifications",
           id: "profile.newAndExistingLabel",
         },
         includeLabel: {
           defaultMessage: "Include",
           id: "profile.includeLabel",
         },
         sendLabel: {
           defaultMessage: "Send",
           id: "profile.sendLabel",
         },
         sendIndividualDomainLabel: {
           defaultMessage: "Individual digest for each domain",
           id: "profile.sendIndividualDomainLabel",
         },
         sendConsolidatedDomainsLabel: {
           defaultMessage: "Consolidated digest for all domains",
           id: "profile.sendConsolidatedDomainsLabel",
         },
         saveLabel: {
           defaultMessage: "Save",
           id: "profile.save",
         },
         notificationPreferencesSuccessToastText: {
           defaultMessage: "Notification preferences were successfully updated",
           id: "profile.notificationPreferencesSuccessToastText",
         },
         notificationPreferencesErrorToastText: {
           defaultMessage: "Notification preferences update failed",
           id: "profile.notificationPreferencesErrorToastText",
         },
       });


